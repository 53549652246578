import React from "react";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";
import './ShipmentTypeCard.css';
import "react-phone-input-2/lib/style.css";
import CloseModal from "../Auth/CloseModal";
import OrderCartitem from "./OrderCartItem";
import Airplane from "../../assets/images/Icon3";
import { formatDollars } from "../../helper/generalFunctions";
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';


const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
    width: clamp(290px, 80%, 700px);
  padding: 20px;
  background: white;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 90%;
  }
`;
const DeliveryAddressHeading = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  margin: 20px 0;
  color: #595959;
`;
const AddressWrapper = styled.div`
  border: 1px solid #e3e3e3;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
`;

const ShipmentDetailsWrapper = styled.div`
  border: 1px solid #e3e3e3;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  margin:20px 0;
  display:flex;
  justify-content:space-between;
  align-items:center; 

`;
const AmountTotalBox = styled.div`
  border: 1px solid #e3e3e3;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 20px 0;
`;

const TotalAmountHeading = styled.div`
  font-family: "Inter";
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
`;
const Heading = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  @media (max-width: 800px) {
    font-size: 15px;
  }
`;
const DetailBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DetailItem = styled.span`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #999999;
`;
const Division = styled.div`
  border: 1px solid #efefef;
  width: 95%;
  margin: 20px auto;
`;


function OrderInfoModal({
  selectedOrder,
  setSelectedOrder,
  displayModalOrderInfo,
  setDisplayModalOrderInfo,
}) {
  return (
    <>
      <Modal
        open={displayModalOrderInfo}
        onClose={() => {
          setDisplayModalOrderInfo(false);
          setSelectedOrder(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper>
          <CloseModal
            closeFn={() => {
              setDisplayModalOrderInfo(false);
              setSelectedOrder(null);
            }}
          />
          <Heading>{`Orders Id : #${selectedOrder?.display_id}`}</Heading>
          <OrderCartitem item={selectedOrder} />
          <DeliveryAddressHeading>Delivery Address</DeliveryAddressHeading>
          <AddressWrapper>
            {`${selectedOrder?.ecommerce_order?.delivery_address?.address || ""
              } ${selectedOrder?.ecommerce_order?.delivery_address?.area_name || ""
              } ${selectedOrder?.ecommerce_order?.delivery_address?.landmark || ""
              } 
                ${selectedOrder?.ecommerce_order?.delivery_address
                ?.first_name || ""
              } ${selectedOrder?.ecommerce_order?.delivery_address?.last_name || ""
              } 
                ${selectedOrder?.ecommerce_order?.delivery_address
                ?.mobile_number || ""
              } `}
          </AddressWrapper>
          {/* <ShipmentDetailsWrapper>
          <div style={{display:"flex", alignItems:"center"}}><Airplane color={ "#6B7280"}></Airplane><span>Airplane</span></div>
          <div>255.25</div>
          <div>7-10days</div>
          </ShipmentDetailsWrapper> */}

          <Card className="shipment-card" >
            <div className="shipment-content">
              <div className="shipment-info">
                {selectedOrder?.shipment_type === "AIR" ? (
                  <>
                    <AirplanemodeActiveIcon className="shipment-icon" />
                    <Typography variant="body1" className="shipment-text">Airplane</Typography>
                  </>
                ) : selectedOrder?.shipment_type === "SHIP" ? (
                  <>
                    <DirectionsBoatIcon className="shipment-icon" />
                    <Typography variant="body1" className="shipment-text">Ship</Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body1" className="shipment-text">Unknown</Typography>
                  </>
                )}
              </div>
              <Typography variant="h6" className="shipment-price">{formatDollars(selectedOrder?.shipping_cost)}</Typography>
              <Button variant="contained" className="shipment-button">7-10 Days</Button>
            </div>
          </Card>
          <AmountTotalBox>
            <TotalAmountHeading>Total Amount</TotalAmountHeading>
            <Division />
            <DetailBox>
              <DetailItem>Sub Total</DetailItem>
              <DetailItem>{formatDollars(selectedOrder?.sub_total)}</DetailItem>
            </DetailBox>
            <DetailBox>
              <DetailItem>Discount</DetailItem>
              <DetailItem>
                -{formatDollars(selectedOrder?.discountAmount)}
              </DetailItem>
            </DetailBox>
            <DetailBox>
              <DetailItem>Shipping Charges</DetailItem>
              <DetailItem>
                {formatDollars(selectedOrder?.shipping_cost)}
              </DetailItem>
            </DetailBox>
            <DetailBox>
              <DetailItem>Taxes</DetailItem>
              <DetailItem>
                {formatDollars(selectedOrder?.taxes)}
              </DetailItem>
            </DetailBox>

            <Division />
            <DetailBox>
              <DetailItem>Total ({selectedOrder?.unit_quantity} Product)</DetailItem>
              <DetailItem
                style={{ color: "#595959", fontSize: "18px", fontWeight: 700 }}
              >
                {formatDollars(selectedOrder?.grand_total)}
              </DetailItem>
            </DetailBox>
          </AmountTotalBox>
        </LoginFormWrapper>
      </Modal>
    </>
  );
}

export default OrderInfoModal;

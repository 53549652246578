import React from "react";
import styled from "@emotion/styled";

const Heading = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 600;
  line-height: 38.73px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
`;

const SearchBoxHeading = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
`;
const DisplayFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1106px) {
    flex-direction: column;
    align-items: flex-start;
  
  }
`;

const FieldWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1106px) {
margin-top:13px;
  
  }
`;
const SearchInputBox = styled.input`
  width: 370px;
  height: 38px;
  border-radius: 4px;
  background: white;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  padding: 20px;
  box-sizing: border-box;
  border: none;
  outline: none;
  @media (max-width: 800px) {
    width: 250px;
  }
`;
const SearchBtn = styled.button`
  background: #00b4d8;
  color: white;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 38px;
  border: none;
  outline: none;
  margin-left: 10px;
  border-radius: 4px;
  @media (max-width: 800px) {
    width: 70px;
  }
`;

const Btn = styled.button`
  width: 183px;
  display: flex;
  justify-content: center;
  height: 38px;
  align-items: center;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  background: transparent;
  cursor: pointer;
  color: #7b7b7b;
  border: 1px solid #7b7b7b;
  @media (max-width: 800px) {
    margin-top: 15px;
    width: 150px;
  }
`;



function OrderHeader({ getUserOrders,status, setStatus,search ,setSearch}) {
  return (
    <>
      <Heading>Your Orders</Heading>
      <SearchBoxHeading>Search Your Orders</SearchBoxHeading>
      <DisplayFlex>
        <FieldWrapper>
          <SearchInputBox value={search} onChange={(e)=>{
            setSearch(e.target.value)
          }} placeholder="Enter Order Id / Product Name" />
          <SearchBtn onClick={getUserOrders}>Search</SearchBtn>
        </FieldWrapper>

        <FieldWrapper>
          <Btn
            style={{
              background: status === 0 ? "#FFC727" : "transparent",
              color: status === 0 ? "#595959" : "#7B7B7B",
              border: status === 0 ? "none" : "1px solid #7b7b7b",
              borderRadius:"4px",
            }}
            onClick={() => {
              setStatus(0);
            }}
          >
            Active Order
          </Btn>
          <div style={{ width: "20px" }} />
          <Btn
            style={{
              background: status === 1 ? "#FFC727" : "transparent",
              color: status === 1 ? "#595959" : "#7B7B7B",
              border: status === 1 ? "none" : "1px solid #7b7b7b",
              borderRadius:"4px",
            }}
            onClick={() => {
              setStatus(1);
            }}
          >
            Completed Order
          </Btn>
        </FieldWrapper>
      </DisplayFlex>
    </>
  );
}

export default OrderHeader;

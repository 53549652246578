import React from "react";
import styled from "@emotion/styled";

import NoImage from "../../assets/images/no-image.jpeg"
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "../../api_helper/api_helper";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { useEffect } from "react";
import { useState } from "react";

import {
  selectProductToFetchDetails,
  selectedProduct,
  showSelectShipmentModal,
  showWishlistShipmentModal
,
} from "../../store/reducer/reducer";




const ParentProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  cursor: pointer;
  height: auto;
  margin: 10px;
  max-width: 100%;
  position:relative;

  @media (min-width: 600px) {
    max-width: 500px;
    margin: 20px 18px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%; // Creates a 1:1 aspect ratio
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const ProductName = styled.div`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  margin: 17px 10px 0;
  text-align: left;
  height: 38px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PriceBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;

  @media (max-width: 922px) {
    font-size: 0.88rem;
  }
`;

const SellingPrice = styled.span`
  font-weight: bold;
  color: black;
`;

const MRP = styled.span`
  text-decoration: line-through;
  color: #999;
  margin-left: 5px;
`;

const Discount = styled.span`
  color: #FF6D6D;
  margin-left: 10px;
  font-weight: bold;
  margin-bottom:19px;
`;

const AddToCartBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #00B4D8;
  border: 1px solid #00B4D8;
  border-radius: 5px;
  padding: 10px;
      margin: 0px auto;
    margin-bottom: 19px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: calc(100% - 20px);
  font-size: 16px;

  &:hover {
    background-color: #008CBA;
    color:white;
  }
`;



const PositionAbsolute = styled.div`
position: absolute;
right: 1px;
top: 10px;
z-index: 1000;
margin-right:5px;
color:black;
`;

// const ParentProductWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   border-radius: 8px;
//   overflow: hidden;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   transition: transform 0.3s;
//   cursor: pointer;

//   height: auto;
//   margin: 10px;
//   max-width: 100%;

//   @media (min-width: 600px) {
//     max-width: 500px;
//     margin: 20px 18px;
//   }
// `;

// const ImageWrapper = styled.div`
//   width: 100%;
//   padding-top: 100%; // Creates a 1:1 aspect ratio
//   position: relative;
//   overflow: hidden;

//   img {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     transition: transform 0.3s ease;
//   }

//   &:hover img {
//     transform: scale(1.1);

//   @media (max-width: 600px) {
//     width: 97%;
//   }
// `;
// const ImageWrapper = styled.div`
//   width: 183px;
//   height: 255px;
//   border-radius: 8px;
//   border: 1px solid #e3e3e3;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   @media (max-width: 600px) {
//     width: 97%;

//   }
// `;

// const ProductName = styled.div`
//   font-family: "Inter";
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 1.2;
//   margin: 17px 10px 0;
//   text-align: left;
//   height: 38px;
//   overflow: hidden;

//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;

//   @media (max-width: 600px) {
//     font-size: 12px;
//     width: 100%;
//     line-height: 15.36px;
//   }

// `;

// const PriceBox = styled.div`
//   display: flex;
//   align-items: center;
//   flex-wrap: wrap;
//   margin: 10px;

//   @media (max-width: 922px) {
//     font-size: 0.88rem;
//   }
// `;

// const SellingPrice = styled.span`

//   font-weight: bold;
//   color: black;

//   font-weight: 600;
//   font-family: "Inter";
//   font-size: 20px;
//   line-height: 24.2px;
//   @media (max-width: 600px) {
//     font-size: 15px;
//     line-height: 19.2px;
//   }

// `;

// const MRP = styled.span`
//   text-decoration: line-through;

//   color: #999;
//   margin-left: 5px;

//   @media (max-width: 600px) {
//     font-size: 15px;
//     line-height: 19.2px;
//     margin-left: 0px;
//   }

// `;

// const Discount = styled.span`
//   color: #FF6D6D;
//   margin-left: 10px;
//   font-weight: bold;
//   margin-bottom:19px;
// `;

// const AddToCartBtn = styled.button`

//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: none;
//   color: #00B4D8;
//   border: 1px solid #00B4D8;
//   border-radius: 5px;
//   padding: 10px;
//       margin: 0px auto;
//     margin-bottom: 19px;
//   cursor: pointer;
//   transition: background-color 0.3s;
//   width: calc(100% - 20px);
//   font-size: 16px;

//   &:hover {
//     background-color: #008CBA;
//     color:white;
//   }
// `;

// const PositionAbsolute = styled.div`
// position: absolute;
// right: 1px;
// top: 10px;
// z-index: 1000;
// margin-right:5px;
// color:black;
// `;

// const AddToCartBtn = styled.button`

//   width: 183px;
//   border: 1px solid #00b4d8;
//   color: #00b4d8;
//   font-family: "Inter";
//   font-size: 20px;
//   font-weight: 400;
//   line-height: 24.2px;
//   outline: none;
//   cursor: pointer;

//   @media (max-width: 600px) {
//     width: 100%;
//     font-size: 15px;
//   }
// `;
// function SingleProduct({ product, displayAddToCartBtn }) {

//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.general.user);


//   function SingleProduct({ product, displayAddToCartBtn }) {

//     const finalProduct = product?.product?.contain_variants
//       ? product?.product?.product_variants[0]
//       : product.product;

//     const calculateDiscountPercentage = () => {
//       if (finalProduct?.discountedPrice && finalProduct?.MRP) {
//         const discountedPrice = parseFloat(finalProduct.discountedPrice);
//         const mrp = parseFloat(finalProduct.MRP);
//         const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
//         return Math.round(discountPercentage);
//       }
//       return 0;
//     };

//     console.log("test", displayAddToCartBtn)
//     return (
//       <>
//         <ParentProductWrapper>
//           <ImageWrapper>
//             <img
//               alt="product"
//               src={
//                 finalProduct?.image_urls?.length
//                   ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${finalProduct?.image_urls[0]}`
//                   : NoImage
//               }
//             />
//           </ImageWrapper>

//           <ProductName>{product?.product?.name}</ProductName>


//           <PriceBox>
//             {finalProduct?.discountedPrice ? (
//               <>
//                 <SellingPrice>${finalProduct?.discountedPrice}</SellingPrice>
//                 <MRP>${finalProduct?.MRP}</MRP>
//               </>
//             ) : (
//               <>
//                 <SellingPrice>${finalProduct?.MRP}</SellingPrice>
//               </>
//             )}
//           </PriceBox>
//           <Discount>{calculateDiscountPercentage()}% off</Discount>
//           {displayAddToCartBtn ? <>
//             <AddToCartBtn
//               onClick={() => {

//                 if (finalProduct?.contain_variants) {
//                   toast.error("Please select variant");
//                   dispatch(selectProductToFetchDetails(product));
//                   navigate("/product-details");
//                   return;
//                 } else {
//                   if (!user) {
//                     toast.error("Please login to continue");
//                     return;
//                   }
//                   dispatch(selectedProduct({ productId: finalProduct.id }));
//                   dispatch(showSelectShipmentModal(true));
//                 }
//               }}
//             >
//               <ShoppingCartOutlinedIcon />
//               <span >Add to cart</span>
//             </AddToCartBtn>
//           </> : null}

//         </ParentProductWrapper>


//         <PriceBox>
//           {finalProduct?.discountedPrice ? (
//             <>
//               <SellingPrice>${finalProduct?.discountedPrice}</SellingPrice>
//               <MRP>${finalProduct?.MRP}</MRP>
//             </>
//           ) : (
//             <>
//               <SellingPrice>${finalProduct?.MRP}</SellingPrice>
//             </>
//           )}
//         </PriceBox>
//         <Discount>{calculateDiscountPercentage()}% off</Discount>
//       </ParentProductWrapper >


//       {
//         displayAddToCartBtn?(
//         <>
//         <AddToCartBtn>
//           <ShoppingCartOutlinedIcon style={{ color: "#00B4D8" }} />
//           <span style={{ marginLeft: "5px" }}>Add to cart</span>
//         </AddToCartBtn>

//         </>
//       ) : null
//   }
//     </>
//   );
// }

function SingleProduct({ product, displayAddToCartBtn, wishlistItems, setWishlistItems, getUserWishlistItems }) {


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.general.user);

  const finalProduct = product?.product?.contain_variants
    ? product?.product?.product_variants[0]
    : product.product;
    console.log("VendorPage",finalProduct)

    const [categoryName, setcategoryName] = useState('');
  const [subcategoryName, setSubCategoryName] = useState('');

  useEffect(() => {
    const name = finalProduct?.category?.name || '';
    
    // Set the productName in state
    setcategoryName(name);
    
    // console.log("final categoryName", name);
  }, [finalProduct]);

 

  useEffect(() => {
    const name = finalProduct?.sub_category?.name || '';
    
    // Set the productName in state
    setSubCategoryName(name);
    
    // console.log("final categoryName", name);
  }, [finalProduct]);


  const calculateDiscountPercentage = () => {
    if (finalProduct?.discountedPrice && finalProduct?.MRP) {
      const discountedPrice = parseFloat(finalProduct.discountedPrice);
      const mrp = parseFloat(finalProduct.MRP);
      const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
      return Math.round(discountPercentage);
    }
    return 0;
  };

  // console.log("test", displayAddToCartBtn);

  return (
    <>
      <ParentProductWrapper
      style={{}}
        onClick={() => {
          dispatch(selectProductToFetchDetails(product));

      
          navigate(`/product-details/${finalProduct?.category?.name}/${finalProduct?.sub_category?.name}/${finalProduct?.brand_name}/${finalProduct?.name}`);
          // console.log(finalProduct.name)

          // console.log(finalProduct.category.name)
          // console.log(finalProduct.sub_category.name)
          // console.log(finalProduct.brand_name)
          

        }}
      >
        {/* Image and Product Name */}

          <HeartIcon bool={wishlistItems?.find(el => el?.product_id === product?.product_id)} item={product} getUserWishlistItems={getUserWishlistItems} user={user}  wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} />
        <ImageWrapper>
          <img
            alt="product"
            src={
              finalProduct?.image_urls?.length
                ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${finalProduct?.image_urls[0]}`
                : NoImage
            }
          />
        </ImageWrapper>
        <ProductName>{product?.product?.name}</ProductName>

        {/* Price and Discount */}
        <PriceBox>
          {finalProduct?.discountedPrice ? (
            <>
              <SellingPrice>${finalProduct?.discountedPrice}</SellingPrice>
              <MRP>${finalProduct?.MRP}</MRP>
            </>
          ) : (
            <SellingPrice>${finalProduct?.MRP}</SellingPrice>
          )}
        </PriceBox>
        <Discount>{calculateDiscountPercentage()}% off</Discount>

        {/* Add to Cart Button */}
        {displayAddToCartBtn ? (
          <AddToCartBtn
            onClick={() => {
              if (finalProduct?.contain_variants) {
                toast.error("Please select variant");
                dispatch(selectProductToFetchDetails(product));
                navigate("/product-details/");
                return;
              } else {
                if (!user) {
                  toast.error("Please login to continue");
                  return;
                }
                dispatch(selectedProduct({ productId: finalProduct.id }));
                dispatch(showSelectShipmentModal(true));
              }
            }}
          >
            <ShoppingCartOutlinedIcon />
            <span>Add to cart</span>
          </AddToCartBtn>
        ) : null}
      </ParentProductWrapper>
    </>
  );
}


function HeartIcon({ bool, item, getUserWishlistItems, user, setWishlistItems, wishlistItems }) {
  const [loading, setLoading] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(bool); // Track if the item is in the wishlist
  // console.log("Jai ho",item.product)

  let brand_name;
  const calculateDiscountPercentage = (item) => {
    if (item.discountedPrice && item.MRP) {
      return Math.round(((item.MRP - item.discountedPrice) / item.MRP) * 100);
    }
    return 0; // Return 0 if no discount is available
  };

  // Sync wishlist state with localStorage or server when component mounts
  // useEffect(() => {
  //   if (!user) {
  //     // For guest users, check localStorage
  //     const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //     const isItemInWishlist = storedWishlist.some(wishlistItem => wishlistItem.id === item?.product_id);
  //     setIsInWishlist(isItemInWishlist);
  //   } else {
  //     // For logged-in users, you can call an API to check the wishlist status
  //     // Example: Check if item is in the server-side wishlist
  //     // You may replace this with an API call if needed.
  //     setIsInWishlist(bool);
  //   }
  // }, [user, bool, item?.product_id]);

  useEffect(() => {
    if (user) {

   
      // For logged-in users, you can call an API to check the wishlist status
      // Example: Check if item is in the server-side wishlist
      // You may replace this with an API call if needed.
      setIsInWishlist(bool);
    }
  }, [user, bool, item?.product_id]);

  useEffect(() => {
    if (!user) {
    const isItemInWishlist = wishlistItems?.some(wishlistItem => wishlistItem.id === item?.product_id);
    setIsInWishlist(isItemInWishlist);}
  }, [wishlistItems, item?.product_id]); 

  // Add item to wishlist (for guest users, store in localStorage)
  const addToWishlist = async () => {
    try {
      setLoading(true);

      if (!user) {
        // For guest users, store product details in localStorage
        const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        const newItem = {
          id: item?.product_id,
          name: item?.product?.name,
          category:item?.product?.categoryName,
          childcategory:item?.product?.childCategoryName,
          subcategory:item?.product?.subCategoryName,
          brand_name:item?.product?.brand_name,
          price: item?.product?.MRP,
          image: item?.product?.image_urls[0], // Ensure image URL is stored
          discountedPrice: item?.product?.discountedPrice,
          MRP: item?.product?.MRP,
          discountPercentage: calculateDiscountPercentage(item.product),
          weight: item?.product?.weight,
          width: item?.product?.width,
          length: item?.product?.length,
          height: item?.product?.height,
        };

        storedWishlist.push(newItem);
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

        setIsInWishlist(true); // Set state to true after adding to the wishlist
        toast.success("Added to wishlist (guest user)");

        setLoading(false);
        getUserWishlistItems(); // Refresh wishlist from the server
        return;
      }

      console.log("Product :- ", item)

      // For logged-in users, use API
      await get(`/api/wishlist/add/${item?.product_id}${item?.contain_variants ? `/${item?.product_variants[0].id}` : ''}`);

      setIsInWishlist(true); // Set state to true after adding to the wishlist
      toast.success("Added to wishlist");
      setLoading(false);
      getUserWishlistItems(); // Refresh wishlist from the server
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error || "Something went wrong");
    }
  };

  // Remove item from wishlist (for guest users, remove from localStorage)
  const removeFromWishlist = async () => {

    console.log("Removing.....", bool?.id)
    try {
      setLoading(true);

      if (!user) {
        // For guest users, remove from localStorage
        let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        storedWishlist = storedWishlist.filter((wishlistItem) => wishlistItem.id !== item?.product_id);
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

        setIsInWishlist(false); // Set state to false after removing from wishlist
        toast.success("Removed from wishlist (guest user)");

        setLoading(false);
        getUserWishlistItems(); // Refresh wishlist from the server
        return;
      }
      let wishlistItemId = item?.id;
      // For logged-in users, use API
      await get(`/api/wishlist/remove/${bool?.id}`);
      console.log("Hello ID", item?.id)
      setIsInWishlist(false); // Set state to false after removing from wishlist
      toast.success("Removed from wishlist");
      setLoading(false);
      getUserWishlistItems(); // Refresh wishlist from the server
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error || "Something went wrong");
    }
  };

  // Decide whether to add or remove the item from wishlist
  function callFunctionAccordingly(event) {
    event.stopPropagation();
    if (isInWishlist) {
      removeFromWishlist();
    } else {
      addToWishlist();
    }
  }

  return isInWishlist ? (
    <PositionAbsolute onClick={callFunctionAccordingly}>
      <FavoriteIcon style={{ color: "rgb(218, 72, 72)" }} /> {/* Red when added to wishlist */}
    </PositionAbsolute>
  ) : (
    <PositionAbsolute onClick={callFunctionAccordingly}>
      <FavoriteBorderOutlinedIcon style={{ color: "black" }} /> {/* Black when not in wishlist */}
    </PositionAbsolute>
  );
}
export default SingleProduct;

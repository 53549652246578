import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { post } from "../../api_helper/api_helper";
import CartItemsBox from "../../components/UserCart/CartItemsBox";
import CartDetailsBox from "../../components/UserCart/CartDetailsBox";
import UpdateTypeOfShipment from "../../components/UserCart/UpdateTypeOfShipment";
import empty_order from "../../assets/images/Group33257.png";
import CloseIcon from '@mui/icons-material/Close'; // Import the Close Icon
import { setGrandShippingValue } from "../../store/reducer/reducer";
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
max-width:1525px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 36px;
  font-weight: 600;
  line-height: 29.05px;
  margin: 20px auto;
`;

const DisplayFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const CheckoutBtn = styled.button`
  width: 50%;
  background: #00b4d8;
  color: white;
  border-radius: 8px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
`;
function UserCart() {
  const dispatch = useDispatch();
  const [cart, setCart] = useState({});
  const [coupon_code, setCouponCode] = useState("");
  const user = useSelector((state) => state.general.user);
  const [refetch, setRefetch] = useState(false);
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [shippingPrices, setShippingPrices] = useState({});
  // const [grandShippingValue, setGrandShippingValue] = useState(0);


  // const updateShippingPrice = (itemId, shippingPrice) => {
  //   setShippingPrices((prev) => {
  //     const updatedPrices = { ...prev, [itemId]: shippingPrice };

  //     // Recalculate grand shipping value
  //     const totalShipping = Object.values(updatedPrices).reduce((sum, price) => sum + price, 0);
  //     setGrandShippingValue(totalShipping);

  //     return updatedPrices;
  //   });
  // };
  const updateShippingPrice = (itemId, shippingPrice) => {
    setShippingPrices((prev) => {
      const updatedPrices = { ...prev, [itemId]: shippingPrice };

      // Recalculate grand shipping value
      const totalShipping = Object.values(updatedPrices).reduce((sum, price) => sum + price, 0);

      // Dispatch the total shipping value to Redux
      dispatch(setGrandShippingValue(totalShipping));

      return updatedPrices;
    });
  };
  // console.log("Grand", grandShippingValue)

  const handleCheckoutClick = () => {

    if (user) {
      setIsPopupVisible(true); // Show the popup when button is clicked
    }

    else {

      if (!user) {
        toast.error("Please login to continue");
        return;
      }

      toast.error("Something Went Wrong");

    }
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setIsPopupVisible(false); // Hide the popup
  };
  useEffect(() => {
    if (user) {
      // If logged in, fetch user cart info from the server
      getUserCartInfo();
    } else {
      // If not logged in, check for guest cart in local storage
      const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
      if (guestCart.length > 0) {
        // Process the guest cart items here, e.g., set them to state for displaying in the cart
        setCart(guestCart);
      } else {
        // No user and no guest cart, possibly show a message
        toast.error("Your cart is empty. Start shopping!");
      }
    }
  }, [refetch]);

  // console.log(cart)

  // async function getUserCartInfo(dontUseCoupon) {
  //   try {
  //     const cartInfo = await post("/api/cart", {
  //       user_id: user?.id,
  //       ...(coupon_code && !dontUseCoupon ? { coupon_code } : {}),
  //     });

  //     setCart(cartInfo);
  //   } catch (e) {
  //     setCart({});
  //   }
  // }

  async function getUserCartInfo(dontUseCoupon) {
    try {
      const cartInfo = await post("/api/cart", {
        user_id: user?.id,
        ...(coupon_code && !dontUseCoupon ? { coupon_code } : {}),
      });

      console.log("Cart data after adding item:", cartInfo);

      setCart(cartInfo);
    } catch (e) {
      setCart({});
    }
  }

  // console.log("Check Set Cart",cart.cartItems)

  // console.log("GrandShippingValue",grandShippingValue)

  return (
    <WidthAdjuster>

      <Dialog open={isPopupVisible} onClose={handleClosePopup} sx={{
        '& .MuiPaper-root': {

          borderRadius: '16px',  // Border radius
        },
      }} >


        <DialogTitle>

          {/* Close Icon Button */}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClosePopup}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 16,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CartDetailsBox
            cart={cart}
            getUserCartInfo={getUserCartInfo}
            coupon_code={coupon_code}
  
            setCouponCode={setCouponCode}
        
          />
        </DialogContent>


      </Dialog>





      <UpdateTypeOfShipment getUserCartInfo={getUserCartInfo} />
      <Heading>Cart</Heading>
      {(user ? !cart?.cartItems?.length : !cart.length) ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "500px",
          }}
        >
          <img src={empty_order} alt="Empty Cart" />
          <p style={{ fontFamily: "Inter", color: "#7B7B7B" }}>
            You Don't have any Cart Item
          </p>
        </div>
      ) : (
        <>
          <DisplayFlex>
            <CartItemsBox cart={cart} setRefetch={setRefetch}          updateShippingPrice={updateShippingPrice} setCart={setCart}  />
            {/* <CartDetailsBox
          cart={cart}
          getUserCartInfo={getUserCartInfo}
          coupon_code={coupon_code}
          setCouponCode={setCouponCode}
        /> */}
          </DisplayFlex>

          <CheckoutBtn onClick={handleCheckoutClick}>
            Checkout
          </CheckoutBtn>
        </>
      )}

    </WidthAdjuster>
  );
}

export default UserCart;

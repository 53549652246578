import React, { useState } from "react";
import styled from "@emotion/styled";

const DescriptionBoxWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  @media (max-width: 800px) {
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
    padding: 0;
  }

   @media (max-width: 1100px) {
    width: 90%;
    margin: 10px auto;

  }
`;

const Heading = styled.div`
font-family: "Inter";
font-size: 20px;
font-weight: 500;
line-height: 24.2px;
text-align: left;
width: 100%;

`

const Description = styled.div`
width: 100%;
font-family: "Inter";
font-size: 16px;
font-weight: 400;
line-height: 19.36px;
text-align: justify;
margin: 10px 0;


  span {

   color:#7B7B7B !important;
   font-family: "Inter" !important;
 
  }

`
function ProductDescription({product}) {

  return (
    <DescriptionBoxWrapper>
        <Heading>Description</Heading>
        <Description dangerouslySetInnerHTML={{__html:product?.description}} />

    </DescriptionBoxWrapper>
  );
}

export default ProductDescription;

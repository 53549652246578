// import React from "react";
// import styled from "@emotion/styled";
// import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
// import NoImage from "../../assets/images/no-image.jpeg";
// import delete_icon from "../../assets/images/delete_img.png"
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
// import { useDispatch } from "react-redux";
// import {
//   selectedProduct,
//   showSelectShipmentModal,

// } from "../../store/reducer/reducer";
// import { useSelector } from "react-redux";
// import { setModalSource } from '../../store/reducer/reducer'; // Adjust the path if needed
// import { formatDollars } from "../../helper/generalFunctions";
// const ItemWrapper = styled.div`
//   width: 100%;
//   height: 198px;
//   display: flex;
//   justify-content: space-evenly;
//   align-items: center;
//   border: 1px solid #c7c7c7;
//   border-radius: 8px;
//   gap:10px;
// `;
// const PositionAbsolute = styled.div`
//   position: absolute;
//   left: 5px;
//   top: 6px;
//   z-index: 1000;
// `;
// const ImageBox = styled.div`
//   width: 165px;
//   height: 165px;
//   border-radius: 8px;
//   background: #efefef;
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const WishlistDetails = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: flex-start;
//   flex-direction: column;
//   width: 50%;
//   @media (max-width: 600px) {
//     width: 90%;
//   }
// `;

// const Heading = styled.div`
//   font-family: "Inter";
//   font-size: 20px;
//   font-weight: 500;
//   line-height: 24.2px;
//   color: #000000;
//   @media (max-width: 600px) {
//     font-size: 14px;
//   }

  
//     @media (max-width: 919px) {
//     font-size: 17px;

//   }
// `;

// const PriceBox = styled.div`
//   display: flex;
//   margin-top: 10px;
//   justify-content: flex-start;
//   width: 100%;
//   margin-top: 15px;
//   // @media (max-width: 600px) {
//   //   flex-direction: column-reverse;
//   // }

//     @media (max-width: 400px) {
//     flex-direction: column;
//   }
// `;
// const SellingPrice = styled.span`
//   font-weight: 600;
//   font-family: "Inter";
//   font-size: 20px;
//   line-height: 24.2px;
//   @media (max-width: 600px) {
//     font-size: 15px;
//     line-height: 19.2px;
//   }

//     @media (max-width: 919px) {
//     font-size: 17px;

//   }
// `;
// const MRP = styled.span`
//   font-weight: 600;
//   font-family: "Inter";
//   font-size: 20px;
//   line-height: 24.2px;
//   margin-left: 10px;
//   color: #9a9a9a;
//   text-decoration: line-through;
//   @media (max-width: 600px) {
//     font-size: 15px;
//     line-height: 19.2px;
//     margin-left: 0px;
//   }

  
//     @media (max-width: 919px) {
//     font-size: 17px;

//   }
// `;

// const BtnBox = styled.div`
//   display: flex;
//   justify-content: space-evenly;
//   align-items: center;
//   @media (max-width: 600px) {
//     margin-top: 10px;
//   }
// `;
// const AddToCart = styled.button`
//   outline: none;
//   border: none;
//   cursor: pointer;
//   width: 182px;
//   height: 48px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 8px;
//   background: #19889f;
//   color: white;
//   font-size:20px;
//   @media (max-width: 600px) {
//     width: 142px;
//   }

//     @media (max-width: 919px) {
//     width: 143px;
//   }
// `;

// const DeleteIcon = styled.div`
//   margin-left: 10px;
// `;
// const BoxManagerResponsive = styled.div`
//   display: flex;
//   width: 80%;
//   justify-content: space-evenly;
//   align-items: center;
//   @media (max-width: 600px) {
//     flex-direction: column;
//     padding: 10px;
//     box-sizing: border-box;
//   }

//     @media (max-width: 800px) {
//     flex-direction: column;
//      align-items: start;
 
//   }
// `;

// const CategoryImage = styled.img`
//   width: 128px;
//   height: 128px;
//   padding: 56px;
//   border-radius: 18px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */

//   @media (max-width: 768px) {
//     width: 115px;
//     height: 115px;
//     padding: 40px;
//   }

//   @media (max-width: 600px) {
//     width: 100px;
//     height: 100px;
//     padding: 30px;
//   }
// `;


// const Discount = styled.div`
//   color: #da4848;
//   font-family: "Inter";
//   font-size: 20px;
//   font-weight: 400;
//   line-height: 24.2px;
//   margin-left: 10px;

//      @media (max-width: 600px) {
//      font-size: 14px;
//   }

  
//     @media (max-width: 919px) {
//     font-size: 17px;

//   }
// `;

// const DelImg = styled.img`

// color: #999999;
// font-size: "36px";
// cursor: "pointer";
// margin-left : 20px;

//     @media (max-width: 919px) {
//   margin-left : 6px;
//   }
// `;

// const DoubleWraper = styled.div`
//     display: flex;
//     gap: 4px;
// `;



// function WishlistItem({ setSelectedItem, item, setShowModal }) {
//   const user = useSelector((state) => state.general.user);
//   const finalProduct = item?.product?.contain_variants
//     ? { ...item?.product, ...item?.product_variant }
//     : item?.product;
//   const dispatch = useDispatch();
//   console.log("With Login", finalProduct?.name)
//   console.log("Without Login", item)
//   async function addToCart(isWishlistPage = false) {
//     dispatch(
//       selectedProduct({
//         productId: item?.product?.id,
//         productVariantId: item?.product_variant?.id,
//         productDetails: item?.product,
//         WOLoginID: item.id,
//         WODetails: item,


//       })
//     );

//     dispatch(
//       setModalSource(isWishlistPage ? 'wishlist' : 'homepage')
//     );

//     dispatch(showSelectShipmentModal(true));
//   }

//   const calculateDiscountPercentage = () => {
//     if (finalProduct?.discountedPrice && finalProduct?.MRP) {
//       const discountedPrice = parseFloat(finalProduct.discountedPrice);
//       const mrp = parseFloat(finalProduct.MRP);
//       const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
//       return Math.round(discountPercentage);
//     }
//     return 0;
//   };

//   // console.log("Get Selected",item.product)
//   return (
//     <ItemWrapper>
//       <ImageBox>
//         <HeartIcon bool={true} />
//         <img
//           style={{ width: "125px" }}
//           src={

//             (item?.image?.length
//               ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image}`
//               : NoImage)
//           }
//           alt="wishlist"
//         />
//       </ImageBox>
//       <BoxManagerResponsive>
//         <WishlistDetails>
//           <Heading>{finalProduct?.name || item?.name}</Heading>

//           {(finalProduct?.discountedPrice || item?.discountedPrice) ? (
//             <PriceBox>
//               <DoubleWraper>
//                 <SellingPrice>
//                   {user
//                     ? formatDollars(finalProduct?.discountedPrice || item?.discountedPrice)
//                     : formatDollars(item?.discountedPrice || finalProduct?.discountedPrice)}
//                 </SellingPrice>

//                 <MRP>
//                   {user
//                     ? formatDollars(finalProduct?.MRP || item?.MRP)
//                     : formatDollars(item?.MRP || finalProduct?.MRP)}
//                 </MRP>
//               </DoubleWraper>

//               <div>
//                 <Discount>
//                   -{ calculateDiscountPercentage() || item.discountPercentage}% OFF
//                 </Discount>
//               </div>
//             </PriceBox>
//           ) : (
//             <PriceBox>
//               <SellingPrice>
//                 {formatDollars(finalProduct?.MRP || item?.MRP)}
//               </SellingPrice>
//             </PriceBox>
//           )}

//         </WishlistDetails>

//         <BtnBox>
//           <AddToCart onClick={addToCart}>Add to cart</AddToCart>
//           {/* <DeleteIcon>
//             <DeleteOutlinedIcon
//               onClick={() => {
//                 setSelectedItem(item);
//                 setShowModal(true);
//               }}
//               style={{ color: "#999999", fontSize: "36px", cursor: "pointer" }}
//             />
//           </DeleteIcon> */}
//           <DelImg
//             src={delete_icon}
//             onClick={() => {
//               setSelectedItem(item);
//               setShowModal(true);
//             }}

//           />
//         </BtnBox>
//       </BoxManagerResponsive>
//     </ItemWrapper>
//   );
// }

// function HeartIcon(bool) {
//   function showAlert(event) {
//     event.stopPropagation();
//   }
//   return bool ? (
//     <PositionAbsolute onClick={showAlert}>
//       <FavoriteIcon style={{ color: "#DA4848" }} />
//     </PositionAbsolute>
//   ) : (
//     <PositionAbsolute onClick={showAlert}>
//       <FavoriteBorderOutlinedIcon style={{ color: "#DA4848" }} />
//     </PositionAbsolute>
//   );
// }
// export default WishlistItem;
import React from "react";
import styled from "@emotion/styled";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import NoImage from "../../assets/images/no-image.jpeg";
import delete_icon from "../../assets/images/delete_img.png"
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectedProduct,
  showSelectShipmentModal,

} from "../../store/reducer/reducer";
import { selectProductToFetchDetails } from "../../store/reducer/reducer";
import { useSelector } from "react-redux";
import { setModalSource } from '../../store/reducer/reducer'; // Adjust the path if needed
import { formatDollars } from "../../helper/generalFunctions";
const ItemWrapper = styled.div`
  width: 100%;
  height: 198px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #c7c7c7;
  border-radius: 8px;
  gap:10px;
`;
const PositionAbsolute = styled.div`
  position: absolute;
  left: 5px;
  top: 6px;
  z-index: 1000;
`;
const ImageBox = styled.div`
  width: 165px;
  height: 165px;
  border-radius: 8px;
  background: #efefef;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WishlistDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

const Heading = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: #000000;
  @media (max-width: 600px) {
    font-size: 14px;
  }

  
    @media (max-width: 919px) {
    font-size: 17px;

  }
`;

const PriceBox = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;
  width: 100%;
  margin-top: 15px;
  // @media (max-width: 600px) {
  //   flex-direction: column-reverse;
  // }

    @media (max-width: 400px) {
    flex-direction: column;
  }
`;
const SellingPrice = styled.span`
  font-weight: 600;
  font-family: "Inter";
  font-size: 20px;
  line-height: 24.2px;
  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 19.2px;
  }

    @media (max-width: 919px) {
    font-size: 17px;

  }
`;
const MRP = styled.span`
  font-weight: 600;
  font-family: "Inter";
  font-size: 20px;
  line-height: 24.2px;
  margin-left: 10px;
  color: #9a9a9a;
  text-decoration: line-through;
  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 19.2px;
    margin-left: 0px;
  }

  
    @media (max-width: 919px) {
    font-size: 17px;

  }
`;

const BtnBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 600px) {
    margin-top: 10px;
  }
`;
const AddToCart = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  width: 182px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #19889f;
  color: white;
  font-size:20px;
  @media (max-width: 600px) {
    width: 142px;
  }

    @media (max-width: 919px) {
    width: 143px;
  }
`;

const DeleteIcon = styled.div`
  margin-left: 10px;
`;
const BoxManagerResponsive = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
  }

    @media (max-width: 800px) {
    flex-direction: column;
     align-items: start;
 
  }
`;

const CategoryImage = styled.img`
  width: 128px;
  height: 128px;
  padding: 56px;
  border-radius: 18px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */

  @media (max-width: 768px) {
    width: 115px;
    height: 115px;
    padding: 40px;
  }

  @media (max-width: 600px) {
    width: 100px;
    height: 100px;
    padding: 30px;
  }
`;


const Discount = styled.div`
  color: #da4848;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin-left: 10px;

     @media (max-width: 600px) {
     font-size: 14px;
  }

  
    @media (max-width: 919px) {
    font-size: 17px;

  }
`;

const DelImg = styled.img`

color: #999999;
font-size: "36px";
cursor: "pointer";
margin-left : 20px;

    @media (max-width: 919px) {
  margin-left : 6px;
  }
`;

const DoubleWraper = styled.div`
    display: flex;
    gap: 4px;
`;



function WishlistItem({ setSelectedItem, item, setShowModal }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.general.user);
  const finalProduct = item?.product?.contain_variants
    ? { ...item?.product, ...item?.product_variant }
    : item?.product;
  const dispatch = useDispatch();
  console.log("With Login", finalProduct?.name)
  console.log("Without Login", item)
  async function addToCart(isWishlistPage = false) {
    dispatch(
      selectedProduct({
        productId: item?.product?.id,
        productVariantId: item?.product_variant?.id,
        productDetails: item?.product,
        WOLoginID: item.id,
        WODetails: item,


      })
    );

    dispatch(
      setModalSource(isWishlistPage ? 'wishlist' : 'homepage')
    );

    dispatch(showSelectShipmentModal(true));
  }

  const calculateDiscountPercentage = () => {
    if (finalProduct?.discountedPrice && finalProduct?.MRP) {
      const discountedPrice = parseFloat(finalProduct.discountedPrice);
      const mrp = parseFloat(finalProduct.MRP);
      const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
      return Math.round(discountPercentage);
    }
    return 0;
  };
  const handleProductClick = () => {
    if(user){
    dispatch(selectProductToFetchDetails(finalProduct));
    navigate(`/product-details/${finalProduct?.categoryName
    }/${finalProduct?.subCategoryName}/${finalProduct?.brand_name}/${finalProduct?.name}`);
  }

  else{
    dispatch(selectProductToFetchDetails(item));
    navigate(`/product-details/${item?.category
    }/${item?.subCategoryName}/${item?.brand_name}/${item?.name}`)
  }


  };
  


  console.log(`/product-details/${item?.category
    }/${item?.subCategoryName}/${item?.brand_name}/${item?.name}`)

  console.log("Items Produ",item)

  return (
    <ItemWrapper>
      <ImageBox onClick={handleProductClick}>
        <HeartIcon bool={true} />
        <img
          style={{ width: "125px", cursor:"pointer" }}
          src={
            user
              ? (finalProduct?.image_urls?.length
                ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${finalProduct?.image_urls[0]}`
                : NoImage)
              : (item?.image?.length
                ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image}`
                : NoImage)
          }
          alt="wishlist"
        />
      </ImageBox>
      <BoxManagerResponsive>
        <WishlistDetails>
          <Heading>{user ? finalProduct?.name : item?.name}</Heading>

          {(finalProduct?.discountedPrice || item?.discountedPrice) ? (
            <PriceBox>
              <DoubleWraper>
                <SellingPrice>
                  {user
                    ? formatDollars(finalProduct?.discountedPrice || item?.discountedPrice)
                    : formatDollars(item?.discountedPrice || finalProduct?.discountedPrice)}
                </SellingPrice>

                <MRP>
                  {user
                    ? formatDollars(finalProduct?.MRP || item?.MRP)
                    : formatDollars(item?.MRP || finalProduct?.MRP)}
                </MRP>
              </DoubleWraper>

              <div>
                <Discount>
                  -{user ? calculateDiscountPercentage() : item.discountPercentage}% OFF
                </Discount>
              </div>
            </PriceBox>
          ) : (
            <PriceBox>
              <SellingPrice>
                {formatDollars(finalProduct?.MRP || item?.MRP)}
              </SellingPrice>
            </PriceBox>
          )}

        </WishlistDetails>

        <BtnBox>
          <AddToCart onClick={addToCart}>Add to cart</AddToCart>
          {/* <DeleteIcon>
            <DeleteOutlinedIcon
              onClick={() => {
                setSelectedItem(item);
                setShowModal(true);
              }}
              style={{ color: "#999999", fontSize: "36px", cursor: "pointer" }}
            />
          </DeleteIcon> */}
          <DelImg
            src={delete_icon}
            onClick={() => {
              setSelectedItem(item);
              setShowModal(true);
            }}

          />
        </BtnBox>
      </BoxManagerResponsive>
    </ItemWrapper>
  );
}

function HeartIcon(bool) {
  function showAlert(event) {
    event.stopPropagation();
  }
  return bool ? (
    <PositionAbsolute onClick={showAlert}>
      <FavoriteIcon style={{ color: "#DA4848" }} />
    </PositionAbsolute>
  ) : (
    <PositionAbsolute onClick={showAlert}>
      <FavoriteBorderOutlinedIcon style={{ color: "#DA4848" }} />
    </PositionAbsolute>
  );
}
export default WishlistItem;

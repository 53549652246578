import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useDispatch } from "react-redux";
import { post } from "../../api_helper/api_helper";
import { addUserInfo } from "../../store/reducer/reducer";

function LayoutPage({ children }) {
  const dispatch = useDispatch();

  async function getUserInfo() {
    try {
      await post("/api/auth/info", {});
    } catch (e) {
      dispatch(addUserInfo(null));
      localStorage.clear();
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    if (token) {
      getUserInfo();
    }
  }, []);

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default LayoutPage;

import React, { useState } from "react";
import styled from "@emotion/styled";
import SearchIcon from "../../assets/images/search.svg";
import { searchTerm } from "../../store/reducer/reducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {  useEffect } from "react";
import { debounce } from "lodash"; // Install lodash: npm install lodash
// const InputField = styled.input`
//   width: 100%;
//   height: 100%;
//   border-radius: 40px;
//   outline: none;
//   border: none;
//   font-family: "Inter";
//   font-weight: 400;
//   font-size: 16px;
//   padding: 10px 16px 10px 16px;
//   box-sizing: border-box;
//   @media (max-width: 600px) {
//     font-size: 14px;
//   }
// `;

const InputField = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 40px;
  outline: none;
  border: none;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  padding: 10px 16px;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    font-size: 15px;
    padding: 8px 14px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    padding: 8px 12px;
  }

    @media (max-width: 600px) {
    font-size: 14px;
  }

 
`;

const SearchIconImg = styled.img`
height: 23px;
    width: 23px;
`;
const InputWrapper = styled.div`
  position: relative;
  width: 60%;
  height: 40px;
  @media (max-width: 1300px) {
   width: 50%;
  }

   @media (max-width: 475px) {
   width: 55%;
  }

  @media (max-width: 475px) {
   width: 55%;
  }

`;

const IconWrapper = styled.div`
position:absolute;
    right: 14px;
    top: 7px;
cursor: pointer;


`;

function InputBox() {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleSearch = debounce((text) => {
    if (text.trim()) {
      dispatch(searchTerm(text)); // Dispatch the search term
      navigate("/products"); // Navigate to products page
    }
  }, 300); // Adjust debounce delay as needed (e.g., 300ms)

  // Use effect to trigger the search function when searchText changes
  useEffect(() => {
    handleSearch(searchText);
    // Cleanup the debounce on unmount
    return () => handleSearch.cancel();
  }, [searchText]);
  return (
    <InputWrapper>
      <InputField
        type="text"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Search products..."
      />
      <IconWrapper>
        <SearchIconImg
          onClick={() => {
            dispatch(searchTerm(searchText));
            navigate("/products");
          }}
          src={SearchIcon}
        />
      </IconWrapper>
    </InputWrapper>
  );
}

export default InputBox;

import React from "react"
import styled from "@emotion/styled"
import Cartitem from "./Cartitem"
import { useSelector } from "react-redux"

const CartBoxWidth = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
@media (max-width: 800px) {
    width: 100%;
    /* max-height: 500px;
    overflow-x: auto; */
  }
`



function CartItemsBox({ cart, setRefetch, hide, updateShippingPrice , setCart}) {
  const user = useSelector((state) => state.general.user);

  // console.log("Checking The CART::-",cart)
  console.log("Cart Item Box", cart)
  return (
    <CartBoxWidth>
      {(user ? cart?.cartItems : cart)?.map(item => (
        <Cartitem
          allItems={user ? cart?.cartItems : cart}
          hide={hide}
          item={item}
          setRefetch={setRefetch}
          updateShippingPrice={updateShippingPrice}
          setCart={setCart}
        />
      ))}

    </CartBoxWidth>
  )
}

export default CartItemsBox
import React from "react";
import styled from "@emotion/styled";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SingleCategory from "./SingleCategory";
import { Link } from "react-router-dom";
import { selectedCategoryFromLandingPage, selectedSubCategory } from "../../store/reducer/reducer";
import { useDispatch } from "react-redux";
const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
  max-width:1525px;

   @media (max-width: 600px) {
   width: 95%;
  }


 
`;

const HeadingWithLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled.div`
  font-family: "Inter";
  font-weight: 500;
  font-size: 24px;
  line-height: 29.05px;
  @media (max-width: 600px) {
    font-size: 19px;
    line-height: 24.05px;
  }
`;

const HeadingLink = styled.div`
  font-family: "Inter";
  font-weight: 500;
  font-size: 24px;
  line-height: 29.05px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 600px) {
    font-size: 19px;
    line-height: 24.05px;
  }
`;

const CategoriesScrollableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  box-sizing: border-box;
  overflow-x: auto; /* Allow horizontal scrolling if content overflows */
  scroll-behavior: smooth; /* Smooth scrolling */
  padding-bottom: 10px; /* Space below items for better touch experience on mobile */
  
  /* Hide scrollbar for Chrome, Safari, Edge, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  img{

   @media (max-width: 600px) {
   width:100px !important;
  height:100px !important;
  }




  name{

  
   @media (max-width: 950px) {
   font-size:0.98rem !important;
 
  }

  
  }
`;

const CategoryWrapperStyled = styled(SingleCategory)`
  flex: 0 0 calc(16.66% - 10px); /* 6 items per row on larger screens */
  margin-right: 20px;

  @media (max-width: 1200px) {
    flex: 0 0 calc(25% - 10px); /* 4 items per row */
  }

  @media (max-width: 900px) {
    flex: 0 0 calc(33.33% - 10px); /* 3 items per row */
  }

  @media (max-width: 600px) {
    flex: 0 0 calc(33.33% - 10px); /* 3 items per row */
  }

  &:last-child {
    margin-right: 0; /* Remove margin for the last item */
  }
`;

function CategoryViewer({ categories }) {
  const dispatch = useDispatch();

  console.log(categories.name)
  return (
    <WidthAdjuster>
      <HeadingWithLink>
        <Heading>Popular Categories</Heading>
        <Link style={{ color: "black" }} to={"/categories"}>
          <HeadingLink>
            See all <ArrowForwardIcon style={{ marginLeft: "10px" }} />
          </HeadingLink>
        </Link>
      </HeadingWithLink>
      <CategoriesScrollableWrapper>
        {categories.slice(0, 6).map((item) => (

          <Link
            key={item.id}
            to="/sub-categories"
            state={{ selectedCategory: item }} // Pass the selected category as selectedCategory
            style={{ textDecoration: "none", color:"black" }}
          >
            <CategoryWrapperStyled
              key={item.id}
              name={item.name}
              img={item.image_icon_url}
              item={item}
            />
          </Link>
        ))}
      </CategoriesScrollableWrapper>
    </WidthAdjuster>
  );
}

export default CategoryViewer;

import React, { useState } from "react";
import { useEffect } from "react";
import styled from "@emotion/styled";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AirplanemodeActiveTwoToneIcon from "@mui/icons-material/AirplanemodeActiveTwoTone";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import EditNoteTwoToneIcon from "@mui/icons-material/EditNoteTwoTone";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
import { post } from "../../api_helper/api_helper";
import { useDispatch } from "react-redux";
import {
  selectedCartItem,
  showUpdateCartItemShipment,
} from "../../store/reducer/reducer";

import { formatDollars } from "../../helper/generalFunctions";
import { useSelector } from "react-redux";
import { Dialog, DialogContent, DialogActions, Typography, Button, Box, Divider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { CircularProgress, Grid } from "@mui/material";
import { Flare } from "@mui/icons-material";
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import TrashBinIcon from "../../assets/images/Delete";

const BtnsBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-end;;
  align-items: center;
  margin-right:15px;
  @media (max-width: 800px) {
    margin-top: 10px;
  }

      @media  (max-width: 1150px) {
  justify-content: start;
}

  @media (min-width: 800px) and (max-width: 1150px) {
margin-bottom:15px;
}
`;

const BtnBox = styled.div`
  /* border: 1px solid #AFAFAF; */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 6.29px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;


`;
const ProductvariantDetailsHeading = styled.div`
  font-family: "Inter";
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 5px;
  @media (max-width: 800px) {
    font-size: 11px;
  }
`;

const Btn = styled.button`
  background: transparent;
  outline: none;

  width: 52px;
  height: 44px;
  // border: 1px solid #afafaf;
  border:none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  color: black;
  cursor: pointer;
  line-height: 24.2px;
  @media (max-width: 800px) {
    width: 32px;
    height: 24px;
    font-size:12px;
  }
`;
const CartItemBox = styled.div`
  width: 100%;
  // border: 1px solid #c7c7c7;
  // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);

  min-height: 243px;
  display: flex;
  // padding:15px;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  border-radius: 20px;
  position:relative;
`;
const ImageBox = styled.div`
  // background: #efefef;
  width: 165px;
  height: 211px;
  border-radius: 16px;
  // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
margin-left:15px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    width: 105px;
    height: 151px;
  }
  /* width: 30%; */
`;

const ItemDetailsBox = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 800px) {
    padding: 10px;
  }

  
  @media (min-width: 1487px) and (max-width: 2000px) {
  width: 83%;
}

  @media (min-width: 1150px) and (max-width: 1487px) {
  width: 78%;
}
`;

const ItemHeading = styled.div`
  font-family: "Inter";
  font-weight: 500;
  line-height: 21.78px;
  font-size:20px;
  text-align: justify;
  margin-bottom:13px;
  @media (max-width: 800px) {
    margin-bottom: 7px;
    line-height: 15px;
    font-size: 14px;
    /* max-height: 500px;
    overflow-x: auto; */
  }

  @media (min-width: 800px) and (max-width: 1150px) {
margin-top:15px;
}
`;

const ProductvariantDetails = styled.div`
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  @media (max-width: 800px) {
    font-size: 11px;
  }
`;
const SmallDetails = styled.div`
  font-family: "Inter";
  font-size: 17px;
  font-weight: 400;
  line-height: 19.36px;
  display: flex;
  color:rgba(66, 69, 69, 1);
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    font-size: 13px;
  }

        @media  (max-width: 450px) {
font-size:11px;
}
`;

const SmallDetailsSpan = styled.div`
  font-family: "Inter";
  font-size: 17px;
  font-weight: 400;
  line-height: 19.36px;
  display: flex;
  color:rgba(66, 69, 69, 1);
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    font-size: 11px;
  }
`;
const PriceAndCartBtnsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
  }

  @media(max-width:1150px){
      flex-direction: column;
  }
`;
const PriceBox = styled.div`
  display: flex;
`;

const SellingPrice = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  color: #000000;
  @media (max-width: 800px) {
    font-size: 15px;
  }
`;
const MRP = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  text-decoration: line-through;
  color: #9a9a9a;
  margin-left: 5px;
  @media (max-width: 800px) {
    font-size: 15px;
  }
`;

const RightFlex = styled.div`

    display: flex
;

  @media (min-width: 1150px) and (max-width: 2000px) {
margin-top:30px;
margin-right:10px;
}

    flex-direction: column;
    gap: 18px;
`;

const PriceFlex = styled.div`

display: flex;
gap: 2px;
  @media (max-width: 1300px) {
gap: 0px;
  }
@media (min-width: 800px) and (max-width: 1150px) {
margin:10px 0;
}
`;


const IconFlex = styled.span`
color:rgba(109, 109, 109, 1);
font-size:36px;

  @media (max-width: 1300px) {
font-size:25px;
  }

    @media (max-width: 800px) {
font-size:20px;
  }
`;

const SingleProduct = styled.div`
display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 10px;
    gap: 7px;
  @media (max-width: 1300px) {
    margin: 0px 4px;
  }
`;

const SingleProductName = styled.span`
  font-family: "Inter";
font-size:18px;
color:rgba(66, 69, 69, 1);
font-weight:500;
  @media (max-width: 1300px) {
font-size:17px;
  }

    @media (max-width: 800px) {
font-size:13px;
  }

        @media  (max-width: 450px) {
font-size:11px;
}


`;

const SingleProductPrice = styled.span`
  font-family: "Inter";
font-size:20px;
color:rgba(131, 132, 132, 1);
font-weight:500;

  @media (max-width: 1300px) {
font-size:17px;
  }

      @media (max-width: 800px) {
font-size:13px;
  }

  
      @media  (max-width: 450px) {
font-size:9px;
}

`;

const SingleDetails = styled.div`
display:flex;
margin:11px 0;
gap:5px;

@media (min-width: 800px) and (max-width: 1150px) {
margin:10px 0;
}

@media  (max-width: 450px) {
margin:5px 0;
}
`;


function Cartitem({ item, setRefetch, hide = false, allItems, updateShippingPrice, setCart }) {
  // console.log("Item Val",item)
  const user = useSelector((state) => state.general.user);
  const TotalPriceCart = item?.TotalPriceCart;
  const ShipPrice = item?.ShippingPrice


  // async function getShippingDetails() {
  //   try {
  //     const shippingDetails = await post("/api/ecom-calculate-shipping", {
  //       productId: item?.productId,
  //       ...(item?.variant && {
  //         productVariantId: item?.variant,
  //       }),
  //     });

  //     setShippingDetails(shippingDetails);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }



  console.log("Ship Price::- ", ShipPrice * item?.quantity)
  console.log("Total Price::- ", TotalPriceCart * item?.quantity)

  // console.log("Checking the item", item)
  const [shippingDetails, setShippingDetails] = useState({});
  // console.log(shippingDetails)
  const parseCurrency = (value) => {
    return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0; // Convert to number and return 0 if NaN
  };
  const price = item?.shipment_type === "AIR" ? formatDollars(shippingDetails?.amountShip) : formatDollars(shippingDetails?.amountAir);
  // console.log(`Checking... ${item?.shipment_type === "AIR" ? formatDollars(shippingDetails?.amountShip) : formatDollars(shippingDetails?.amountAir)} `)
  const priceValue = parseCurrency(price) || 0; // Use parseCurrency to get the numeric value
  // console.log(`Hello there ${shippingDetails?.amountShip ? price : "Nul"}`)
  // console.log("Main Cart",item)

  const dispatch = useDispatch();
  const finalProduct = item?.product_variant
    ? { ...item?.product, ...item?.product_variant }
    : item?.product;
  const Icon =
    item?.shipment_type === "AIR"
      ? AirplanemodeActiveTwoToneIcon
      : DirectionsBoatFilledOutlinedIcon;

  function getAllAttributesString(items) {
    let str = "";
    items?.forEach((el, index) => {
      if (index + 1 === items.length) {
        str += `${el?.name}`;
      } else {
        str += `${el?.name}/`;
      }
    });
    return str;
  }
  function getAllAttributesValuesString(items) {
    let str = "";
    items?.attrbute_value?.forEach((el, count) => {
      if (count + 1 === items?.attrbute_value?.length) {
        str += `${el?.value}`;
      } else {
        str += `${el?.value}/`;
      }
    });

    return str;
  }
  console.log("id", finalProduct?.id)
  async function getShippingDetails() {
    try {
      // Check if `user` is available to determine which product to use
      const shippingDetails = await post("/api/ecom-calculate-shipping", 
        user ? { // If `user` is present, use `finalProduct`
          productId: finalProduct?.id,
          ...(finalProduct?.productVariantId && {
            productVariantId: finalProduct.productVariantId,
          }),
        } : { // If `user` is not available, use `item`
          productId: item?.productId,
          ...(item?.productVariantId && {
            productVariantId: item.productVariantId,
          }),
        });
  
      // Set the shipping details after the API call
      setShippingDetails(shippingDetails);
    } catch (e) {
      console.log(e); // Handle any errors
    }
  }
  
  useEffect(() => {
    if (finalProduct || item) { // Ensure that either `finalProduct` or `item` is available
      getShippingDetails();
    }
  }, [finalProduct, item]); // Trigger effect when either `finalProduct` or `item` changes


// console.log("Hello there",shippingDetails)
    console.log(price)
    const ShippingPrice = priceValue * item?.quantity;
    const TotalPrice = (priceValue * item?.quantity) + (finalProduct?.discountedPrice * item?.quantity)
    const WOLoginToPrice=(priceValue * item?.quantity) + (item?.discountedPrice * item?.quantity)


    useEffect(() => {
      if (priceValue > 0 && item?.quantity > 0) {
        updateShippingPrice(item.id, ShippingPrice);
      }
    }, [priceValue, item?.quantity]);
    // console.log("VAL FOR PROD", TotalPrice)
    return (
      <CartItemBox>
        <ImageBox>
          <img
            style={{
              width: "70%",
            }}
            src={user ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${finalProduct?.image_urls[0]}` : `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item.image}`}
          />
        </ImageBox>

        <ItemDetailsBox>
          <ItemHeading>{user ? finalProduct?.name : item?.name}</ItemHeading>
          {finalProduct?.contain_variants ? (
            <>
              <ProductvariantDetailsHeading>
                {getAllAttributesString(finalProduct?.attributes)}
              </ProductvariantDetailsHeading>
              <ProductvariantDetails>
                {getAllAttributesValuesString(finalProduct)}
              </ProductvariantDetails>
            </>
          ) : null}

          <PriceAndCartBtnsBox>
            <div>
              <SingleDetails>
                <SmallDetails style={{ fontWeight: 600 }}>
                  Estimate days:{" "}
                </SmallDetails>
                <SmallDetails>{user
                  ? item?.duration
                  : item?.shipment_type === "AIR" ? item?.AirDuration : item?.ShipDuration
                }</SmallDetails>
              </SingleDetails>

              {user &&
                <SingleDetails>
                  <SmallDetails style={{ fontWeight: 600 }}>
                    Delivery Date:{" "}
                  </SmallDetails>
                  <SmallDetails>
                    {item?.estimateDelivery
                      ? new Date(
                        new Date(item?.estimateDelivery).getTime() +
                        5 * 24 * 60 * 60 * 1000
                      )
                        .toLocaleDateString("en-US", {
                          weekday: "short",
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })
                        .replace(/,/g, "")
                      : ""}
                  </SmallDetails>
                </SingleDetails>
              }

              <div style={{ display: 'flex', margin: '5px 0' }}>
                <Box
                  sx={{
                    // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)', // Box shadow instead of border
                    // border: "1.5px solid rgb(227, 227, 227)",
                    borderRadius: '8px',
                    // padding: '12px 0px',
                    display: 'inline-block',
                    width: 'fit-content',

                    '@media (max-width: 430px)': {
                      padding: '7px 0px', // Adjust padding when screen width is 430px or less
                    },
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs sx={{
                      paddingRight: "14px", '@media (max-width: 450px)': {
                        paddingRight: '3px', // Apply font-size 11px on max-width 430px
                      },
                    }}>
                      <Typography variant="body2" align="center" color="textSecondary" sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#424545',
                        '@media (max-width: 800px)': {
                          fontSize: '13px', // Apply font-size 11px on max-width 430px
                        },


                        '@media (max-width: 450px)': {
                          fontSize: '11px', // Apply font-size 11px on max-width 430px
                        },


                      }}>
                        Width
                      </Typography>
                      <Typography variant="h6" align="center" sx={{
                        fontSize: '15px',
                        fontWeight: '500',
                        color: '#838484',
                        '@media (max-width: 800px)': {
                          fontSize: '13px', // Apply font-size 11px on max-width 430px
                        },




                        '@media (max-width: 450px)': {
                          fontSize: '11px', // Apply font-size 11px on max-width 430px
                        },


                      }}>
                        {user ? finalProduct?.width : item?.width}(CM)
                      </Typography>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} /> */}

                    <Grid item xs sx={{
                      paddingRight: "14px", '@media (max-width: 450px)': {
                        paddingRight: '3px', // Apply font-size 11px on max-width 430px
                      },
                    }}>
                      <Typography variant="body2" align="center" color="textSecondary" sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#424545',
                        '@media (max-width: 800px)': {
                          fontSize: '13px', // Apply font-size 11px on max-width 430px
                        },


                        '@media (max-width: 450px)': {
                          fontSize: '11px', // Apply font-size 11px on max-width 430px
                        },
                      }}>
                        Height
                      </Typography>
                      <Typography variant="h6" align="center" sx={{
                        fontSize: '15px',
                        fontWeight: '500',
                        color: '#838484',
                        '@media (max-width: 800px)': {
                          fontSize: '13px', // Apply font-size 11px on max-width 430px
                        },


                        '@media (max-width: 450px)': {
                          fontSize: '11px', // Apply font-size 11px on max-width 430px
                        },
                      }}>
                        {user ? finalProduct?.height : item?.height}(CM)
                      </Typography>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} /> */}

                    <Grid item xs sx={{
                      paddingRight: "14px", '@media (max-width: 450px)': {
                        paddingRight: '3px', // Apply font-size 11px on max-width 430px
                      },
                    }}>
                      <Typography variant="body2" align="center" color="textSecondary" sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#424545',
                        '@media (max-width: 800px)': {
                          fontSize: '13px', // Apply font-size 11px on max-width 430px
                        },


                        '@media (max-width: 450px)': {
                          fontSize: '11px', // Apply font-size 11px on max-width 430px
                        },
                      }}>
                        Length
                      </Typography>
                      <Typography variant="h6" align="center" sx={{
                        fontSize: '15px',
                        fontWeight: '500',
                        color: '#838484',
                        '@media (max-width: 800px)': {
                          fontSize: '13px', // Apply font-size 11px on max-width 430px
                        },


                        '@media (max-width: 450px)': {
                          fontSize: '11px', // Apply font-size 11px on max-width 430px
                        },
                      }}>
                        {user ? finalProduct?.length : item?.length}(CM)
                      </Typography>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} /> */}

                    <Grid item xs sx={{
                      paddingRight: "14px", '@media (max-width: 450px)': {
                        paddingRight: '0   px', // Apply font-size 11px on max-width 430px
                      },
                    }}>
                      <Typography variant="body2" align="center" color="textSecondary" sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#424545',
                        '@media (max-width: 800px)': {
                          fontSize: '13px', // Apply font-size 11px on max-width 430px
                        },


                        '@media (max-width: 450px)': {
                          fontSize: '11px', // Apply font-size 11px on max-width 430px
                        },
                      }}>
                        Weight
                      </Typography>
                      <Typography variant="h6" align="center" sx={{
                        fontSize: '15px',
                        fontWeight: '500',
                        color: '#838484',
                        '@media (max-width: 800px)': {
                          fontSize: '13px', // Apply font-size 11px on max-width 430px
                        },


                        '@media (max-width: 450px)': {
                          fontSize: '11px', // Apply font-size 11px on max-width 430px
                        },
                      }}>
                        {user ? finalProduct?.weight : item?.weight}(KG)
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </div>
             {/* {console.log("CART PAGE",item)} */}
              <SingleDetails style={{ gap: 0 }}>
                <SmallDetails style={{ fontWeight: 600 }}>
                  Type of Shipment:{" "}
                </SmallDetails>
                <SmallDetails>
                  <Icon style={{ marginLeft: "5px", color: "#424545" }} />{" "}
                  <span style={{ margin: "0 5px" }}>{item?.shipment_type}</span>
                  {!hide && (
                    <EditNoteTwoToneIcon
                      onClick={() => { 
                        



                        dispatch(showUpdateCartItemShipment(true));

if (user) {
  dispatch(
    selectedCartItem({
      product: item?.product_id,
      variant: item?.product_variant_id,
      cart: item?.id,
    })
  );
} else {
  dispatch(
    selectedCartItem({
      product: item?.productId,
      variant: item?.product_variant_id,
      cart: item?.CartId,
    })
  );
}
                        // console.log(`Check.... ${item?.productId}, ${item?.product_variant_id},  ${item?.id}`)
                      }}
                      style={{
                        marginLeft: "5px",
                        color: "#424545",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </SmallDetails>
              </SingleDetails>
            </div>

            <RightFlex>

              <PriceFlex>
                <SingleProduct style={{ marginLeft: "0" }}>
                  <SingleProductName >Produce Price</SingleProductName>
                  <SingleProductPrice>{user ? formatDollars(finalProduct?.discountedPrice * item?.quantity) : formatDollars(item?.discountedPrice * item?.quantity)}</SingleProductPrice>
                </SingleProduct>
                <IconFlex>+</IconFlex>
                <SingleProduct>
                  <SingleProductName>{item?.shipment_type === "AIR" ? "Airplane Price" : "Ship Price"}</SingleProductName>
                  <SingleProductPrice>{ formatDollars(ShippingPrice) }</SingleProductPrice>
                </SingleProduct>
                <IconFlex>=</IconFlex>
                <SingleProduct>
                  <SingleProductName>Total Price</SingleProductName>
                  <SingleProductPrice style={{ color: "rgba(0, 180, 216, 1)" }}>{user ? formatDollars(TotalPrice) : formatDollars(WOLoginToPrice)}</SingleProductPrice>
                </SingleProduct>
              </PriceFlex>

              {!hide && (
                <CartitemBtn
                  allItems={allItems}
                  item={item}
                  setRefetch={setRefetch}
                  user={user}
                  setCart={setCart}
                />
              )}
            </RightFlex>
          </PriceAndCartBtnsBox>


          {/* <PriceAndCartBtnsBox>
          <div style={{display:"flex"}}>
            <SmallDetails style={{ fontWeight: 600 }}>
              Type of Shipment:{" "}
            </SmallDetails>
            <SmallDetails>
              <Icon style={{ marginLeft: "5px", color: "#424545" }} />{" "}
              <span style={{ margin: "0 5px" }}>{item?.shipment_type}</span>
              {!hide && (
                <EditNoteTwoToneIcon
                  onClick={() => {
                    dispatch(showUpdateCartItemShipment(true));
                    dispatch(
                      selectedCartItem({
                        product: item?.product_id,
                        variant: item?.product_variant_id,
                        cart: item?.id,
                      })
                    );

                    // console.log(`Check.... ${item?.productId}, ${item?.product_variant_id},  ${item?.id}`)
                  }}
                  style={{
                    marginLeft: "5px",
                    color: "#424545",
                    cursor: "pointer",
                  }}
                />
              )}
            </SmallDetails>
          </div>

          {!hide && (
            <CartitemBtn
              allItems={allItems}
              item={item}
              setRefetch={setRefetch}
            />
          )}
        </PriceAndCartBtnsBox> */}
          {/* <PriceAndCartBtnsBox>
          {(user ? finalProduct?.discountedPrice : item?.discountedPrice) ? (
            <PriceBox>
              <SellingPrice>
                {user ? formatDollars(finalProduct?.discountedPrice) : formatDollars(item?.discountedPrice)}
              </SellingPrice>
              <MRP>
                {user ? formatDollars(finalProduct?.MRP) : formatDollars(item?.MRP)}
              </MRP>
            </PriceBox>
          ) : (
            <PriceBox>
              <SellingPrice>
                {user ? formatDollars(finalProduct?.MRP) : formatDollars(item?.MRP)}
              </SellingPrice>
            </PriceBox>
          )}

          {!hide && (
            <CartitemBtn
              allItems={allItems}
              item={item}
              setRefetch={setRefetch}
            />
          )}
        </PriceAndCartBtnsBox> */}
        </ItemDetailsBox>
      </CartItemBox>
    );
  }

  function CartitemBtn({ item, setRefetch, allItems, user, setCart }) {
    // console.log("hello",allItems)
    const [loading, setLoading] = useState(false);

    async function addToCart() {
      try {
        setLoading(true);

        if (user) {
          // For authenticated users, use the API to increase the quantity
          await post("/api/cart/add-to-cart-or-increase-quantity", {
            productId: item?.product_id,
            shipment_type: item?.shipment_type,
            ...(item?.product_variant_id && {
              productVariantId: item?.product_variant_id,
            }),
          });

          setLoading(false);
          toast.success("Cart updated!");
          setRefetch((prev) => !prev); // Trigger refetch to update cart state

        } else {
          // For guest users, handle cart updates using localStorage
          const storedCart = JSON.parse(localStorage.getItem("guestCart")) || [];

          // Find the item in the cart
          const itemIndex = storedCart.findIndex(cartItem => cartItem?.productId === item?.productId);

          if (itemIndex > -1) {
            // If the item exists, just increase the quantity
            storedCart[itemIndex].quantity += 1;

            // Update the cart in localStorage
            localStorage.setItem("guestCart", JSON.stringify(storedCart));

            // Update the cart state
            setCart(storedCart);

            toast.success("Quantity increased!");
          } else {
            // Item not found, don't do anything (or you can show a message)
            toast.error("Item not found in Cart");
          }
        }
      } catch (e) {
        setLoading(false);
        toast.error(e?.response?.data?.message || "Something went wrong");
        console.log(e);
      } finally {
        setLoading(false); // Ensure loading is stopped in case of any failure or success
      }
    }

    // async function addToCart(qty) {  // Accept qty as a parameter
    //   try {
    //     setLoading(true);

    //     // Use the passed qty to set the correct quantity in the request
    //     await post("/api/cart/add-to-cart-or-increase-quantity", {
    //       productId: item?.product_id,
    //       shipment_type: item?.shipment_type,
    //       quantity: qty,  // Use the passed qty (from the wishlist page)
    //       ...(item?.product_variant_id && {
    //         productVariantId: item?.product_variant_id,
    //       }),
    //     });

    //     setLoading(false);
    //     toast.success("Cart updated!");

    //     // Trigger refetch of cart data
    //     setRefetch((prev) => !prev);
    //   } catch (e) {
    //     setLoading(false);
    //     toast.error(e?.response?.data?.message || "Something went wrong");
    //     console.log(e);
    //   }
    // }

    async function removeFromCart() {
      try {
        setLoading(true);

        if (user) {
          // For authenticated users, use the API to remove the item or decrease its quantity
          await post("/api/cart/remove-from-cart-or-decrease-quantity", {
            cartItemId: item?.id,
          });

          setLoading(false);
          toast.success("Cart updated!");
          setRefetch((prev) => !prev);

          // If there's only one item left in the cart, reload the page
          if (allItems.length === 1 && allItems[0].quantity === 1) {
            window.location.reload();
          }
        } else {
          // For guest users, handle cart updates using localStorage
          const storedCart = JSON.parse(localStorage.getItem("guestCart")) || [];

          // Find the index of the item to remove or decrease quantity
          const itemIndex = storedCart.findIndex(cartItem => cartItem?.productId === item?.productId);

          if (itemIndex > -1) {
            const updatedCart = [...storedCart];
            if (updatedCart[itemIndex].quantity > 1) {
              // Decrease the quantity if more than 1
              updatedCart[itemIndex].quantity -= 1;
            } else {
              // Remove the item if the quantity is 1
              updatedCart.splice(itemIndex, 1);
            }

            // Update the cart in localStorage
            localStorage.setItem("guestCart", JSON.stringify(updatedCart));

            // Sync the updated cart with the state
            setCart(updatedCart);
            toast.success("Cart updated!");
          } else {
            toast.error("Item not found in Cart");
          }
        }
      } catch (e) {
        setLoading(false);
        toast.error(e?.response?.data?.message || e?.response?.data?.error || "Something went wrong");
        console.log(e);
      } finally {
        setLoading(false); // Ensure loading is stopped in case of any failure or success
      }
    }



    // async function removeFromWishlist(bool) {

    //   if (user) {
    //     try {
    //       await get(`/api/wishlist/remove/${selectedItem?.id}`);
    //       toast.success("Removed from wishlist");

    //       getUserWishlistItems();
    //     } catch (e) {
    //       toast.error(e?.response?.data?.error || "something went wrong");
    //     }
    //   }

    //   else {

    //     try {
    //       // For guest users, manage wishlist using localStorage
    //       const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];

    //       // Find the index of the item to remove from the wishlist
    //       const itemIndex = storedWishlist.findIndex(item => item.id === selectedItem?.id);

    //       if (itemIndex > -1) {
    //         // Remove the item from the wishlist
    //         storedWishlist.splice(itemIndex, 1);

    //         // Update the wishlist in localStorage
    //         localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
    //         setWishlistItem(storedWishlist)
    //         toast.success("Removed from wishlist");


    //       } else {
    //         toast.error("Item not found in wishlist");
    //       }
    //     } catch (e) {
    //       toast.error(e?.response?.data?.error || "Something went wrong");
    //       console.log(e);
    //     }

    //   }
    // }

    async function deleteItemFromCart() {
      try {
        if (user) {
          setLoading(true);
          await post("/api/cart/delete-cart-item", {
            cartItemId: item?.id,
          });

          setLoading(false);
          toast.success("Removed from cart!");
          setRefetch((prev) => !prev);
        } else {
          // For guest users, manage wishlist using localStorage
          const storedCart = JSON.parse(localStorage.getItem("guestCart")) || [];
          console.log(item?.id)

          // Find the index of the item to remove from the wishlist
          const itemIndex = storedCart.findIndex(item => item?.productId === item?.productId);

          if (itemIndex > -1) {
            // Remove the item from the wishlist
            storedCart.splice(itemIndex, 1);

            // Update the wishlist in localStorage
            localStorage.setItem("guestCart", JSON.stringify(storedCart));
            setCart(storedCart);
            toast.success("Removed from Cart");
          } else {
            toast.error("Item not found in Cart");
          }
        }
      } catch (e) {
        setLoading(false);
        toast.error(e?.response?.data?.message || e?.response?.data?.error || "Something went wrong");
        console.log(e);
      }
    }

    return (
      <BtnsBoxWrapper>
        <BtnBox>
          <Btn disabled={loading} onClick={removeFromCart}>
            <RemoveOutlinedIcon
              sx={{
                color: "black",
                cursor: "pointer",

                "@media (max-width: 800px)": {
                  height: "12px", // example change for smaller screens
                },
              }}
            />

          </Btn>
          <Divider orientation="vertical" flexItem sx={{ height: 'auto' }} />
          <Btn>{item?.quantity}</Btn>
          <Divider orientation="vertical" flexItem sx={{ height: 'auto' }} />
          <Btn disabled={loading} onClick={addToCart}>
            <AddOutlinedIcon sx={{
              color: "black",
              cursor: "pointer",

              "@media (max-width: 800px)": {
                height: "12px", // example change for smaller screens
              },
            }} />
          </Btn>
        </BtnBox>

        <TrashBinIcon
          onClick={deleteItemFromCart}
          sx={{
            marginLeft: "10px",
            color: "black",
            fontSize: "31px",
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "20px",
            height: "25px",
            width: "25px",
            boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "5px",
            padding: "4px",

            '@media (max-width: 450px)': {
              height: "19px",
              width: "19px",
            },
          }}
        />

      </BtnsBoxWrapper>
    );
  }

  export default Cartitem;

import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserInfo,
  showForgotPasswordModal,
  showLoginModal,
  showSignupModal,
} from "../../store/reducer/reducer";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import * as Yup from "yup";
import { post } from "../../api_helper/api_helper";
import { toast } from "react-toastify";
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, Typography, Box } from '@mui/material';

import CloseModal from "./CloseModal";
const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
width: clamp(450px, 30%, 636px);
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

const Heading = styled.div`
  font-family: "Inter";
  font-size: 41px;
  line-height: 33.89px;
  font-weight: 600;
  margin-bottom:45px;
`;

function LoginModal() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const displayModal = useSelector((state) => state.general.showLoginModal);
  const dispatch = useDispatch();
  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const userInfo = await post("/api/auth/sign-in/ecom", {
        email: values.emailAddress,
        password: values.password,
      });
      dispatch(addUserInfo(userInfo));
      localStorage.setItem("auth_token", userInfo.accessToken);
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      setLoading(false);
      dispatch(showLoginModal(false));
      formik.resetForm()

    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
    }
  };
  const ForgotpasswordLink = styled.div`
    font-family: "Inter";
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #00b4d8;
    line-height: 17px;
    margin: 5px 0;
    cursor:pointer;
  `;

  const SignupLine = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  const BlackText = styled.div`
    color: #858b8b;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
  `;
  const Bluetext = styled.div`
    color: #00b4d8;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
    cursor:pointer;
  `;
  const ValidationSchema = Yup.object({
    password: Yup.string().strict(true).required("Password is required!"),
    emailAddress: Yup.string()
      .strict(true)
      .email("Invalid Email Address!")
      .required("Email Address is required!"),
  });
  const formik = useFormik({
    initialValues: { emailAddress: "", password: "" },
    onSubmit,
    validationSchema: ValidationSchema,
    validateOnChange: false, // Disable validation on change
    validateOnBlur: false,    // Disable validation on blur
  });

  return (
    <>
      <Modal
        open={displayModal}
        onClose={() => {
          dispatch(showLoginModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper>
          <CloseModal
            closeFn={() => {
              dispatch(showLoginModal(false));
            }}
          />
          <Heading style={{ color: '#424545' }}>Log In</Heading>

          <div >
            <React.Fragment>
              <form autoComplete="off" onSubmit={formik.handleSubmit}>
                {/* <TextField
                  label="Email Address"
                  variant="outlined"
                  color="primary"
                  type="email"
                  name="emailAddress"
                  placeholder="john@johndoe.com"
                  value={formik.values.emailAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.emailAddress)}
                  helperText={
                    formik.touched.emailAddress && formik.errors.emailAddress
                  }
                  fullWidth
                  style={{
                    borderRadius: "10px",
                    margin: "20px auto",
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize: "16px",
                  }}
                /> */}

                <Box sx={{ width: '100%', maxWidth: 500, margin: ' auto ', marginTop: '20px' }}>
                  <Typography
                    component="label"
                    htmlFor="email-input"
                    sx={{
                      display: 'block',
                      marginBottom: '8px',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '22px',
                      fontWeight: 500,
                      // color: 'rgba(0, 0, 0, 0.87)',
                      // color:'#424545',
                      color: "#5E6363",

                    }}
                  >
                    Email
                  </Typography>
                  <TextField
                    id="email-input"
                    variant="outlined"
                    color="primary"
                    type="email"
                    name="emailAddress"
                    placeholder="john@johndoe.com"
                    value={formik.values.emailAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.emailAddress)}
                    helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '13px',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 400,
                        fontSize: '16px',
                        '& fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.87)',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main',
                        },
                      },
                      '& .MuiInputBase-input': {
                        padding: '16.5px 14px',
                      },
                      marginBottom: '20px',
                    }}
                  />
                </Box>




                {/* <TextField
                  label="Password"
                  variant="outlined"
                  color="primary"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <Visibility
                            onClick={() => {
                              setShowPassword((prev) => !prev);
                            }}
                          />
                        ) : (
                          <VisibilityOff
                            onClick={() => {
                              setShowPassword((prev) => !prev);
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  style={{
                    borderRadius: "10px",
                    // margin: "20px auto",
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize: "16px",
                  }}
                ></TextField> */}

                <Box sx={{ width: '100%', maxWidth: 500, margin: 'auto', marginTop: '4px' }}>
                  <Typography
                    component="label"
                    htmlFor="password-input"
                    sx={{
                      display: 'block',
                      marginBottom: '8px',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '22px',
                      fontWeight: 500,
                      // color: 'rgba(0, 0, 0, 0.87)',
                      // color:'#424545',
                      color: "#5E6363",
                    }}
                  >
                    Password
                  </Typography>
                  <TextField
                    id="password-input"
                    variant="outlined"
                    color="primary"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword((prev) => !prev)}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '13px',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 400,
                        fontSize: '16px',
                        '& fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.87)',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main',
                        },
                      },
                      '& .MuiInputBase-input': {
                        padding: '16.5px 14px',
                      },
                    }}
                  />
                </Box>

                <ForgotpasswordLink>
                  <ForgotpasswordLink
                    onClick={() => {
                      if (loading) {
                        return;
                      }
                      dispatch(showLoginModal(false));
                      dispatch(showForgotPasswordModal(true));
                    }}
                  >
                    Forgot Password?
                  </ForgotpasswordLink>
                </ForgotpasswordLink>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      margin: "20px auto",
                      background: "#00B4D8",
                      color: "white",
                      borderRadius: "24px",
                      width: "80%",
                      height: "43px",
                    }}
                    variant="contained"
                    type="submit"
                    disabled={false}
                  >
                    {loading ? <CircularProgress /> : `Sign In`}
                  </Button>
                </div>
                <SignupLine>
                  <BlackText>Don’t have an account?</BlackText>
                  <Bluetext
                    onClick={() => {
                      if (loading) {
                        return;
                      }
                      dispatch(showLoginModal(false));
                      dispatch(showSignupModal(true));
                    }}
                  >
                    Sign Up
                  </Bluetext>
                </SignupLine>
              </form>
            </React.Fragment>
          </div>
        </LoginFormWrapper>
      </Modal>
    </>
  );
}

export default LoginModal;

import React, { useState } from "react";
import styled from "@emotion/styled";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AirplanemodeActiveTwoToneIcon from "@mui/icons-material/AirplanemodeActiveTwoTone";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import EditNoteTwoToneIcon from "@mui/icons-material/EditNoteTwoTone";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TypeOfShipment from "./TypeOfShipment";
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { formatDollars } from "../../helper/generalFunctions";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useNavigate } from "react-router-dom";
import AddToCartBuyNow from "./AddToCartBuyNow";

import { Box, Card, CardContent, Typography, Grid, Divider } from '@mui/material';
const ParentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

      @media (max-width: 800px) {
   width: 90%;
        margin: auto;
  }

      @media (max-width: 1100px) {
   width: 90%;
        margin: 10px auto;
  }
`;
const Heading = styled.div`
  font-family: "Inter";
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  text-align: justify;
    @media (max-width: 600px) {
     font-size: 21px;
  }

  
`;

const Seller = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  text-align: justify;
  color: #007185;
  margin-bottom: 18px;
  
  // Add underline on hover
  &:hover {
    text-decoration: underline;
  }
`;

const SellingPrice = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  color: #3e4d50;
  line-height: 38.73px;

     @media (max-width: 600px) {
     font-size: 20px;
  }
`;

const BtnsBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 800px) {
    margin-top: 10px;
  }
`;

const BtnBox = styled.div`
  /* border: 1px solid #AFAFAF; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Btn = styled.button`
  background: transparent;
  outline: none;

  width: 52px;
  height: 44px;
  border: 1px solid #afafaf;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
  line-height: 24.2px;
  @media (max-width: 800px) {
    width: 32px;
    height: 24px;
  }
`;

const BtnAb = styled.button`
  background: transparent;
  outline: none;
  display:flex;
  flex-direction:column;

  width: 52px;
  height: 44px;
  border: 1px solid #afafaf;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
  line-height: 24.2px;
  @media (max-width: 800px) {
    width: 32px;
    height: 24px;
  }
`;
const AttributeName = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: #3e4d50;
  margin: 10px 0;
`;
const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
`;
const AttriButeValueBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AttribteValue = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 5px;
`;
const MRP = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  margin-left: 10px;
  text-decoration: line-through;
  color: #999999;

     @media (max-width: 600px) {
     font-size: 17px;
  }
`;

const Discount = styled.div`
  color: #da4848;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin-left: 10px;

     @media (max-width: 600px) {
     font-size: 14px;
  }
`;



function ProductAddtoCartAndDetails({
  typeOfShipment,
  setTypeOfShipment,
  shippingDetails,
  product,
  selectedVariant,
  setSelectedVariant,
  qty,
  setQty,
}) {


  // const finalProduct = product?.contain_variants
  //   ? { ...product, ...product?.product_variants[0] }
  //   : product;

  const finalProduct = product?.contain_variants && product?.product_variants?.length > 0
    ? { ...product, ...product.product_variants[0] }
    : product;

  console.log("Selected Products", finalProduct)



  const measurements = [
    { label: "Width", value: finalProduct.width },
    { label: "Height", value: finalProduct.height },
    { label: "Length", value: finalProduct.length },
    { label: "Weight", value: finalProduct.weight },
  ];
  const calculateDiscountPercentage = () => {
    if (finalProduct?.discountedPrice && finalProduct?.MRP) {
      const discountedPrice = parseFloat(finalProduct.discountedPrice);
      const mrp = parseFloat(finalProduct.MRP);
      const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
      return Math.round(discountPercentage);
    }
    return 0;
  };


  const discountedPrice = finalProduct?.discountedPrice;
  // console.log("DP",discountedPrice)





  // console.log("Fp",finalProduct.brand_name)
  const sellerName = finalProduct.brand_name;

  // function getAllAttributesString(items) {
  //   let str = "";
  //   items.forEach((el, index) => {
  //     if (index + 1 === items.length) {
  //       str += `${el?.name}`;
  //     } else {
  //       str += `${el?.name}/`;
  //     }
  //   });
  //   return str;
  // }

  function getAllAttributesString(items) {
    let str = "";
    if (Array.isArray(items) && items.length > 0) {
      items.forEach((el, index) => {
        if (index + 1 === items.length) {
          str += `${el?.name}`;
        } else {
          str += `${el?.name}/`;
        }
      });
    }
    return str;
  }

  return (
    <ParentWrapper>
      <Heading>{finalProduct?.name || ""}</Heading>
      <PriceContainer>
        {finalProduct?.discountedPrice ? (
          <>
            <SellingPrice>
              {formatDollars(finalProduct?.discountedPrice)}
            </SellingPrice>
            <MRP>{formatDollars(finalProduct?.MRP)}</MRP>
            <Discount>-{calculateDiscountPercentage()}% OFF</Discount>
          </>
        ) : (
          <>
            <SellingPrice>{formatDollars(finalProduct?.MRP)}</SellingPrice>
          </>
        )}
      </PriceContainer>
      {product?.contain_variants ? (
        <>
          <AttributeName>
            {getAllAttributesString(finalProduct?.attributes)}
          </AttributeName>
          <AttriButeValueBox>
            {product?.product_variants?.map((item, index) => {
              let str = "";
              item?.attrbute_value?.forEach((el, count) => {
                if (count + 1 === item?.attrbute_value?.length) {
                  str += `${el?.value}`;
                } else {
                  str += `${el?.value}/`;
                }
              });

              return (
                <AttribteValue
                  style={
                    selectedVariant === item?.id
                      ? { color: `#00B4D8`, border: `1px solid #00B4D8` }
                      : { color: `#3E4D50`, border: `1px solid #3E4D50` }
                  }
                  onClick={() => {
                    setSelectedVariant(item?.id);
                  }}
                >
                  {str}
                </AttribteValue>
              );
            })}
          </AttriButeValueBox>
        </>
      ) : null}



      <div style={{ height: "20px" }}>

      </div>
      <CartitemBtn qty={qty} setQty={setQty} product={product} sellerName={sellerName}  finalProduct={finalProduct}/>



      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
        <Box
          sx={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)', // Box shadow instead of border
            borderRadius: '8px',
            padding: '20px 16px',
            display: 'inline-block',
            width: 'fit-content',

            '@media (max-width: 430px)': {
              padding: '20px 0px', // Adjust padding when screen width is 430px or less
            },
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs style={{ padding: "0px 10px" }}>
              <Typography variant="body2" align="center" color="textSecondary" sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#424545',
                '@media (max-width: 393px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
                Width
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: '#838484',
                '@media (max-width: 393px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
                {finalProduct.width}(CM)
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item xs style={{ padding: "0px 10px" }}>
              <Typography variant="body2" align="center" color="textSecondary" sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#424545',
                '@media (max-width: 393px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
                Height
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: '#838484',
                '@media (max-width: 393px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
                {finalProduct.height}(CM)
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item xs style={{ padding: "0px 10px" }}>
              <Typography variant="body2" align="center" color="textSecondary" sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#424545',
                '@media (max-width: 393px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
                Length
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: '#838484',
                '@media (max-width: 393px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
                {finalProduct.length}(CM)
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item xs style={{ padding: "0px 10px" }}>
              <Typography variant="body2" align="center" color="textSecondary" sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#424545',
                '@media (max-width: 393px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
                Weight
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: '#838484',
                '@media (max-width: 393px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
                {finalProduct.weight}(KG)
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>

      <TypeOfShipment
        shippingDetails={shippingDetails}
        typeOfShipment={typeOfShipment}
        setTypeOfShipment={setTypeOfShipment}
        discountedPrice={discountedPrice}
        qty={qty}
        finalProduct={finalProduct}
      />

      {/* <AddToCartBuyNow qty={qty} typeOfShipment={typeOfShipment} product={product} selectedVariant={selectedVariant} /> */}


    </ParentWrapper>
  );
}
function CartitemBtn({ qty, setQty, product, sellerName,finalProduct }) {
  const navigate = useNavigate();
  const VendorName=finalProduct?.vendor?.username;

  console.log("Inside CartItem",VendorName)

  const handleSellerClick = () => {
    navigate(`/store/${VendorName}`);
  };

  // const finalProduct = product?.contain_variants
  //   ? { ...product, ...product?.product_variants[0] }
  //   : product;



  return (

    <>


      {/* <BtnsBoxWrapper>
      <BtnBox>

        <Btn
          onClick={() => {
            if (qty > 1) {
              setQty((prev) => prev - 1);
            }
          }}
        >


          <RemoveCircleOutlineOutlinedIcon style={{ color: "#999999" }} />
        </Btn>
        <Btn>{qty}</Btn>


        <Btn
          onClick={() => {
            setQty((prev) => prev + 1);
          }}
        >
          <ControlPointOutlinedIcon style={{ color: "#999999" }} />
        </Btn>

      </BtnBox>

    </BtnsBoxWrapper> */}
      <Seller onClick={handleSellerClick} style={{ cursor: "pointer" }}>Visit the {sellerName} Store</Seller>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0px' }}>
        <Box
          sx={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
            borderRadius: '4px',
            padding: '0px',
            display: 'inline-block',
            width: 'fit-content',
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs alignItems="center" justifyContent="center" style={{ padding: "8px 8px" }} onClick={() => {
              if (qty > 1) {
                setQty((prev) => prev - 1);
              }

            }}>
              <RemoveOutlinedIcon style={{ color: "black", cursor: "pointer", marginTop: "5px" }} />
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item xs style={{ color: "black", fontSize: "19px", fontWeight: "500", padding: "8px 8px" }}>
              {qty}
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item style={{ padding: "8px 8px" }} xs onClick={() => {
              setQty((prev) => prev + 1);
            }}>
              <AddOutlinedIcon style={{ color: "black", cursor: "pointer", marginTop: "5px" }} />
            </Grid>
            {/* <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} /> */}

            {/* <Grid item xs>
              <Typography variant="body2" align="center" color="textSecondary">
                Weight
              </Typography>
              <Typography variant="h6" align="center">
                {finalProduct.weight}(KG)
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
      </div>




    </>

  );
}
export default ProductAddtoCartAndDetails;

const Done = ({ color }) => {
    return (
        <svg width="70" height="64" viewBox="0 0 70 64" fill="none">
        <path d="M36.6547 54.9445H0.917969V16.2995L11.9125 0.624023H24.2974V3.09313H13.3028L3.51568 17.0469V52.4754H36.6547V54.9445Z" fill={ color } />
        <path d="M52.8725 32.9694V17.0469L43.0853 3.09313H33.3896V0.624023H44.4756L55.4702 16.2995V32.9681L52.8725 32.9694Z" fill={ color } />
        <path d="M35.9863 15.4385H54.1703V17.9076H35.9863V15.4385Z" fill={ color } />
        <path d="M2.2168 15.4385H20.4008V17.9076H2.2168V15.4385Z" fill={ color } />
        <path d="M21.6597 16.9727L19.1396 16.3737L23.2821 0.624023H34.4886L37.2671 16.4703L34.7047 16.8762L32.2878 3.09313H25.3104L21.6597 16.9727Z" fill={ color } />
        <path d="M24.2973 50.0084H6.11328V32.7246H24.2973V50.0084ZM8.71099 47.5393H21.6995V35.1937H8.71099V47.5393Z" fill={ color } />
        <path d="M11.3086 37.6621H19.1017V40.1312H11.3086V37.6621Z" fill={ color } />
        <path d="M11.3096 42.6016H19.1027V45.0707H11.3096V42.6016Z" fill={ color } />
        <path d="M47.6761 59.8823C38.9761 59.8823 32.0898 53.1641 32.0898 45.0676C32.0898 36.8988 39.0818 30.2529 47.6761 30.2529C50.172 30.2529 52.5559 30.7965 54.7618 31.8686L54.7616 31.8685C60.0051 34.4165 63.2624 39.4739 63.2624 45.0676C63.2624 53.2364 56.2704 59.8823 47.6761 59.8823ZM47.6761 32.722C40.5141 32.722 34.6876 38.2601 34.6876 45.0676C34.6876 51.8389 40.4523 57.4132 47.6761 57.4132C54.8381 57.4132 60.6647 51.8751 60.6647 45.0676C60.6647 40.4058 57.9497 36.1908 53.5792 34.0671L53.579 34.067C51.743 33.1745 49.7569 32.722 47.6761 32.722Z" fill={ color } />
        <path d="M37.2865 30.0909L32.0911 26.7987L28.1945 29.2678L24.298 26.7987L19.1025 30.0909V15.4385H37.2865V30.0909ZM24.298 23.8311L28.1945 26.3002L32.0911 23.8311L34.6888 25.4771V17.9076H21.7003V25.4771L24.298 23.8311Z" fill={ color } />
        <path d="M45.0796 50.5205L40.2646 45.9437L42.1015 44.1978L45.0796 47.0284L51.9543 40.4941L53.7912 42.2401L45.0796 50.5205Z" fill={ color } />
      </svg>
      
    );
  };
  
  export default Done;
  
 
  
import React, { useState } from "react";
import styled from "@emotion/styled";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NoImage from "../../assets/images/no-image.jpeg"
import { get, post } from "../../api_helper/api_helper";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import {
  selectProductToFetchDetails,
  selectedProduct,
  showSelectShipmentModal,
} from "../../store/reducer/reducer";
import { formatDollars } from "../../helper/generalFunctions";
import { useEffect } from "react";
import { addToGuestCart, addToGuestWishlist, removeFromGuestWishlist } from "../../store/reducer/reducer";


// const ParentProductWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   margin: auto;
//   width: 183px;
//   cursor: pointer;
//   @media (max-width: 600px) {
//     width: 97%;
//   }
// `;



// const ImageWrapper = styled.div`
//   width: 200px;
//   height: 255px;
//   border-radius: 8px;
//   border: 1px solid #e3e3e3;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   @media (max-width: 600px) {
//     width: 97%;
//   }
// `;

// const ProductName = styled.div`
//   font-family: "Inter";
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 19.36px;
//   margin-top: 10px;
//   text-align: left;
//   width: 183px;
//   min-height: 38px;
//   overflow: hidden;
//   @media (max-width: 600px) {
//     font-size: 12px;
//     width: 100%;
//     line-height: 15.36px;
//   }
// `;

// const PriceBox = styled.div`
//   display: flex;
//   margin-top: 10px;
//   justify-content: flex-start;
//   width: 100%;
//   @media (max-width: 600px) {
//     flex-direction: column-reverse;
//   }
// `;
// const SellingPrice = styled.span`
//   font-weight: 600;
//   font-family: "Inter";
//   font-size: 20px;
//   line-height: 24.2px;
//   @media (max-width: 600px) {
//     font-size: 15px;
//     line-height: 19.2px;
//   }
// `;
// const MRP = styled.span`
//   font-weight: 600;
//   font-family: "Inter";
//   font-size: 20px;
//   line-height: 24.2px;
//   margin-left: 10px;
//   color: #9a9a9a;
//   text-decoration: line-through;
//   @media (max-width: 600px) {
//     font-size: 15px;
//     line-height: 19.2px;
//     margin-left: 0px;
//   }
// `;
// const Discount = styled.span`
//   font-weight: 400;
//   font-family: "Inter";
//   font-size: 16;
//   line-height: 19.36px;
//   margin-top: 10px;
//   color: #9a9a9a;
//   width: 100%;
// `;
// const AddToCartBtn = styled.button`
//   width: 183px;
//   margin: auto;
//   border: 1px solid #00b4d8;
//   color: #00b4d8;
//   font-family: "Inter";
//   font-size: 20px;
//   font-weight: 400;
//   line-height: 24.2px;
//   outline: none;
//   cursor: pointer;
//   background: white;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 48px;
//   border-radius: 10px;
//   margin: 10px 0 10px 0;

//   @media (max-width: 600px) {
//     width: 100%;
//     font-size: 15px;
//   }
// `;

// const PositionAbsolute = styled.div`
// position: absolute;
// right: 1px;
// top: 10px;
// z-index: 1000;
// `


import { useParams } from "react-router-dom";

const ParentProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s;
  cursor: pointer;
  height: auto;
  margin: 10px;
  max-width: 100%;
  position:relative;

  @media (min-width: 600px) {
    max-width: 500px;
    margin: 20px 18px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%; // Creates a 1:1 aspect ratio
  position: relative;
  overflow: hidden;
 box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const ProductName = styled.div`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  margin: 17px 10px 0;
  text-align: left;
  height: 38px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PriceBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;

  @media (max-width: 922px) {
    font-size: 0.88rem;
  }
`;

const SellingPrice = styled.span`
  font-weight: bold;
  color: black;
`;

const MRP = styled.span`
  text-decoration: line-through;
  color: #999;
  margin-left: 5px;
`;

const Discount = styled.span`
  color: #FF6D6D;
  margin-left: 10px;
  font-weight: bold;
  margin-bottom:19px;
`;

const AddToCartBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #00B4D8;
  border: 1px solid #00B4D8;
  border-radius: 5px;
  padding: 10px;
      margin: 0px auto;
    margin-bottom: 19px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: calc(100% - 20px);
  font-size: 16px;

  &:hover {
    background-color: #008CBA;
    color:white;
  }
`;



const PositionAbsolute = styled.div`
position: absolute;
right: 1px;
top: 10px;
z-index: 1000;
margin-right:5px;
color:black;
`;

function ProductSingleProduct({ wishlistItems, product, displayAddToCartBtn, getUserWishlistItems, cartItems, setCartItems }) {

  // console.log("Testing :- ",wishlistItems);
 


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.general.user);
  const guestId = useSelector((state) => state.general.user)
  const finalProduct = product?.contain_variants
    ? { ...product, ...product?.product_variants[0] }
    : product;

  const calculateDiscountPercentage = () => {
    if (finalProduct?.discountedPrice && finalProduct?.MRP) {
      const discountedPrice = parseFloat(finalProduct.discountedPrice);
      const mrp = parseFloat(finalProduct.MRP);
      const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
      return Math.round(discountPercentage);
    }
    return 0;
  };
  // console.log("Single product", finalProduct);

  const [categoryName, setcategoryName] = useState('');
  const [subcategoryName, setSubCategoryName] = useState('');

  useEffect(() => {
    const name = finalProduct?.category?.name || '';

    // Set the productName in state
    setcategoryName(name);

    // console.log("final categoryName", name);
  }, [finalProduct]);


// console.log("Check product Items",product)


  useEffect(() => {
    const name = finalProduct?.sub_category?.name || '';

    // Set the productName in state
    setSubCategoryName(name);

    // console.log("final categoryName", name);
  }, [finalProduct]);

  // console.log(`/product-details/${categoryName}/${subcategoryName}/${finalProduct.brand_name}/${finalProduct.name}`)
  // console.log("WishList",getUserWishlistItems)
  // const handleAddToCart = (product) => {
  //   if (product?.contain_variants) {
  //     toast.error("Please select variant");
  //     dispatch(selectProductToFetchDetails(product));
  //     navigate(`/product-details/${categoryName}/${subcategoryName}/${product?.brand_name}/${product?.name}`);
  //     return;
  //   } else {
  //     if (!user) {
  //       // Save to local storage for guest user
  //       const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
  //       const newItem = {
  //         id: product.id,
  //         name: product.name,
  //         price: product.MRP,
  //         image: product.image_urls[0],
  //         // Add other necessary properties
  //       };
  //       storedCart.push(newItem);
  //       localStorage.setItem("cart", JSON.stringify(storedCart));
  //       toast.success("Added to cart (guest user)");
  //       return;
  //     }

  //     // For logged-in users, use the existing cart handling
  //     dispatch(selectedProduct({ productId: product.id }));
  //     dispatch(showSelectShipmentModal(true));
  //   }
  // };
  // console.log("Final Products", finalProduct)
  return (
    <div style={{}}>
      <ParentProductWrapper
        style={{}}
        onClick={() => {
          dispatch(selectProductToFetchDetails(product));
          // navigate(`/product-details/${categoryName}/${subcategoryName}/${finalProduct.brand_name}/${finalProduct.name}`);

          navigate(`/product-details/${finalProduct?.category?.name}/${finalProduct?.sub_category?.name}/${finalProduct?.brand_name}/${finalProduct?.name}`);
        }}
      >


        {/* {user && <HeartIcon bool={wishlistItems.find(el => el.product.id === product.id)} item={product} getUserWishlistItems={getUserWishlistItems} />} */}
        <HeartIcon bool={wishlistItems?.find(el => el?.product?.id === product?.id)} item={product} getUserWishlistItems={getUserWishlistItems} user={user} />
        <ImageWrapper>
          <img

            alt="product"
            src={finalProduct?.image_urls?.length
              ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${finalProduct?.image_urls[0]}`
              : NoImage}
          />
        </ImageWrapper>

        <ProductName>{product?.name}</ProductName>

        <PriceBox>
          {finalProduct?.discountedPrice ? (
            <>
              <SellingPrice>{formatDollars(finalProduct?.discountedPrice)}</SellingPrice>
              <MRP>{formatDollars(finalProduct?.MRP)}</MRP>
            </>
          ) : (
            <>
              <SellingPrice>{formatDollars(finalProduct?.MRP)}</SellingPrice>
            </>
          )}
        </PriceBox>
        <Discount>{calculateDiscountPercentage()}% off</Discount>
        {displayAddToCartBtn ? (
          <div

          >
            {/* <AddToCartBtn
              onClick={() => {

                if (finalProduct?.contain_variants) {
                  toast.error("Please select variant");
                  dispatch(selectProductToFetchDetails(product));
                  navigate(`/product-details/${categoryName}/${subcategoryName}/${finalProduct.brand_name}/${finalProduct.name}`);
                  return;
                } else {
                  if (!user) {
                    toast.error("Please login to continue");
                    return;
                  }
                  dispatch(selectedProduct({ productId: finalProduct.id }));
                  dispatch(showSelectShipmentModal(true));
                }
              }}
            >
              <ShoppingCartOutlinedIcon />
              <span >Add to cart</span>
            </AddToCartBtn> */}

            <AddToCartBtn
              onClick={() => {
                if (finalProduct?.contain_variants) {
                  toast.error("Please select variant");
                  dispatch(selectProductToFetchDetails(product));
                  navigate(`/product-details/${categoryName}/${subcategoryName}/${finalProduct.brand_name}/${finalProduct.name}`);
                  return;
                } else {
                  if (!user) {
                    // Handle guest cart for users not logged in
                    const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];

                    // Check if the product already exists in the guest cart
                    const existingItemIndex = guestCart.findIndex(
                      item => item.productId === finalProduct.id
                    );

                    if (existingItemIndex > -1) {
                      // Increase quantity if the item is already in the guest cart
                      guestCart[existingItemIndex].quantity += 1;
                    } else {
                      // Add new item to the guest cart
                      guestCart.push({
                        productId: finalProduct.id,
                        image: finalProduct.image_urls?.[0],
                        quantity: 1,
                        discountedPrice: finalProduct.discountedPrice,
                        MRP: finalProduct.MRP,
                      });
                    }

                    // Save updated guest cart to local storage
                    localStorage.setItem("guestCart", JSON.stringify(guestCart));
                    toast.success("Product has been added to your cart as a guest.");
                  } else {
                    // Handle logged-in users
                    dispatch(selectedProduct({ productId: finalProduct.id }));
                    dispatch(showSelectShipmentModal(true));
                  }
                }
              }}
            >
              <ShoppingCartOutlinedIcon />
              <span>Add to cart</span>
            </AddToCartBtn>


            {/* <AddToCartBtn onClick={() => handleAddToCart(finalProduct)}>
              <ShoppingCartOutlinedIcon />
              <span>Add to cart</span>
            </AddToCartBtn> */}






          </div>
        ) : null}
      </ParentProductWrapper>

    </div>
  );
}
const { slug } = useParams;
console.log("slug here", slug);


// function HeartIcon({ bool, item, getUserWishlistItems }) {
//   const [loading, setLoading] = useState(false)

//   async function addToWishlist() {
//     try {
//       setLoading(true);
//       await get(`/api/wishlist/add/${item?.id}${item?.contain_variants ? `/${item?.product_variants[0].id}` : ``}`,);

//       setLoading(false);
//       toast.success("Added to wishlist");
//       getUserWishlistItems()
//     } catch (e) {
//       setLoading(false);
//       toast.error(e?.response?.data?.error || "something went wrong");
//       console.log(e);
//     }
//   }



//   async function removeFromWishlist() {
//     try {
//       setLoading(true);
//       await get(`/api/wishlist/remove/${bool?.id}`,);

//       setLoading(false);
//       toast.success("Removed from wishlist");
//       getUserWishlistItems()
//     } catch (e) {
//       setLoading(false);
//       toast.error(e?.response?.data?.error || "something went wrong");
//     }
//   }

//   function callFunctionAccordingly(event) {
//     event.stopPropagation();
//     console.log("Hello there")

//     if (bool) {
//       removeFromWishlist()
//     } else {
//       addToWishlist()
//     }
//   }
//   return bool ? (
//     <PositionAbsolute onClick={callFunctionAccordingly}>
//       <FavoriteIcon style={{ color: "rgb(218, 72, 72)" }} />
//     </PositionAbsolute>
//   ) : (
//     <PositionAbsolute onClick={callFunctionAccordingly}>
//       <FavoriteBorderOutlinedIcon style={{ color: "black" }} />
//     </PositionAbsolute>
//   );
// }

// function HeartIcon({ bool, item, getUserWishlistItems }) {
//   const [loading, setLoading] = useState(false);

//   // Function to add item to localStorage wishlist
//   function addToWishlist() {
//     let wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
//     if (!wishlist.find((wishlistItem) => wishlistItem.id === item.id)) {
//       wishlist.push(item);
//       localStorage.setItem('wishlist', JSON.stringify(wishlist));
//       toast.success("Added to wishlist");
//       getUserWishlistItems(); // Refresh wishlist
//     }
//   }

//   // Function to remove item from localStorage wishlist
//   function removeFromWishlist() {
//     let wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
//     wishlist = wishlist.filter((wishlistItem) => wishlistItem.id !== item.id);
//     localStorage.setItem('wishlist', JSON.stringify(wishlist));
//     toast.success("Removed from wishlist");
//     getUserWishlistItems(); // Refresh wishlist
//   }

//   function callFunctionAccordingly(event) {
//     event.stopPropagation();
//     if (bool) {
//       removeFromWishlist();
//     } else {
//       addToWishlist();
//     }
//   }

//   return bool ? (
//     <PositionAbsolute onClick={callFunctionAccordingly}>
//       <FavoriteIcon style={{ color: "rgb(218, 72, 72)" }} />
//     </PositionAbsolute>
//   ) : (
//     <PositionAbsolute onClick={callFunctionAccordingly}>
//       <FavoriteBorderOutlinedIcon style={{ color: "black" }} />
//     </PositionAbsolute>
//   );
// }


function HeartIcon({ bool, item, getUserWishlistItems, user, setWishlistItems, wishlistItems }) {
  // {console.log(item)}
  const [loading, setLoading] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(bool); // Track if the item is in the wishlist

  let brand_name;
  const calculateDiscountPercentage = (item) => {
    if (item.discountedPrice && item.MRP) {
      return Math.round(((item.MRP - item.discountedPrice) / item.MRP) * 100);
    }
    return 0; // Return 0 if no discount is available
  };

  // Sync wishlist state with localStorage or server when component mounts
  useEffect(() => {
    if (!user) {
      // For guest users, check localStorage
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      const isItemInWishlist = storedWishlist.some(wishlistItem => wishlistItem.id === item?.id);
      setIsInWishlist(isItemInWishlist);
    } else {
      // For logged-in users, you can call an API to check the wishlist status
      // Example: Check if item is in the server-side wishlist
      // You may replace this with an API call if needed.
      setIsInWishlist(bool);
    }
  }, [user, bool, item?.id]);

  // Add item to wishlist (for guest users, store in localStorage)
  // const addToWishlist = async () => {
  //   try {
  //     setLoading(true);

  //     if (!user) {
  //       // For guest users, store product details in localStorage
  //       const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //       const newItem = {
  //         id: item?.id,
  //         name: item?.name,
  //         price: item?.MRP,
  //         image: item?.image_urls[0], // Ensure image URL is stored
  //         discountedPrice: item?.discountedPrice,
  //         MRP: item?.MRP,
  //         discountPercentage: calculateDiscountPercentage(item),
  //         weight: item?.weight,
  //         width: item?.width,
  //         length: item?.length,
  //         height: item?.height,
  //       };

  //       storedWishlist.push(newItem);
  //       localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

  //       setIsInWishlist(true); // Set state to true after adding to the wishlist
  //       toast.success("Added to wishlist (guest user)");

  //       setLoading(false);
  //       return;
  //     }

  //     console.log("Product :- ", item)

  //     // For logged-in users, use API
  //     await get(`/api/wishlist/add/${item?.id}${item?.contain_variants ? `/${item?.product_variants[0].id}` : ''}`);

  //     setIsInWishlist(true); // Set state to true after adding to the wishlist
  //     toast.success("Added to wishlist");
  //     setLoading(false);
  //     getUserWishlistItems(); // Refresh wishlist from the server
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e?.response?.data?.error || "Something went wrong");
  //   }
  // };

  const addToWishlist = async () => {
    try {
      setLoading(true);
  
      if (!user) {
        // For guest users, store product details in localStorage
        const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        const newItem = {
          id: item?.id,
          name: item?.name,
          category:item?.categoryName,
          childcategory:item?.childCategoryName,
          subcategory:item?.subCategoryName,
          brand_name:item?.brand_name,
          price: item?.MRP,
          image: item?.image_urls[0], // Ensure image URL is stored
          discountedPrice: item?.discountedPrice,
          MRP: item?.MRP,
          discountPercentage: calculateDiscountPercentage(item),
          weight: item?.weight,
          width: item?.width,
          length: item?.length,
          height: item?.height,
        };
  
        storedWishlist.push(newItem);
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
  
        setIsInWishlist(true); // Set state to true after adding to the wishlist
        toast.success("Added to wishlist (guest user)");
  
        setLoading(false);
        return;
      }
  
      console.log("Product :- ", item?.id);
  
      // For logged-in users, use API
      await get(`/api/wishlist/add/${item?.id}${item?.contain_variants ? `/${item?.product_variants[0].id}` : ''}`);
  
      setIsInWishlist(true); // Set state to true after adding to the wishlist
      toast.success("Added to wishlist");
      setLoading(false);
      getUserWishlistItems(); // Refresh wishlist from the server
    } catch (e) {
      setLoading(false);
  
      // Log the entire error object to see more details
      console.error("Error while adding to wishlist:", e);
  
      // Check if response is available and log it
      if (e?.response) {
        console.error("Response error:", e?.response);
        console.error("Response status:", e?.response?.status);
        console.error("Response data:", e?.response?.data);
      }
  
      toast.error(e?.response?.data?.error || "Something went wrong");
    }
  };
  

  // Remove item from wishlist (for guest users, remove from localStorage)
  const removeFromWishlist = async () => {

    console.log("Removing.....", bool?.id)
    try {
      setLoading(true);

      if (!user) {
        // For guest users, remove from localStorage
        let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        storedWishlist = storedWishlist.filter((wishlistItem) => wishlistItem.id !== item?.id);
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

        setIsInWishlist(false); // Set state to false after removing from wishlist
        toast.success("Removed from wishlist (guest user)");

        setLoading(false);
        return;
      }
      let wishlistItemId = item?.id;
      // For logged-in users, use API
      await get(`/api/wishlist/remove/${bool?.id}`);
      // console.log("Hello ID", item?.id)
      setIsInWishlist(false); // Set state to false after removing from wishlist
      toast.success("Removed from wishlist");
      setLoading(false);
      getUserWishlistItems(); // Refresh wishlist from the server
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error || "Something went wrong");
    }
  };

  // Decide whether to add or remove the item from wishlist
  function callFunctionAccordingly(event) {
    event.stopPropagation();
    if (isInWishlist) {
      removeFromWishlist();
    } else {
      addToWishlist();
    }
  }

  return isInWishlist ? (
    <PositionAbsolute onClick={callFunctionAccordingly}>
      <FavoriteIcon style={{ color: "rgb(218, 72, 72)" }} /> {/* Red when added to wishlist */}
    </PositionAbsolute>
  ) : (
    <PositionAbsolute onClick={callFunctionAccordingly}>
      <FavoriteBorderOutlinedIcon style={{ color: "black" }} /> {/* Black when not in wishlist */}
    </PositionAbsolute>
  );
}






export default ProductSingleProduct;

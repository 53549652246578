import React, { useState } from "react";
import styled from "@emotion/styled";
import { post } from "../../api_helper/api_helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { formatDollars } from "../../helper/generalFunctions";
import { BorderBottom } from "@mui/icons-material";
import { useSelector } from 'react-redux';

const CartBoxWidth = styled.div`
  padding: 0 20px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 10px 0;
  @media (max-width: 800px) {
    width: 90% !important;
  }
`;

const CouponHeading = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 15px;

    @media (max-width: 423px) {
  font-size: 16px;
  }
`;
const CouponInput = styled.input`
  width: 316px;
  height: 44px;
  text-align: start;
  padding : 0 15px;
  border: none;
  outline: none;
  background: #f3f3f3;
  color: #838484;
  border-radius: 8px;
`;
const ApplyBtn = styled.button`
  outline: none;
  border: none;
  background: #00b4d8;
  color: white;
  border-radius: 8px;
  padding:10px 10px;

  font-family: "Inter";
  font-size: 20px;
  font-weight: 300;
  line-height: 20px;
  margin-left: 10px;
  cursor: pointer;

      @media (max-width: 423px) {
  font-size: 14px;
    margin-left: 3px;
    padding: 8px 5px;
  }
`;

const Title = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: #242626;
  display: flex;
  justify-content: flex-start;
  align-items: center;

        @media (max-width: 423px) {
  font-size: 16px;
    margin-left: 3px;
  }
`;
const Detail = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: #242626;
  display: flex;
  justify-content: flex-end;
  align-items: center;

          @media (max-width: 423px) {
  font-size: 16px;

  }
`;
const TitleSmall = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: #838484;
  display: flex;
  justify-content: flex-start;
  align-items: center;

            @media (max-width: 423px) {
  font-size: 16px;

  }
`;
const DetailSmall = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 300;
  line-height: 20px;
  color: #838484;
  display: flex;
  justify-content: flex-end;
  align-items: center;

            @media (max-width: 423px) {
  font-size: 16px;

  }
`;
const ProductCounter = styled.span`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 10px;

            @media (max-width: 423px) {
  font-size: 13px;
   margin-left: 3px;

  }
`;
const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

`;

const CheckoutBtn = styled.button`
  width: 100%;
  background: #00b4d8;
  color: white;
  border-radius: 8px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
`;
function CartDetailsBox({ getUserCartInfo, cart, coupon_code, setCouponCode }) {
  const grandShippingValue = useSelector(
    (state) => state.general.grandShippingValue
  );

  const subTotal = cart?.subTotal || 0;
  const shipping = grandShippingValue || 0;
  const taxes = cart?.taxes || 0;
  const discount = cart?.discountAmount || 0;
  
  const GrandTotalValue = subTotal + shipping + taxes - discount;
  // console.log("In Coupen ",GrandTotalValue)
  const [showRemove, setShowRemove] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  async function checkAndApplyCoupon() {
    if (showRemove) {
      setCouponCode("");
      setShowRemove(false);
      getUserCartInfo(true);
      return;
    }
    try {
      setLoading(true);
      await post("/api/get-coupon-by-name-shipment", {
        coupon_name: coupon_code,
        grandTotal: cart?.grandTotal || 0,
        order_type: "ECOMMERCE"
      });

      setLoading(false);
      toast.success("Coupon applied!");
      setShowRemove(true);
      getUserCartInfo();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error || "something went wrong");
      console.log(e);
    }
  }

  console.log("Checkkkkk", cart)

  return (
    <CartBoxWidth>
      <CouponHeading>Coupon Code</CouponHeading>
      <BoxWrapper style={{}}>
        <CouponInput
          placeholder="Enter Coupon Code"
          type="text"
          value={coupon_code}
          onChange={(e) => {
            setCouponCode(e.target.value);
          }}
        />

        <ApplyBtn
          disabled={loading || !cart?.cartItems?.length}
          onClick={checkAndApplyCoupon}
        >
          {showRemove ? `REMOVE` : `Apply`}
        </ApplyBtn>
      </BoxWrapper>

      <div style={{ display: "flex", justifyContent: "right", width: "100%", marginTop: "14px", borderBottom: "1px dotted black", paddingBottom: "14px" }}>
        <span style={{ fontSize: "14px", color: "#006FFF", textDecoration: "underline", cursor: "pointer" }}>View promo codes</span>
      </div>




      <div style={{ marginTop: "20px" }}></div>

      <BoxWrapper style={{ marginBottom: "10px" }}>
        <TitleSmall>Product Total</TitleSmall>
        <DetailSmall>{formatDollars(cart?.subTotal)}</DetailSmall>
      </BoxWrapper>

      <BoxWrapper style={{ marginBottom: "10px" }}>
        <TitleSmall>Shipping Charges</TitleSmall>
        <DetailSmall>{formatDollars(grandShippingValue)}</DetailSmall>
      </BoxWrapper>
      <BoxWrapper style={{ marginBottom: "10px" }}>
        <TitleSmall>Taxes</TitleSmall>
        <DetailSmall>{formatDollars(cart?.taxes)}</DetailSmall>
      </BoxWrapper>
      <BoxWrapper style={{ marginBottom: "10px" }}>
        <TitleSmall>Discount</TitleSmall>
        <DetailSmall>-{formatDollars(cart?.discountAmount)}</DetailSmall>
      </BoxWrapper>


      <div
        style={{ border: "1px solid #AFAFAF", margin: "10px 0", width: "100%" }}
      ></div>
      <BoxWrapper>
        <div style={{ display: "flex" }}>
          <Title>Total</Title>{" "}
          <ProductCounter>
            {` ( ${cart?.cartItems?.length || 0} products )`}
          </ProductCounter>
        </div>
        <Detail>{formatDollars(GrandTotalValue)}</Detail>
      </BoxWrapper>
      <CheckoutBtn
        disabled={!cart?.cartItems?.length}
        onClick={() => navigate("/checkout")}
      >
        Checkout
      </CheckoutBtn>
    </CartBoxWidth>
  );
}

export default CartDetailsBox;

import React, { useState } from "react"
import styled from "@emotion/styled"
import SelectAddress from "../../components/OrderCheckout/SelectAddress";
import { useNavigate } from "react-router-dom";
import Checkout from "../../components/OrderCheckout/Checkout";

const BodyWrapper = styled.div`
min-height: 20%;
background:white ;
width: 100%;
padding-bottom: 40px;
`

function OrderCheckout(){
    const navigation = useNavigate()
    const [currentTab,setCurrentTab] = useState(1)

    return (
        <BodyWrapper>
            {currentTab ===2?<Checkout currentTab={currentTab} setCurrentTab={setCurrentTab}/>:null}
            {currentTab ===1?<SelectAddress currentTab={currentTab} setCurrentTab={setCurrentTab}/>:null}
        </BodyWrapper>
    )
}

export default OrderCheckout
import React from "react";
import styled from "@emotion/styled";
import ShiporaWhiteLogo from "../../assets/images/shipora-logo-white.svg";
import InputBox from "./SearchBoxInput";
import MarketPlaceButton from "./MarketPlaceButton";
import UserTools from "./UserTools";
import UserProfile from "./LoginSignup";
import { useNavigate } from "react-router-dom";
const ParentWrapper = styled.div`
  height: 161px;
  width: 100%;
`;
const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  height: 100%;
  max-width:1525px;
  @media (max-width: 600px) {
    width: 97%;
  }
`;
const SearchLogoWrapper = styled.div`
  background: #003e4c;
  height: 96px;
  width: 100%;
`;
const UserToolsWrapper = styled.div`
  background: #25626f;
  width: 100%;
  height: 64px;
  border-top: 1px solid white;
`;

const SpaceBetweenFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
const MainLogoImage = styled.img`
  width: 174px;
  height: 64px;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 80px;
    height: 30px;
  }

  @media (max-width: 1150px) {
    width: 140px;
    height: 49px;
  }
  
     @media (max-width: 475px) {
    width: 108px;
   
  }
`;

function Header() {
  const navigate = useNavigate();
  return (
    <ParentWrapper>
      <SearchLogoWrapper>
        <WidthAdjuster>
          <SpaceBetweenFlex>
            <MainLogoImage
              onClick={() => {
                navigate("/");
                window.location.reload();
              }}
              src={ShiporaWhiteLogo}
            />
            <InputBox />

            <MarketPlaceButton />
          </SpaceBetweenFlex>
        </WidthAdjuster>
      </SearchLogoWrapper>
      <UserToolsWrapper>
        <WidthAdjuster>
          <SpaceBetweenFlex>
            <UserTools />
            <UserProfile />
          </SpaceBetweenFlex>
        </WidthAdjuster>
      </UserToolsWrapper>
    </ParentWrapper>
  );
}

export default Header;

import React from "react";
import styled from "@emotion/styled";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useDispatch } from "react-redux";
import { selectProductToFetchDetails } from "../../store/reducer/reducer";
import { useNavigate } from "react-router-dom";
import { formatDollars } from "../../helper/generalFunctions";
import NoImage from "../../assets/images/no-image.jpeg";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { get, post } from "../../api_helper/api_helper";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { toast } from "react-toastify";
import { useRef } from "react";
import { bool } from "yup";
const ParentProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s;
  cursor: pointer;
  height: auto;
  margin: 10px;
  max-width: 100%;
  position:relative;

  @media (min-width: 600px) {
    max-width: 500px;
    margin: 20px 18px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%; // Creates a 1:1 aspect ratio
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const ProductName = styled.div`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  margin: 17px 10px 0;
  text-align: left;
  height: 38px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PriceBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;

  @media (max-width: 922px) {
    font-size: 0.88rem;
  }
`;

const SellingPrice = styled.span`
  font-weight: bold;
  color: black;
`;

const MRP = styled.span`
  text-decoration: line-through;
  color: #999;
  margin-left: 5px;
`;

const Discount = styled.span`
  color: #999;
  margin-left: 10px;
  font-weight: bold;
  margin-bottom:19px;
`;

const AddToCartBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00B4D8;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: calc(100% - 20px);

  &:hover {
    background-color: #008CBA;
  }
`;
const PositionAbsolute = styled.div`
position: absolute;
right: 1px;
top: 10px;
z-index: 1000;
margin-right:5px;
color:black;
`;
// export default function SingleCommonProducts({ product, displayAddToCartBtn, wishlistItems, getUserWishlistItems, isWishlistLoading }) {
export default function SingleCommonProducts({ product, displayAddToCartBtn, wishlistItems, getUserWishlistItems, setWishlistItems }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.general.user);
  const renderCount = useRef(0);

  // console.log("Checking....", wishlistItems)
  const isProductInWishlist = wishlistItems?.find(el => el?.id === product?.id);
  console.log("Is product in wishlist:", isProductInWishlist);
  // console.log(product)

  //   console.log("Check Fom Common",product)
  // console.log("ITEM ID" ,product)
  const finalProduct = product;
  // console.log("Common SLider",finalProduct)

  //   const { category, sub_category, brand_name, name } = product?.product;

  useEffect(() => {
    renderCount.current += 1;
    // console.log(`SingleProduct (${product.id}) render #${renderCount.current}. wishlistItems:`, wishlistItems);
  });

  const calculateDiscountPercentage = () => {
    if (finalProduct?.discountedPrice && finalProduct?.MRP) {
      const discountedPrice = parseFloat(finalProduct.discountedPrice);
      const mrp = parseFloat(finalProduct.MRP);
      const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
      return Math.round(discountPercentage);
    }
    return 0;
  };

  const handleProductClick = () => {
    dispatch(selectProductToFetchDetails(finalProduct));
    navigate(`/product-details/${finalProduct?.categoryName
      }/${finalProduct?.subCategoryName}/${finalProduct?.brand_name}/${finalProduct?.name}`);
  };

  //   const isInWishlist = Array.isArray(wishlistItems) && wishlistItems.some(item => item.product.id === product.id);
  useEffect(() => {
    console.log("ProductSlider: wishlistItems updated:", wishlistItems);
  }, [wishlistItems]);
  return (
    <ParentProductWrapper onClick={handleProductClick}>
      {/* {user && (
        <HeartIcon
        //   isInWishlist={isInWishlist}
          product={product}
        //   getUserWishlistItems={getUserWishlistItems}
        //   isWishlistLoading={isWishlistLoading}
        />
      )} */}





      <HeartIcon bool={wishlistItems?.find(el => el?.product?.id === product?.id)} item={product} user={user} setWishlistItems={setWishlistItems} />

      {console.log("Hello Nool", typeof (bool))}
      <ImageWrapper>
        <img
          alt="product"
          src={
            finalProduct?.image_urls?.length
              ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${finalProduct?.image_urls[0]}`
              : NoImage
          }
        />
      </ImageWrapper>
      <ProductName>{finalProduct?.name}</ProductName>
      <PriceBox>
        {finalProduct?.discountedPrice ? (
          <>
            <SellingPrice>{formatDollars(finalProduct?.discountedPrice)}</SellingPrice>
            <MRP>{formatDollars(finalProduct?.MRP)}</MRP>
          </>
        ) : (
          <SellingPrice>{formatDollars(finalProduct?.MRP || 0)}</SellingPrice>
        )}
      </PriceBox>
      <Discount>{calculateDiscountPercentage()}% off</Discount>
      {displayAddToCartBtn && (
        <AddToCartBtn>
          <ShoppingCartOutlinedIcon style={{ color: "white" }} />
          <span style={{ marginLeft: "5px" }}>Add to cart</span>
        </AddToCartBtn>
      )}
    </ParentProductWrapper>
  );
}

function HeartIcon({ bool, item, getUserWishlistItems, user, setWishlistItems }) {
  const [loading, setLoading] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(bool); // Track if the item is in the wishlist

  const calculateDiscountPercentage = (item) => {
    if (item.discountedPrice && item.MRP) {
      return Math.round(((item.MRP - item.discountedPrice) / item.MRP) * 100);
    }
    return 0; // Return 0 if no discount is available
  };

  // Sync wishlist state with localStorage or server when component mounts
  useEffect(() => {
    if (!user) {
      // For guest users, check localStorage
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      const isItemInWishlist = storedWishlist.some(wishlistItem => wishlistItem.id === item?.id);
      setIsInWishlist(isItemInWishlist);
    } else {
      // For logged-in users, you can call an API to check the wishlist status
      // Example: Check if item is in the server-side wishlist
      // You may replace this with an API call if needed.
      setIsInWishlist(bool);
    }
  }, [user, bool, item?.id]);

  // Add item to wishlist (for guest users, store in localStorage)
  const addToWishlist = async () => {
    try {
      setLoading(true);

      if (!user) {
        // For guest users, store product details in localStorage
        const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        const newItem = {
          id: item?.id,
          name: item?.name,
          price: item?.MRP,
          image: item?.image_urls[0], // Ensure image URL is stored
          discountedPrice: item?.discountedPrice,
          MRP: item?.MRP,
          discountPercentage: calculateDiscountPercentage(item),
          weight: item?.weight,
          width: item?.width,
          length: item?.length,
          height: item?.height,
        };

        storedWishlist.push(newItem);
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

        setIsInWishlist(true); // Set state to true after adding to the wishlist
        toast.success("Added to wishlist (guest user)");

        setLoading(false);
        return;
      }

      console.log("Product :- ", item)

      // For logged-in users, use API
      await get(`/api/wishlist/add/${item?.id}${item?.contain_variants ? `/${item?.product_variants[0].id}` : ''}`);

      setIsInWishlist(true); // Set state to true after adding to the wishlist
      toast.success("Added to wishlist");
      setLoading(false);
      getUserWishlistItems(); // Refresh wishlist from the server
    } catch (e) {
      setLoading(false);
      // toast.error(e?.response?.data?.error || "Something went wrong");
    }
  };

  // Remove item from wishlist (for guest users, remove from localStorage)
  const removeFromWishlist = async () => {

    console.log("Removing.....", typeof (bool))
    try {
      setLoading(true);

      if (!user) {
        // For guest users, remove from localStorage
        let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        storedWishlist = storedWishlist.filter((wishlistItem) => wishlistItem.id !== item?.id);
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

        setIsInWishlist(false); // Set state to false after removing from wishlist
        toast.success("Removed from wishlist (guest user)");

        setLoading(false);
        return;
      }
      let wishlistItemId = item?.id;
      // For logged-in users, use API
      console.log(bool?.id)
      await get(`/api/wishlist/remove/${bool?.id}`);
      console.log("Hello ID", bool?.id)
      setIsInWishlist(false); // Set state to false after removing from wishlist
      toast.success("Removed from wishlist");
      setLoading(false);
      getUserWishlistItems(); // Refresh wishlist from the server
    } catch (e) {
      setLoading(false);
      // toast.error(e?.response?.data?.error || "Something went wrong");
    }
  };

  // Decide whether to add or remove the item from wishlist
  function callFunctionAccordingly(event) {
    event.stopPropagation();
    if (isInWishlist) {
      removeFromWishlist();
    } else {
      addToWishlist();
    }
  }

  return isInWishlist ? (
    <PositionAbsolute onClick={callFunctionAccordingly}>
      <FavoriteIcon style={{ color: "rgb(218, 72, 72)" }} /> {/* Red when added to wishlist */}
    </PositionAbsolute>
  ) : (
    <PositionAbsolute onClick={callFunctionAccordingly}>
      <FavoriteBorderOutlinedIcon style={{ color: "black" }} /> {/* Black when not in wishlist */}
    </PositionAbsolute>
  );
}



// function HeartIcon({ isInWishlist, product, getUserWishlistItems, isWishlistLoading }) {
//   const handleWishlistAction = async (event) => {
//     event.stopPropagation();
//     if (isWishlistLoading) return;

//     try {
//       if (isInWishlist) {
//         await get(`/api/wishlist/remove/${product.id}`);
//         toast.success("Removed from wishlist");
//       } else {
//         await get(`/api/wishlist/add/${product.id}${product?.contain_variants ? `/${product?.product_variants[0].id}` : ''}`);
//         toast.success("Added to wishlist");
//       }
//       getUserWishlistItems();
//     } catch (error) {
//       console.error("Error updating wishlist:", error);
//       toast.error("Failed to update wishlist");
//     }
//   };

//   return (
//     <PositionAbsolute onClick={handleWishlistAction}>
//       {isWishlistLoading ? (
//         <span>Loading...</span>
//       ) : isInWishlist ? (
//         <FavoriteIcon style={{ color: "black" }} />
//       ) : (
//         <FavoriteBorderOutlinedIcon style={{ color: "black" }} />
//       )}
//     </PositionAbsolute>
//   );
// }
import React, { useState } from "react";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { post } from "../../api_helper/api_helper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { isBuyNowItemSelected } from "../../store/reducer/reducer";
import { useNavigate } from "react-router-dom";

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 55%;
  padding: 20px;
  @media (max-width: 800px) {
    width: 60%;
    margin: auto;
  }

    @media (max-width: 1100px) {
    width: 90%;
    margin: 10px auto;

  }
`;

const AddToCart = styled.div`
  border: 1px solid #19889f;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  color: #19889f;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
`;
const BuyNowBtn = styled.div`
  border: 1px solid transparent;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  background: #ffc727;
  color: #454545;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
`;

function AddToCartBuyNow({ selectedVariant, product ,typeOfShipment, qty}) {
  const [loading, setLoading] = useState(false);
  const user = useSelector(state=>state.general.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  async function addToCart() {
    try {
      if(!user){
        toast.error("Please login")
        return
      }
      if (product?.contain_variants && !selectedVariant) {
        toast.error("Please select a variant to continue");
        return;
      }
      setLoading(true);
      await post("/api/cart/add-to-cart-or-increase-quantity", {
        productId: product.id,
        shipment_type:typeOfShipment,
        quantity:qty,
        fromDetailsPage:true,
        ...(selectedVariant && { productVariantId: selectedVariant }),

      });

      setLoading(false);
      toast.success("Added to cart!")
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  }
  async function addToBuyNow() {
    try {
      if(!user){
        toast.error("Please login")
        return
      }
      if (product?.contain_variants && !selectedVariant) {
        toast.error("Please select a variant to continue");
        return;
      }
      setLoading(true);
      await post("/api/buy-now/add", {
        productId: product.id,
        shipment_type:typeOfShipment,
        user_id:user?.id,
        quantity:qty,
        ...(selectedVariant && { productVariantId: selectedVariant }),
      });

      setLoading(false);
      dispatch(isBuyNowItemSelected(true))
      navigate("/checkout")
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  }

  return (
    <BtnWrapper>
      <AddToCart onClick={addToCart}>
        {loading ? <CircularProgress /> : `Add to Cart`}
      </AddToCart>
      <BuyNowBtn onClick={addToBuyNow}>
      {loading ? <CircularProgress /> : `Buy Now`}
      </BuyNowBtn>
    </BtnWrapper>
  );
}

export default AddToCartBuyNow;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ProductDescription from "./ProductDescription";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { useSelector } from "react-redux";
import { get, post } from "../../api_helper/api_helper";
import FavoriteIcon from "@mui/icons-material/Favorite";

import { toast } from "react-toastify";
const ShowCaseWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50%;
  flex-direction: column;
  position:relative;
 
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 20px;
  }

   @media (max-width: 820px) {
    width: 100%;
    margin-bottom: 20px;
  }

   @media (max-width: 1100px) {
    width: 100%;

  }
`;
const HeroImageWrapper = styled.div`
  width: 90%;
  max-height: 100%;
//  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position:relative;
  min-height: 605px;

    @media (max-width: 600px) {
 min-height:290px;
  }
`;
const ShowCaseSmallImages = styled.div`
  // overflow-x: auto;
  width: 100%;
  max-width: 90%; /* Set max-width to prevent expanding beyond a certain width */
  gap: 10px;
  margin: 10px auto;
  display: flex; /* Keep display: flex; to display images side by side */
  flex-wrap: nowrap;
`;
const SmallerImages = styled.div`
  width: 135px;
  height: 111px;
  gap: 0px;
  border-radius: 8px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  min-height: 90px;
  flex: 0 0 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SmallImageStyle = styled.img`
  width: 50px;
  cursor: pointer;
`;
const HeroImageStyle = styled.img`
height: 100%;
  max-height: 605px;
  object-fit: cover;
  width : 100%
`;
const PositionAbsolute = styled.div`
position: absolute;
right: 7px;
top: 10px;
z-index: 1000;
cursor:pointer;

color:black;
`;
function ProuductShowCase({ selectedVariant, product, getUserWishlistItems, setWishlistItems, wishlistItems  }) {
  // const selectImages = product?.contain_variants ? selectedVariant ? product?.product_variants?.find((elem) => elem.id === selectedVariant) ?.image_urls : product?.product_variants[0]?.image_urls : product?.image_urls;
  const selectImages = product?.contain_variants
    ? selectedVariant
      ? product?.product_variants?.find((elem) => elem.id === selectedVariant)?.image_urls
      : product?.product_variants?.length > 0
        ? product.product_variants[0].image_urls
        : undefined
    : product?.image_urls;
    const user = useSelector((state) => state.general.user);

  // console.log("Check the products:- ",product)


  const [images, setImages] = useState(selectImages || []);
  const [selectedImage, setSelectedImage] = useState(
    images?.length > 0 ? images[0] : null
  );

  useEffect(() => {
    setImages(selectImages || []);
    setSelectedImage(selectImages?.length > 0 ? selectImages[0] : null);
  }, [selectedVariant]);

  return (
    <ShowCaseWrapper>


      <HeroImageWrapper>
        <HeroImageStyle
          src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${selectedImage}`}
          alt="hero"
        />

        <HeartIcon bool={wishlistItems?.find(el => el?.product?.id === product?.id)} item={product} getUserWishlistItems={getUserWishlistItems} user={user} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} />
      </HeroImageWrapper>
      <ShowCaseSmallImages>
        {images
          .filter((el) => el !== selectedImage)
          .map((el) => {
            return (
              <SmallerImages
                onClick={() => {
                  setSelectedImage(el);
                }}
              >
                <SmallImageStyle
                  src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${el}`}
                  alt="hero-small"
                />
              </SmallerImages>
            );
          })}
      </ShowCaseSmallImages>

      {/* <ProductDescription product={product} /> */}
    </ShowCaseWrapper>
  );
}

function HeartIcon({ bool, item, getUserWishlistItems, user, setWishlistItems, wishlistItems }) {
  // {console.log(item)}
  const [loading, setLoading] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(bool); // Track if the item is in the wishlist

  let brand_name;
  const calculateDiscountPercentage = (item) => {
    if (item.discountedPrice && item.MRP) {
      return Math.round(((item.MRP - item.discountedPrice) / item.MRP) * 100);
    }
    return 0; // Return 0 if no discount is available
  };

  // Sync wishlist state with localStorage or server when component mounts
  // useEffect(() => {
  //   if (!user) {
  //     // For guest users, check localStorage
  //     const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //     const isItemInWishlist = storedWishlist.some(wishlistItem => wishlistItem.id === item?.id);
  //     setIsInWishlist(isItemInWishlist);
  //   } else {
  //     // For logged-in users, you can call an API to check the wishlist status
  //     // Example: Check if item is in the server-side wishlist
  //     // You may replace this with an API call if needed.
  //     setIsInWishlist(bool);
  //   }
  // }, [user, bool, item?.id]);

  useEffect(() => {
    if (user) {

   
      // For logged-in users, you can call an API to check the wishlist status
      // Example: Check if item is in the server-side wishlist
      // You may replace this with an API call if needed.
      setIsInWishlist(bool);
    }
  }, [user, bool, item?.id]);

  useEffect(() => {
    if (!user) {
    const isItemInWishlist = wishlistItems?.some(wishlistItem => wishlistItem?.id === item?.id);
    setIsInWishlist(isItemInWishlist);}
  }, [wishlistItems, item?.id]); 

  // Add item to wishlist (for guest users, store in localStorage)
  const addToWishlist = async () => {
    try {
      setLoading(true);

      if (!user) {
        // For guest users, store product details in localStorage
        const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        const newItem = {
          id: item?.id,
          name: item?.name,
          category:item?.categoryName,
          childcategory:item?.childCategoryName,
          subcategory:item?.subCategoryName,
          brand_name:item?.brand_name,
          price: item?.MRP,
          image: item?.image_urls[0], // Ensure image URL is stored
          discountedPrice: item?.discountedPrice,
          MRP: item?.MRP,
          discountPercentage: calculateDiscountPercentage(item),
          weight: item?.weight,
          width: item?.width,
          length: item?.length,
          height: item?.height,
        };

        storedWishlist.push(newItem);
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

        setIsInWishlist(true); // Set state to true after adding to the wishlist
        toast.success("Added to wishlist (guest user)");

        setLoading(false);
        getUserWishlistItems();
        return;
      }

      console.log("Product :- ", item)

      // For logged-in users, use API
      await get(`/api/wishlist/add/${item?.id}${item?.contain_variants ? `/${item?.product_variants[0].id}` : ''}`);

      setIsInWishlist(true); // Set state to true after adding to the wishlist
      toast.success("Added to wishlist");
      setLoading(false);
      getUserWishlistItems(); // Refresh wishlist from the server
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error || "Something went wrong");
    }
  };

  // Remove item from wishlist (for guest users, remove from localStorage)
  const removeFromWishlist = async () => {

    console.log("Removing.....", bool?.id)
    try {
      setLoading(true);

      if (!user) {
        // For guest users, remove from localStorage
        let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        storedWishlist = storedWishlist.filter((wishlistItem) => wishlistItem.id !== item?.id);
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

        setIsInWishlist(false); // Set state to false after removing from wishlist
        toast.success("Removed from wishlist (guest user)");

        setLoading(false);
        getUserWishlistItems();
        return;
      }
      let wishlistItemId = item?.id;
      // For logged-in users, use API
      await get(`/api/wishlist/remove/${bool?.id}`);
      console.log("Hello ID", item?.id)
      setIsInWishlist(false); // Set state to false after removing from wishlist
      toast.success("Removed from wishlist");
      setLoading(false);
      getUserWishlistItems(); // Refresh wishlist from the server
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error || "Something went wrong");
    }
  };

  // Decide whether to add or remove the item from wishlist
  function callFunctionAccordingly(event) {
    event.stopPropagation();
    if (isInWishlist) {
      removeFromWishlist();
    } else {
      addToWishlist();
    }
  }

  return isInWishlist ? (
    <PositionAbsolute onClick={callFunctionAccordingly}>
      <FavoriteIcon style={{ color: "rgb(218, 72, 72)" }} /> {/* Red when added to wishlist */}
    </PositionAbsolute>
  ) : (
    <PositionAbsolute onClick={callFunctionAccordingly}>
      <FavoriteBorderOutlinedIcon style={{ color: "black" }} /> {/* Black when not in wishlist */}
    </PositionAbsolute>
  );
}

export default ProuductShowCase;

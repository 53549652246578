import React from "react";

const TrashBinIcon = ({ sx, onClick }) => (
    <svg
    // width="28"
    // height="28"
    viewBox="0 0 32 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={sx}
    onClick={onClick}
  > 
    <path
      d="M25.333 10.3438C24.9794 10.3438 24.6402 10.4886 24.3902 10.7465C24.1402 11.0043 23.9997 11.3541 23.9997 11.7188V27.1064C23.9614 27.8017 23.6583 28.4533 23.1563 28.9192C22.6544 29.385 21.9942 29.6275 21.3197 29.5938H10.6797C10.0051 29.6275 9.34499 29.385 8.84302 28.9192C8.34105 28.4533 8.03792 27.8017 7.99967 27.1064V11.7188C7.99967 11.3541 7.8592 11.0043 7.60915 10.7465C7.3591 10.4886 7.01996 10.3438 6.66634 10.3438C6.31272 10.3438 5.97358 10.4886 5.72353 10.7465C5.47348 11.0043 5.33301 11.3541 5.33301 11.7188V27.1064C5.37107 28.5312 5.95512 29.8824 6.95725 30.864C7.95938 31.8457 9.2979 32.3778 10.6797 32.3438H21.3197C22.7014 32.3778 24.04 31.8457 25.0421 30.864C26.0442 29.8824 26.6283 28.5312 26.6663 27.1064V11.7188C26.6663 11.3541 26.5259 11.0043 26.2758 10.7465C26.0258 10.4886 25.6866 10.3438 25.333 10.3438Z"
      fill="black"
    />
    <path
      d="M26.6667 6.21875H21.3333V3.46875C21.3333 3.10408 21.1929 2.75434 20.9428 2.49648C20.6928 2.23862 20.3536 2.09375 20 2.09375H12C11.6464 2.09375 11.3072 2.23862 11.0572 2.49648C10.8071 2.75434 10.6667 3.10408 10.6667 3.46875V6.21875H5.33333C4.97971 6.21875 4.64057 6.36362 4.39052 6.62148C4.14048 6.87934 4 7.22908 4 7.59375C4 7.95842 4.14048 8.30816 4.39052 8.56602C4.64057 8.82388 4.97971 8.96875 5.33333 8.96875H26.6667C27.0203 8.96875 27.3594 8.82388 27.6095 8.56602C27.8595 8.30816 28 7.95842 28 7.59375C28 7.22908 27.8595 6.87934 27.6095 6.62148C27.3594 6.36362 27.0203 6.21875 26.6667 6.21875ZM13.3333 6.21875V4.84375H18.6667V6.21875H13.3333Z"
      fill="black"
    />
    <path
      d="M14.6667 24.0938V14.4688C14.6667 14.1041 14.5262 13.7543 14.2761 13.4965C14.0261 13.2386 13.687 13.0938 13.3333 13.0938C12.9797 13.0938 12.6406 13.2386 12.3905 13.4965C12.1405 13.7543 12 14.1041 12 14.4688V24.0938C12 24.4584 12.1405 24.8082 12.3905 25.066C12.6406 25.3239 12.9797 25.4688 13.3333 25.4688C13.687 25.4688 14.0261 25.3239 14.2761 25.066C14.5262 24.8082 14.6667 24.4584 14.6667 24.0938Z"
      fill="black"
    />
    <path
      d="M19.9997 24.0938V14.4688C19.9997 14.1041 19.8592 13.7543 19.6091 13.4965C19.3591 13.2386 19.02 13.0938 18.6663 13.0938C18.3127 13.0938 17.9736 13.2386 17.7235 13.4965C17.4735 13.7543 17.333 14.1041 17.333 14.4688V24.0938C17.333 24.4584 17.4735 24.8082 17.7235 25.066C17.9736 25.3239 18.3127 25.4688 18.6663 25.4688C19.02 25.4688 19.3591 25.3239 19.6091 25.066C19.8592 24.8082 19.9997 24.4584 19.9997 24.0938Z"
      fill="black"
    />
  </svg>
);

export default TrashBinIcon;

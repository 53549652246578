
import LandingPage from "./pages/LandingPage";

function App() {
  
  return (
    <LandingPage />
  );
}

export default App;

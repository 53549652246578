import React from "react";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { selectedCategoryFromLandingPage } from "../../store/reducer/reducer";
import { useNavigate } from "react-router-dom";


const CategoryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 22px;
  margin-top: 22px;
  box-sizing: border-box;
  width: 182px;

    @media (max-width: 600px) {
  width: 120px;
  }
`;
const Categoryimage = styled.img`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  @media (max-width: 600px) {
    width: 115px;
  height: 115px;
  }
`;
const CategoryTitle = styled.div`
  text-align: center;
  /* line-height: 16px; */
  font-weight: 500;
  font-size: 20px;
  font-family: "Inter";
  margin-top: 15px;
  min-height: 50px;
  @media (max-width: 600px) {
    font-size: 15px;
  }
`;


function SingleCategory({ img, name, item }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <CategoryWrapper onClick={()=>{
      dispatch(selectedCategoryFromLandingPage(item))
      navigate("/categories")
    }}>
      <Categoryimage
        src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${img}`}
      />
      <CategoryTitle>{name || " "}</CategoryTitle>
    </CategoryWrapper>
  );
}
export default SingleCategory;
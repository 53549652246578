import React, { useEffect, useState } from "react";
import { get, post } from "../../api_helper/api_helper";
import Carousel from "react-material-ui-carousel";
import CategoryViewer from "../../components/LandingPage/CategoryViewer";
import PopularProductSlider from "../../components/LandingPage/PopularProductSlider";
import FashionSlider from "../../components/LandingPage/FashionSlider";

import HomeFurnishing from "../../components/LandingPage/HomeFurnishing";
import AdViewer from "../../components/LandingPage/AdViewer";
import ExploreMarketPlace from "../../components/LandingPage/ExploreMarketPlace";
import { useDispatch, useSelector } from "react-redux";
import { handleDynamicPageContent } from "../../store/reducer/reducer";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import axios from 'axios';
import CommonSlider from "../../components/LandingPage/CommonSlider";
import CommonProduct from "../../components/LandingPage/CommonProduct";
function LandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sliderImages, setSliderImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ads, setAds] = useState([]);
  const [wishlistItems, setWishlistItems] = useState([]);

  const [popularProducts, setPopularProducts] = useState([]);
  const [Sections, setSections] = useState([]);
  // const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  // const [wishlistItems, setWishlistItem] = useState([]);
  // const [products, setProducts] = useState([]);
  // const [firstThree, setFirstThree] = useState([]);
  // const [nextFive, setNextFive] = useState([]);
  const user = useSelector((state) => state.general.user);
  const subCategory = location.state?.selectedSubCategory;

  const [transitionStarted, setTransitionStarted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTransitionStarted(true);
    }, 500); // Delay transition by 500ms or adjust as needed
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    window.setTimeout(() => {
      window.document
        .querySelector(
          ".MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium"
        )
        ?.click();
    }, 1000);
  }, []);



  async function getLandingPageDetails() {
    try {
      const data = await get("/api/ecom-dashboard/web");
      setSliderImages(data?.sliderImages || []);
      console.log("Track SLider Images", data)
      setAds(data?.sliderAds || []);
      setPopularProducts(data?.propularProducts || []);
      console.log()
    } catch (e) {
      console.log(e);
    }
  }



  // useEffect(() => {
  //   const fetchSections = async () => {
  //     try {
  //       const response = await axios.get('/api/marketplace_sections-get');
  //       setSections(response.data);
  //     } catch (error) {
  //       setError(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchSections();
  // }, []);

  // console.log(sections)

  async function getMarketplaceSections() {
    try {
      const data = await get("/api/marketplace_sections-get");
      const sortedSections = (data || []).sort((a, b) => a.sectionIndex - b.sectionIndex);
      setSections(sortedSections);
    } catch (e) {
      console.log(e);
    }
  }

  // console.log("Sections Details:-", Sections)


  async function getUserWishlistItems() {
    if (user) {
      // Fetch wishlist items from API for logged-in users
      try {
        const items = await post("/api/wishlist", {});
        console.log("Fetched wishlist items (logged-in user):", items?.wishlist?.wishlist_items);
        setWishlistItems(items?.wishlist?.wishlist_items || []);
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    } else {
      // Fetch wishlist items from local storage for guest users
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      console.log("Fetched wishlist items (guest user):", storedWishlist);
      setWishlistItems(storedWishlist);
    }
  }


  // useEffect(() => {
  //   console.log("PopularProductSlider: wishlistItems updated:", wishlistItems);
  // }, [wishlistItems]);


  useEffect(() => {
    // Call this on initial render to load wishlist items
    getUserWishlistItems();
  }, []); // Run only once on mount

  useEffect(() => {
    console.log("ProductSlider: wishlistItems updated:", wishlistItems);
  }, [wishlistItems]);
  // console.log("ProductSlider: wishlistItems updated: OUT", wishlistItems);




  async function getAllCategories() {
    try {
      const categories = await post("/api/categories");
      setCategories(categories.categories);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getLandingPageDetails();
    getAllCategories();
    getMarketplaceSections();
    // getUserWishlistItems();
  }, []);

  function offerBannerClickHandler(item) {
    dispatch(handleDynamicPageContent(item));
    console.log("ITEM Vaaiiiii", item)
    navigate("/offers-section");
  }


  // async function getUserWishlistItems() {
  //   if (user) {
  //     try {
  //       const items = await post(`/api/wishlist`, {});
  //       setWishlistItem(items?.wishlist?.wishlist_items || []);
  //     } catch (error) {
  //       console.error("Error fetching wishlist items:", error);
  //     }
  //   }
  // }

  // console.log(popularProducts)
  // console.log("Slider",sliderImages)

  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <>
      <div>
        {sliderImages?.length > 0 ? (
          // <Carousel
          //   autoPlay={true}
          //   indicators={true}
          //   navButtonsAlwaysVisible={true}
          //   navButtonsProps={{ style: { color: "white", background: "#19889F" } }}
          // >
          //   {sliderImages.map((item, i) => (
          //     <div key={item.id} style={{ width: "80%", display: "flex", justifyContent: "center", margin:"auto" }}>
          //       <img
          //         src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image_url}`}
          //         style={{ cursor: "pointer", width: "100%", maxHeight: "500px",  }} // Ensure images are responsive and cover the width, with max height
          //         alt={`Slide ${i + 1}`}
          //         onClick={() => offerBannerClickHandler(item)}
          //       />
          //     </div>

          //   ))}
          // </Carousel>
          <Carousel
            autoPlay={true}
            indicators={true}
            navButtonsAlwaysVisible={true} // Keep it true if you want to manage visibility manually
            NavButton={({ style, onClick }) => null} // Override navigation buttons to render nothing
            animation="fade" // Use fade instead of slide
          >
            {sliderImages.map((item, i) => (
              <div
                key={item.id}
                style={{
                  width: isMobile ? "95%" : "80%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px auto",
                  paddingTop: "50px",
                  maxWidth: "1525px",
                  maxHeight: "500px",




                }}
              >
                <img
                  src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image_url}`}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    maxHeight: "500px",
                    borderRadius: "30px",
                    height: "clamp(50px, 52vw, 500px)",  // Apply clamp for height
                    objectFit:"cover",
                  }} // Ensure images are responsive and cover the width, with max height
                  alt={`Slide ${i + 1}`}
                  onClick={() => offerBannerClickHandler(item)}
                />
              </div>
            ))}
          </Carousel>
        ) : null}

        <CategoryViewer categories={categories} />
        <PopularProductSlider products={popularProducts} getUserWishlistItems={getUserWishlistItems} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} />
        <AdViewer ads={ads} offerBannerClickHandler={offerBannerClickHandler} />

        <CommonProduct section={Sections[0]} getUserWishlistItems={getUserWishlistItems} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} ></CommonProduct>
        {/* {!user ? <ExploreMarketPlace /> : null} */}
        <div style={{ height: "35px" }}></div>
        {/* <FashionSlider products={popularProducts} /> */}
        {console.log("Above")}
        {/* <CommonSlider section={Sections[0]} getUserWishlistItems={getUserWishlistItems} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} /> Replace with the section data if needed */}
        {console.log("Below")}
        {/* {sliderImages?.length > 0 ? (
      <Carousel
      autoPlay={true}
      indicators={true}
      navButtonsAlwaysVisible={true} // Keep it true if you want to manage visibility manually
      NavButton={({ style, onClick }) => null} // Override navigation buttons to render nothing
  >
      {sliderImages?.map((item, i) => (
          <div key={item.id} style={{ width: "80%", display: "flex", justifyContent: "center", margin: "auto" , paddingTop:"50px"}}>
              <img
                  src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image_url}`}
                  style={{ cursor: "pointer", width: "100%", maxHeight: "500px", borderRadius:"30px"}} // Ensure images are responsive and cover the width, with max height
                  alt={`Slide ${i + 1}`}
                  onClick={() => offerBannerClickHandler(item)}
              />
          </div>
      ))}
  </Carousel>
) : null} */}

        <div
          style={{
            width: isMobile ? "95%" : "80%",
            display: "flex",
            justifyContent: "center",
            margin: "0px auto",
            paddingTop: "0px",
            maxWidth: "1525px",
          }}
        >
          <img
            src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${sliderImages[0]?.image_url}`} // Display the first image
            style={{
              cursor: "pointer",
              width: "100%",
              maxHeight: "500px",
              borderRadius: "30px",
            }} // Ensure the image is responsive
            alt="Static Image" // Updated alt text
            onClick={() => offerBannerClickHandler(sliderImages[0])} // Handle click on the image
          />
        </div>




        {/* <HomeFurnishing products={popularProducts} /> */}
        {Sections.slice(1).map((section) => (
          // <CommonSlider key={section.sectionIndex} section={section} getUserWishlistItems={getUserWishlistItems} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} />
          <CommonProduct section={section} getUserWishlistItems={getUserWishlistItems} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} ></CommonProduct>
        ))}
        {/* <CommonSlider/> */}
      </div>
    </>
  );
}

export default LandingPage;

import React from "react";
import styled from "@emotion/styled";
import AirplanemodeActiveTwoToneIcon from "@mui/icons-material/AirplanemodeActiveTwoTone";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import { Grid } from "@mui/material";
import { formatDollars } from "../../helper/generalFunctions";
import { Box, Card, CardContent, Typography, Divider } from '@mui/material';

const Heading = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const Cardd = styled.div`
  background: white;
  width: 134px;
  height: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px auto;
  box-sizing: border-box;
  border-radius: 4px;
`;
const CardSubText = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin: 20px;
`;
const CardPrice = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: center;
  margin: 5px;
`;
const CardBtn = styled.div`
  width: 109px;
  height: 32px;
  border-radius: 10px;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 24.2px;
  margin: 5px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SellingPrice = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  color: #00B4D8;
  line-height: 38.73px;

     @media (max-width: 600px) {
     font-size: 20px;
  }
`;

const MRP = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  margin-left: 10px;
  text-decoration: line-through;
  color: #999999;

  
     @media (max-width: 600px) {
     font-size: 17px;
  }
`;

const Discount = styled.div`
  color: #da4848;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin-left: 10px;

      @media (max-width: 600px) {
     font-size: 14px;
  }
`;
const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
`;

function TypeOfShipment({ shippingDetails, setTypeOfShipment, typeOfShipment, discountedPrice,qty, finalProduct }) {

  const price=shippingDetails?.amountShip ? formatDollars(shippingDetails?.amountShip) : "--"
  // Define the parseCurrency function here
  const parseCurrency = (value) => {
    return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0; // Convert to number and return 0 if NaN
  };

  const calculateDiscountPercentage = () => {
    if (finalProduct?.discountedPrice && finalProduct?.MRP) {
      const discountedPrice = parseFloat(finalProduct.discountedPrice);
      const mrp = parseFloat(finalProduct.MRP);
      const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
      return Math.round(discountPercentage);
    }
    return 0;
  };
const preMRP=finalProduct.MRP;
// console.log("MRP",preMRP)
  // Ensure these are numbers
  const discountedPriceValue = Number(discountedPrice) || 0; // Ensure discountedPrice is a number
  const quantity=Number(qty) || 0
  const priceValue = parseCurrency(price) || 0; // Use parseCurrency to get the numeric value
  // const MRP= Number(preMRP) || 0;

  const totalPrice = discountedPriceValue  + priceValue;
  // console.log("Total Price:", totalPrice); 

  

  return (
    <>
      <Heading>Type of Shipment</Heading>
      <Grid container spacing={2}>
        <Grid item alignItems={"center"}>
          <DisplayShipmentCard
            iconName="Airplane"
            priceValue={priceValue}
            selected={typeOfShipment}
            icon="AIR"
            name={"AIR"}
            duration={shippingDetails?.durationAir}
            setSelected={setTypeOfShipment}
            discountedPrice={discountedPrice}
            quantity={quantity}
          />
        </Grid>
        <Grid item alignItems={"center"} justifyContent={"center"}>
          <DisplayShipmentCard
            iconName="Ship"
            priceValue={priceValue}
            selected={typeOfShipment}
            icon="SHIP"
            name={"SHIP"}
            duration={shippingDetails?.durationShip}
            setSelected={setTypeOfShipment}
            discountedPrice={discountedPrice}
            quantity={quantity}
          />
        </Grid>
      </Grid>

            {/* <h1>{`${discountedPriceValue * quantity}$ | ${priceValue *quantity}$ | ${totalPrice * quantity}`}$</h1> */}

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Box
          sx={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)', // Box shadow instead of border
            borderRadius: '8px',
            padding: '20px 16px',
            display: 'inline-block',
            width: '344px',
            '@media (max-width: 430px)': {
              padding: '20px 0px', // Adjust padding when screen width is 430px or less
            },

            '@media (max-width: 393px)': {
              width: 'fit-content',
            },
     
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs style={{padding:"0px 0px"}}>
              <Typography variant="body2" align="center" color="textSecondary" sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#424545',
                '@media (max-width: 393px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
           Produce   Price  
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: '#838484',
                '@media (max-width: 393px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
              {formatDollars(discountedPriceValue * quantity)}
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item xs style={{padding:"0px 0px"}}>
              <Typography variant="body2" align="center" color="textSecondary"  sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#424545',
                '@media (max-width: 393px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
              {typeOfShipment === "AIR" ? "Airplane Price" : "Ship Price"}
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: '#838484',
                '@media (max-width: 393px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
              {formatDollars(priceValue *quantity)}
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item xs style={{padding:"0px 0px"}}>
              <Typography variant="body2" align="center" color="textSecondary"  sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#424545',
                '@media (max-width: 393px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
                Total Price
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: '#838484',
                '@media (max-width: 393px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
              {formatDollars(totalPrice * quantity)}
              </Typography>
            </Grid>
           

          </Grid>
        </Box>
      </div>

      <PriceContainer>
        {finalProduct?.discountedPrice ? (
          <>
            <SellingPrice>
              {formatDollars(totalPrice*quantity)}
            </SellingPrice>
            <MRP>${preMRP*quantity}</MRP>
            <Discount>-{calculateDiscountPercentage()}% OFF</Discount>
          </>
        ) : (
          <>
            <SellingPrice>{formatDollars(finalProduct?.MRP)}</SellingPrice>
          </>
        )}
      </PriceContainer>


      
    </>
  );
}

function DisplayShipmentCard({
  iconName,
  icon,
  name,
  duration,
  selected,
  setSelected,
  priceValue,
  quantity,
  discountedPrice,
}) {
  const Icon = icon === "AIR" ? AirplanemodeActiveTwoToneIcon : DirectionsBoatFilledOutlinedIcon;
  const cardColor = selected === name ? `#03BEE7` : `#838484`;
  const textColor = selected === name ? `#242626` : `#838484`;
  const btnTextColor = selected === name ? `white` : `#838484`;
  const btnBackgroundColor = selected === name ? `#03BEE7` : `#E3E3E3`;



  return (
    <>
      <Cardd
        style={{
          border: selected === name ? `1.5px solid #03BEE7` : "1.5px solid #E3E3E3",
        }}
        onClick={() => {
          setSelected(name);
          // console.log("H",name)
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Icon style={{ color: cardColor, fontSize: "25px", fontWeight: 500 }} />
          <div style={{ color: cardColor, marginLeft: "5px", fontSize: "16px", fontWeight: 500 }}>
            {iconName}
          </div>
        </div>
        <CardPrice style={{ color: cardColor }}>{formatDollars(priceValue*quantity)  || "--"}</CardPrice>
        <CardBtn style={{ color: btnTextColor, background: btnBackgroundColor }}>
          {duration || "--"} Days
        </CardBtn>
      </Cardd>

      {/* <h1>{`${discountedPriceValue} | ${priceValue} | ${totalPrice}`}</h1> */}
    </>
  );
}

export default TypeOfShipment;

const Box = ({ color }) => {
   return (
     <svg width="70" height="48" viewBox="0 0 70 48" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path
         d="M29.9232 1.04816L1.43469 15.2924C0.819638 15.6122 0.699219 16.0408 0.699219 16.5686V47.0091C0.700473 47.541 1.01972 48.0238 1.51022 48.2296L29.9988 60.4304C30.3299 60.5714 30.7012 60.5714 31.0324 60.4304L59.5368 48.2296C60.0214 48.0192 60.3332 47.5374 60.3319 47.0091V16.5805C60.3759 16.0448 60.0916 15.5358 59.6124 15.2924L31.1079 1.04816C30.6728 0.815905 30.2781 0.881186 29.9232 1.04816ZM30.5235 3.70778L55.8833 16.3817L50.2977 18.775L38.9397 13.0979C38.5466 12.8906 38.1221 12.9567 37.8265 13.0662L31.251 15.8848C30.2132 16.3274 30.1699 17.7824 31.1795 18.286C32.4785 18.9355 37.4972 21.4433 40.5339 22.9612L30.5235 27.2508L5.15975 16.3817L30.5235 3.70778ZM38.3036 15.7417L47.1094 20.1426L43.6467 21.6254L34.9165 17.1927L38.3036 15.7417ZM3.34294 18.4768L29.1997 29.5566V57.2182L3.34294 46.1384V18.4768ZM57.6882 18.4927V46.1385L31.8434 57.2182C31.8514 47.9986 31.8474 38.7711 31.8474 29.5486L49.5503 21.9753V32.6495L47.3956 31.5722C46.8859 31.3185 46.271 31.4194 45.869 31.8226L44.0562 33.6354V28.6859C44.0566 27.9546 43.4637 27.3616 42.7323 27.3621C42.001 27.3616 41.4081 27.9546 41.4085 28.6859V36.8278C41.4104 38.0051 42.8334 38.5939 43.6666 37.762L47.0656 34.3669L50.2818 35.977C51.1624 36.4185 52.1994 35.7774 52.198 34.7923V20.8422L57.6882 18.4927ZM6.94873 38.2669C6.61231 38.9221 6.87403 39.7229 7.53312 40.0519L11.6041 42.0834C12.2556 42.4096 13.0483 42.1457 13.3771 41.495C13.7033 40.8435 13.4434 40.0508 12.7927 39.722L8.72181 37.6785C7.84735 37.1999 7.20689 37.8198 6.94873 38.2669ZM8.15731 41.5905C7.62917 41.5551 7.21538 41.8759 6.94875 42.3378C6.61234 42.9931 6.87405 43.7978 7.53315 44.1268L11.6041 46.1543C12.2556 46.4805 13.0483 46.2206 13.3772 45.5699C13.7033 44.9184 13.4434 44.1257 12.7928 43.7969L8.72181 41.7534C8.52128 41.6542 8.33336 41.6022 8.15731 41.5905Z"
         fill={color}
    
       />
     </svg>
   );
 };
 
 export default Box;
 

 
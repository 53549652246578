import React from 'react'
import styled from '@emotion/styled'
import TermsItem from '../../components/TermsAndConditions/TermsItem';
const Heading = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 43.57px;
  margin: 30px auto;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;
function TermsAndCondition() {
  return (
    <>
    <Heading>Term and Condition</Heading>
    {information.map((item,index)=>{
        return (
            <TermsItem heading={item.title} description={item.detail} count={index+1} />
        )
    })}
    <div style={{marginBottom:"40px"}}>&nbsp;&nbsp;</div>
    </>
  )
}

export default TermsAndCondition


const information = [
    {
      "title": "Acceptance of Terms",
      "detail": "By accessing or using this website [YourWebsite.com] (the \"Website\") or any services provided by [YourCompany] (\"we,\" \"us,\" or \"our\"), you agree to comply with and be bound by these Terms and Conditions (the \"Terms\"). If you do not agree to these Terms, please do not use the Website or any of our services."
    },
    {
      "title": "Changes to Terms",
      "detail": "We reserve the right to modify, amend, or update these Terms at any time, and such changes will be effective immediately upon posting. It is your responsibility to review these Terms periodically, and your continued use of the Website or our services after any changes indicates your acceptance of those changes."
    },
    {
      "title": "Privacy Policy",
      "detail": "Your use of the Website and our services is also governed by our Privacy Policy, which can be found at [Link to Privacy Policy]. Please review the Privacy Policy to understand how we collect, use, and protect your personal information."
    },
    {
      "title": "User Accounts",
      "detail": "4.1. You may be required to create an account to access certain features of the Website or use our services. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. \n\n4.2. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete."
    },
    {
      "title": "Use of the Website",
      "detail": "5.1. You agree not to use the Website or our services for any illegal or unauthorized purpose. You must comply with all applicable laws and regulations when using the Website. \n\n5.2. You are solely responsible for any content you post on the Website and any consequences that may result from your posts."
    },
    {
      "title": "Intellectual Property",
      "detail": "6.1. All content on the Website, including text, graphics, logos, images, and software, is our property or the property of our licensors and is protected by copyright, trademark, and other intellectual property laws. \n\n6.2. You may not use, reproduce, distribute, or modify any of the content on the Website without our prior written consent."
    },
    {
      "title": "Disclaimer of Warranties",
      "detail": "7.1. The Website and our services are provided \"as is\" and \"as available\" without any warranties of any kind, either express or implied. \n\n7.2. We do not warrant that the Website or our services will be error-free, uninterrupted, or free from viruses or other harmful components."
    },
    {
      "title": "Limitation of Liability",
      "detail": "8.1. To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Website or our services."
    },
    {
      "title": "Governing Law",
      "detail": "These Terms are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of [Your Jurisdiction]."
    },
    {
      "title": "Contact Information",
      "detail": "If you have any questions or concerns about these Terms, please contact us at [Your Contact Information]."
    }
  ]
  
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import WishlistItem from "../../components/Wishlist/WishlistItem";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../api_helper/api_helper";
import EmptyWishlist from "../../assets/images/empty_wishlist.svg";
import DeleteWishListItem from "../../components/Wishlist/DeleteItem";
import { toast } from "react-toastify";

const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
  margin-bottom:64px;
  max-width:1525px;
  @media (max-width: 600px) {
    width: 97%;
  }
`;
const ParentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const HeadingBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  @media (max-width: 600px) {
    width: 97%;
  }
`;
const Heading = styled.div`
  font-family: "Inter";
  font-size: 36px;
  color : "#3E4D50";
  font-weight: 600;
  line-height: 43.57px;
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;

const ItemsCount = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  @media (max-width: 600px) {
    font-size: 17px;
  }
`;

const WishlistWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  min-height: 500px;
  gap:10px;
  
  
`;

// const WishlistWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   margin-top: 30px;
//   min-height: 500px;
//      gap: 23px;
//   min-height: 500px;
//   max-height: 500px;
  // overflow-y: auto;
  // overflow-x: hidden;
//   padding: 39px 0px;
// `;

function Wishlist() {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const user = useSelector((state) => state.general.user);
  const [wishlistItems, setWishlistItem] = useState([]);
  const navigate = useNavigate("/");

  async function removeFromWishlist(bool) {

    if (user){
    try {
      await get(`/api/wishlist/remove/${selectedItem?.id}`);
      toast.success("Removed from wishlist");

      getUserWishlistItems();
    } catch (e) {
      toast.error(e?.response?.data?.error || "something went wrong");
    }
  }

  else{

    try {
      // For guest users, manage wishlist using localStorage
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  
      // Find the index of the item to remove from the wishlist
      const itemIndex = storedWishlist.findIndex(item => item.id === selectedItem?.id);
  
      if (itemIndex > -1) {
        // Remove the item from the wishlist
        storedWishlist.splice(itemIndex, 1);
  
        // Update the wishlist in localStorage
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
        setWishlistItem(storedWishlist)
        toast.success("Removed from wishlist");
  

      } else {
        toast.error("Item not found in wishlist");
      }
    } catch (e) {
      toast.error(e?.response?.data?.error || "Something went wrong");
      console.log(e);
    }

  }
  }

  // async function removeFromWishlist(bool) {
    // try {
    //   // For guest users, manage wishlist using localStorage
    //   const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  
    //   // Find the index of the item to remove from the wishlist
    //   const itemIndex = storedWishlist.findIndex(item => item.id === selectedItem?.id);
  
    //   if (itemIndex > -1) {
    //     // Remove the item from the wishlist
    //     storedWishlist.splice(itemIndex, 1);
  
    //     // Update the wishlist in localStorage
    //     localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
    //     setWishlistItem(storedWishlist)
    //     toast.success("Removed from wishlist");
  

    //   } else {
    //     toast.error("Item not found in wishlist");
    //   }
    // } catch (e) {
    //   toast.error(e?.response?.data?.error || "Something went wrong");
    //   console.log(e);
    // }
  // }
  
  async function getUserWishlistItems() {
    if (user) {
      try {
        const items = await post("/api/wishlist", {});
        console.log("Fetched wishlist from API:", items); 
        setWishlistItem(items?.wishlist?.wishlist_items || []);
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    }
  }
  
  console.log("Items Wish",wishlistItems)


  useEffect(() => {
    if (!user) {
      // For guest users, load the wishlist from localStorage
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      console.log("Loaded wishlist from localStorage:", storedWishlist); // Log to check what we get from localStorage
      setWishlistItem(storedWishlist);
    } else {
      // For logged-in users, fetch wishlist from server
      getUserWishlistItems();
    }
  }, [user]);
  
  // useEffect(() => {
  //   if (!user) {
  //     // For guest users, load the wishlist from localStorage
  //     const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //     console.log("Loaded wishlist from localStorage:", storedWishlist);
  //     setWishlistItem(storedWishlist);
  //   } else {
  //     // For logged-in users, treat it like guest users (use localStorage as well)
  //     const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //     console.log("Loaded wishlist for logged-in user (from localStorage):", storedWishlist);
  //     setWishlistItem(storedWishlist);
  //   }
  // }, [user]);


  return (
    <WidthAdjuster>
      <DeleteWishListItem
        removeFromWishlist={removeFromWishlist}
        setShowModal={setShowModal}
        showModal={showModal}
      />
      <ParentWrapper>
        <HeadingBox>
          <Heading>My Wishlist</Heading>
          <ItemsCount>{`(${wishlistItems.length || 0} items)`}</ItemsCount>
        </HeadingBox>
      </ParentWrapper>

      <WishlistWrapper>
        {wishlistItems.length ? (
          wishlistItems.map((item) => {
            return (
              <WishlistItem
                setShowModal={setShowModal}
                setSelectedItem={setSelectedItem}
                // getUserWishlistItems={getUserWishlistItems}
                key={item?.id}
                item={item}
              />
            );
          })
        ) : (
          <img alt="wishlist" src={EmptyWishlist} />
        )}
      </WishlistWrapper>
    </WidthAdjuster>
  );
}

export default Wishlist;

import React from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { showSuccessModal } from "../../store/reducer/reducer";
import styled from "@emotion/styled";
import SuccessImage from "../../assets/images/success.svg";
import "react-phone-input-2/lib/style.css";
import CloseModal from "./CloseModal";
const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
  //  width: clamp(450px, 30%, 636px);
  width: clamp(450px, 30%, 448px);
   height:382px;
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

const ShowImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TexttoDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Heading = styled.div`
  font-weight: 600;
  font-size: 36px;
  font-family: "Inter";
  margin: 10px auto;
`;
const SubHeading = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: "Inter";
  margin: 10px auto;
`;

function UpdatedPasswordSuccessfully() {
  const displayModal = useSelector((state) => state.general.showSuccessModal);
  const dispatch = useDispatch();
  const getHeading = () => "Successful!";
  const getSubHeading = () => {
    if (displayModal === "password") {
      return "Your password has been successfully updated.";
    }
    if (displayModal === "mobile") {
      return "Your mobile number has been successfully updated.";
    }

    if (displayModal === "email") {
      return "Your email has been successfully updated.";
    }
    return "";
  };
  return (
    <>
       <Modal
      open={Boolean(displayModal)}
      onClose={() => {
        dispatch(showSuccessModal(null));
      }}
    >
      <LoginFormWrapper>
        <CloseModal closeFn={() => dispatch(showSuccessModal(null))} />
        <ShowImage>
          <img alt="success" src={SuccessImage} style={{ width: "171px", height: "140px", margin: "20px auto" }} />
        </ShowImage>
        <TexttoDisplay>
          <Heading>{getHeading()}</Heading>
          <SubHeading>{getSubHeading()}</SubHeading>
        </TexttoDisplay>
      </LoginFormWrapper>
    </Modal>
    </>
  );
}

export default UpdatedPasswordSuccessfully;

const Cancelled = ({ color }) => {
    return (
        <svg width="70" height="64" viewBox="0 0 70 64" fill="none">
            <path d="M59.4866 14.8343C59.4183 14.2044 59.027 13.6472 58.4423 13.3742L31.0068 0.570929C30.5164 0.342185 29.9501 0.3423 29.4598 0.570929L2.02433 13.3742C1.43973 13.647 1.04854 14.2041 0.980067 14.8337C0.978581 14.8469 0.96875 15.022 0.96875 15.0316V46.1252C0.96875 46.8731 1.42406 47.5457 2.11853 47.8234L29.554 58.7976C29.7721 58.8848 30.0027 58.9284 30.2333 58.9284C30.4638 58.9284 30.6944 58.8849 30.9124 58.7977L38.3465 55.8247C40.5897 57.758 43.5079 58.9284 46.6946 58.9284C53.7543 58.9284 59.4978 53.1849 59.4978 46.1252V15.0316C59.4978 15.0225 59.4878 14.8469 59.4866 14.8343ZM30.2333 4.24672L53.0668 14.9024L44.2745 18.4193L21.0112 8.55032L30.2333 4.24672ZM28.4042 54.3978L4.62681 44.8868V17.7331L28.4042 27.2441V54.3978ZM30.2333 24.0358L7.39963 14.9024L16.5508 10.6319L39.4533 20.3478L30.2333 24.0358ZM46.6946 55.2704C41.6519 55.2704 37.5494 51.1679 37.5494 46.1252C37.5494 41.0826 41.6519 36.98 46.6946 36.98C51.7372 36.98 55.8397 41.0826 55.8397 46.1252C55.8397 51.1679 51.7372 55.2704 46.6946 55.2704ZM46.6946 33.322C39.6348 33.322 33.8913 39.0655 33.8913 46.1252C33.8913 48.6077 34.6023 50.9268 35.8301 52.8913L32.0623 54.398V27.2441L55.8397 17.7331V37.1746C53.5141 34.7989 50.2739 33.322 46.6946 33.322Z" fill={color} />
            <path d="M59.4866 14.8343C59.4183 14.2044 59.027 13.6472 58.4423 13.3742L31.0068 0.570929C30.5164 0.342185 29.9501 0.3423 29.4598 0.570929L2.02433 13.3742C1.43973 13.647 1.04854 14.2041 0.980067 14.8337C0.978581 14.8469 0.96875 15.022 0.96875 15.0316V46.1252C0.96875 46.8731 1.42406 47.5457 2.11853 47.8234L29.554 58.7976C29.7721 58.8848 30.0027 58.9284 30.2333 58.9284C30.4638 58.9284 30.6944 58.8849 30.9124 58.7977L38.3465 55.8247C40.5897 57.758 43.5079 58.9284 46.6946 58.9284C53.7543 58.9284 59.4978 53.1849 59.4978 46.1252V15.0316C59.4978 15.0225 59.4878 14.8469 59.4866 14.8343ZM30.2333 4.24672L53.0668 14.9024L44.2745 18.4193L21.0112 8.55032L30.2333 4.24672ZM28.4042 54.3978L4.62681 44.8868V17.7331L28.4042 27.2441V54.3978ZM30.2333 24.0358L7.39963 14.9024L16.5508 10.6319L39.4533 20.3478L30.2333 24.0358ZM46.6946 55.2704C41.6519 55.2704 37.5494 51.1679 37.5494 46.1252C37.5494 41.0826 41.6519 36.98 46.6946 36.98C51.7372 36.98 55.8397 41.0826 55.8397 46.1252C55.8397 51.1679 51.7372 55.2704 46.6946 55.2704ZM46.6946 33.322C39.6348 33.322 33.8913 39.0655 33.8913 46.1252C33.8913 48.6077 34.6023 50.9268 35.8301 52.8913L32.0623 54.398V27.2441L55.8397 17.7331V37.1746C53.5141 34.7989 50.2739 33.322 46.6946 33.322Z" fill={color} />
            <path d="M51.1404 42.1997C51.5405 42.5998 51.5405 43.2448 51.1404 43.6449L48.3969 46.3844L51.1404 49.1279C51.5405 49.528 51.5405 50.1731 51.1404 50.5732C50.7403 50.9733 50.0953 50.9733 49.6951 50.5732L46.9557 47.8297L44.2121 50.5732C43.812 50.9733 43.167 50.9733 42.7669 50.5732C42.3668 50.1731 42.3668 49.528 42.7669 49.1279L45.5104 46.3844L42.7669 43.6408C42.3668 43.2407 42.3668 42.5957 42.7669 42.1956C43.167 41.7955 43.812 41.7955 44.2121 42.1956L46.9557 44.9391L49.6992 42.1956C50.0953 41.7996 50.7444 41.7996 51.1404 42.1997Z" fill={color} />


        </svg>

    );
};

export default Cancelled;



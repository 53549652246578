import React from "react";
import styled from "@emotion/styled";
import InfoBox from "../../components/AboutCompany/InfoBox";
import ImgWithText from "../../components/AboutCompany/ImgWithText";
import Image1 from "../../assets/images/img1.svg";
import Image2 from "../../assets/images/img2.svg";
import Image3 from "../../assets/images/img3.svg";

const AboutWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #f1fdff; /* Your background color */
  position: relative; /* Required for absolute positioning of patches */
  overflow: hidden;
`;

const Patch = styled.div`
  position: absolute;
  width: 10vw; /* Adjust size of the patch */
  height: 10vw; /* Adjust size of the patch */
  background-color: #8decffb2; /* Patch color */
  border-radius: 50%; /* Make it round */
  transform: rotate(45deg);
  transform: scale(3);
  filter: blur(50px);
`;

const StyledPatch1 = styled(Patch)`
  top: 5vw;
  left: 5vw;
`;

const StyledPatch2 = styled(Patch)`
  top: 50%;
  left: 50%;
`;

const StyledPatch3 = styled(Patch)`
  bottom: 5vw;
  left: 5vw;
`;
const Heading = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 700;
  line-height: 38.73px;
  text-align: center;
  color: #25626f;
  margin: 50px auto;
`;

const SimpleContent = styled.div`
  color: #25626f;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
`;

const Paratitle = styled.span`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24.2px;
  color: #25626f;
`;
const ParaDetail = styled.span`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24.2px;
  color: #25626f;
`;
const HeadingWithParaWrapper = styled.div`
  width: 80%;
  text-align: justify;
  margin: 6px 0;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
function AboutCompany() {
  return (
    <AboutWrapper>
      <Heading>About Company</Heading>
      <InfoBox />
      <ImgWithText
        img={Image1}
        title={`Our Story`}
        content={
          <>
            <SimpleContent>
              Founded in [Year of Establishment], [Your Company] has been at the
              forefront of [Your Industry] innovation for [Number of Years]
              years. Our journey began with a simple vision: to [Briefly
              Describe Your Initial Vision or Mission]. Over the years, we have
              grown and evolved, but our commitment to [Core Company Values] has
              remained unwavering.
            </SimpleContent>
          </>
        }
      />

      <ImgWithText
        isRight={true}
        img={Image2}
        title={`Our Mission`}
        content={
          <SimpleContent>
            Founded in [Year of Establishment], [Your Company] has been at the
            forefront of [Your Industry] innovation for [Number of Years] years.
            Our journey began with a simple vision: to [Briefly Describe Your
            Initial Vision or Mission]. Over the years, we have grown and
            evolved, but our commitment to [Core Company Values] has remained
            unwavering.
          </SimpleContent>
        }
      />
      <ImgWithText
        img={Image3}
        title={`Why Choose Us?`}
        content={
          <>
            <HeadingWithPara
              detail={` Our team of [Number of Employees] professionals boasts a wealth of experience in the [Your Industry] industry. We are dedicated to staying up-to-date with the latest trends and technologies to provide you with the best solutions.`}
              heading={`Expertise:`}
            />
            <HeadingWithPara
              detail={` We are committed to delivering the highest quality [Your Products or Services] to our customers. Our rigorous quality control processes ensure that every product/service meets our strict standards.`}
              heading={`Quality:`}
            />
            <HeadingWithPara
              detail={` We are committed to delivering the highest quality [Your Products or Services] to our customers. Our rigorous quality control processes ensure that every product/service meets our strict standards.`}
              heading={`Customer-centric:`}
            />
            <HeadingWithPara
              detail={` We are committed to delivering the highest quality [Your Products or Services] to our customers. Our rigorous quality control processes ensure that every product/service meets our strict standards.`}
              heading={`Innovation:`}
            />
          </>
        }
      />
      <StyledPatch1 />
      <StyledPatch2 />
      <StyledPatch3 />
    </AboutWrapper>
  );
}
function HeadingWithPara({ heading, detail }) {
  return (
    <HeadingWithParaWrapper>
      {" "}
      <Paratitle>{heading}</Paratitle> <ParaDetail>{detail}</ParaDetail>
    </HeadingWithParaWrapper>
  );
}
export default AboutCompany;

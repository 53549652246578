import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { get, put } from "../../api_helper/api_helper";
import { Grid, InputAdornment, LinearProgress, TextField, Typography } from "@mui/material";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import Group43 from "../../assets/images/Group43.png"
import { useNavigate } from "react-router-dom";
import UpdateUserMobile from "../../components/UserProfile/UpdateUserMobile";
import OtpScreen from "../../components/UserProfile/OtpScreen";
import UpdateUserEmail from "../../components/UserProfile/UpdateUserEmail";
import EmailOtpScreen from "../../components/UserProfile/EmailOtpScreen";
import UpdatePasswordProfileModal from "../../components/UserProfile/UpdatePassword";

const AboutWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #defaff; /* Your background color */
  position: relative; /* Required for absolute positioning of patches */
  overflow: hidden;

`;

const Heading = styled.div`
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;

  color: #000000;
  text-align: center;
  margin-top: 50px;
`;

const FormWrapper = styled.div`
    width: clamp(200px, 40%, 670px);
  border-radius: 16px;
  box-shadow: 0px 4px 30px 0px #42424229;
  background: white;
  border: 1px solid #c7c7c7;
  margin: auto;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* left: 50%; */
  z-index: 1;
  padding-top: 45px;
  padding-bottom: 25px;

  @media (max-width: 800px) {
    width: 96%;
  }
`;
const WidthAdjuster = styled.div`
  width: 90%;
  margin: auto;
`;

const CancelBtn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  color: #999999;
  border: 1px solid #c7c7c7;
  background: white;
  height: 57px;
  cursor: pointer;
  border-radius: 8px;
`;
const SubmitBtn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  color: white;
  border: 1px solid #00b4d8;
  background: #00b4d8;
  height: 57px;
  cursor: pointer;
  border-radius: 8px;
`;

const BtnWrappers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  width: 100%;
  gap: 30px;
`;

const labelStyles = {
  display: 'block',
  marginBottom: '5px',
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 500,
  color: "#5E6363",
};

function UserProfile() {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [email, setEmail] = useState("")
  const [showUpdatePassword1, setShowUpdatePassword1] = useState(false)
  const [showUpdatePassword2, setShowUpdatePassword2] = useState(false)
  const [couuntryCode, setCountryCode] = useState("");
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [showEmailOtpModal, setShowEmailOtp] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showUpdateUserMobileModal, setShowUpdateUserMobile] = useState(false);
  const [showOTPModal, setShowOtpModal] = useState(false)
  const length = 4;
  const [OTP, setOTP] = useState(Array(length).fill(""));
  const inputRefs = useRef(Array(length).fill(null));
  const navigate = useNavigate();
  useEffect(() => {
    getUserProfile();
  }, []);
  async function getUserProfile() {
    try {
      setLoading(true);
      const { userProfile } = await get("/api/auth/profile-info");
      setUserInfo(userProfile || {});
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
    }
  }
  async function updateUserName() {
    try {
      setLoading(true);
      await put("/api/auth/profile-info-name", {
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
      });
      setLoading(false);
      toast.success("Details Updated!");
      getUserProfile();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
    }
  }
  return loading ? (
    <AboutWrapper>
      <div style={{ width: "100%", height: "100%" }}>
        <LinearProgress color="success" />
      </div>
    </AboutWrapper>
  ) : (
    <>
      <UpdateUserMobile
        setShowUpdateUserMobile={setShowUpdateUserMobile}
        showUpdateUserMobileModal={showUpdateUserMobileModal}
        setPhoneNumber={setPhoneNumber}
        setShowOtpModal={setShowOtpModal}
        couuntryCode={couuntryCode}
        setCountryCode={setCountryCode}
        phoneNumber={phoneNumber}
      />
      <OtpScreen
        setCountryCode={setCountryCode}
        setPhoneNumber={setPhoneNumber}
        setShowOtpModal={setShowOtpModal}
        showOTPModal={showOTPModal}
        inputRefs={inputRefs}
        OTP={OTP}
        setOTP={setOTP}
        setShowUpdateUserMobile={setShowUpdateUserMobile}
        couuntryCode={couuntryCode}
        phoneNumber={phoneNumber}
        getUserProfile={getUserProfile}
      />
      <UpdateUserEmail
        setShowUpdateUserMobile={setShowUpdateUserMobile}
        showUpdateUserMobileModal={showEmailModal}
        setPhoneNumber={setEmail}
        setShowOtpModal={setShowEmailModal}
        phoneNumber={email}
        setShowEmailModal={setShowEmailModal}
        setShowEmailOtp={setShowEmailOtp}
      />
      <EmailOtpScreen
        setPhoneNumber={setEmail}
        setShowOtpModal={setShowEmailOtp}
        showOTPModal={showEmailOtpModal}
        inputRefs={inputRefs}
        OTP={OTP}
        setOTP={setOTP}
        getUserProfile={getUserProfile}
        phoneNumber={email}
      />
      <UpdatePasswordProfileModal
        setShowUpdatePassword1={setShowUpdatePassword1}
        showUpdatePassword1={showUpdatePassword1}
      />
      <AboutWrapper>
      <Heading>Personal Details</Heading>
        <FormWrapper>

          <WidthAdjuster>
            
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>


                <Typography component="label" htmlFor="first_name" sx={labelStyles}>
                  First Name
                </Typography>
                <TextField
                  fullWidth
                  id="first_name"
                  // label="First Name"
                  name="first_name"
                  variant="outlined"
                  value={userInfo?.first_name}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      first_name: e.target.value,
                    }))
                  }

                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      borderColor: "rgba(227, 227, 227, 1)",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>

                <Typography component="label" htmlFor="last_name" sx={labelStyles}>
                  Last Name
                </Typography>
                <TextField
                  fullWidth
                  id="last_name"
                  // label="Last Name"
                  name="last_name"
                  variant="outlined"
                  value={userInfo?.last_name}
                  onChange={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      last_name: e.target.value,
                    }))
                  }

                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      borderColor: "rgba(227, 227, 227, 1)",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ height: "20px" }}></div>

            <Grid container spacing={2}>
              <Grid item xs={12}>

                <Typography component="label" htmlFor="last_name" sx={labelStyles}>
                  Mobile Number
                </Typography>
                <TextField
                  fullWidth
                  id="mob_no"
                  // label="Mobile Number"
                  name="mob_no"
                  variant="outlined"
                  contentEditable={false}
                  aria-readonly={true}
                  disabled={true}
                  value={`${userInfo?.country_code}-${userInfo?.mobilenumber}`}
                  InputProps={{

                    style: {
                      borderRadius: "8px",
                      borderColor: "rgba(227, 227, 227, 1)",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={Group43}
                          onClick={() => setShowUpdateUserMobile(true)}
                          style={{ color: "#00B4D8" }}
                        />
                      </InputAdornment>
                    ),
                  }}

                 
                />
              </Grid>
            </Grid>
            <div style={{ height: "20px" }}></div>

            <Grid container spacing={2}>
              <Grid item xs={12}>

                <Typography component="label" htmlFor="email" sx={labelStyles}>
                  Email
                </Typography>
                <TextField
                  fullWidth
                  id="email"
                  // label="Email"
                  name="email"
                  variant="outlined"
                  contentEditable={false}
                  aria-readonly={true}
                  disabled={true}
                  value={`${userInfo?.email}`}
                  InputProps={{

                    style: {
                      borderRadius: "8px",
                      borderColor: "rgba(227, 227, 227, 1)",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={Group43}
                          onClick={() => setShowEmailModal(true)}
                          style={{ color: "#00B4D8" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ height: "20px" }}></div>
            <Grid container spacing={2}>
              <Grid item xs={12}>

                <Typography component="label" htmlFor="password" sx={labelStyles}>
                  Change Password
                </Typography>
                <TextField
                  fullWidth
                  id="password"
                  // label="Change Password"
                  name="password"
                  variant="outlined"
                  contentEditable={false}
                  aria-readonly={true}
                  disabled={true}
                  value={`${userInfo?.password}`}
                  InputProps={{

                    style: {
                      borderRadius: "8px",
                      borderColor: "rgba(227, 227, 227, 1)",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={Group43}
                          onClick={() => setShowUpdatePassword1(true)}
                          style={{ color: "#00B4D8" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <BtnWrappers>
              <CancelBtn
                onClick={() => {
                  navigate("/");
                  window.location.reload();
                }}
              >
                Cancel
              </CancelBtn>
              <SubmitBtn onClick={updateUserName}>Save Changes</SubmitBtn>
            </BtnWrappers>
          </WidthAdjuster>
        </FormWrapper>
      </AboutWrapper>
    </>
  );
}

export default UserProfile;

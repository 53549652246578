import React from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import useMediaQuery from "@mui/material/useMediaQuery";

const IconWrapper = styled.div`
  padding: 5px;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  

  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 52px;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px; /* Consider reducing this if needed */
  color: #022c35;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  background: #ffc727;
  padding: 10px 20px; /* Adjust padding to suit your design */
  transition: background 0.3s ease; /* Smooth transition for hover effect */

  @media (max-width: 927px) {
    padding: 5px 10px; /* Reduce padding on smaller screens */
    font-size: 14px; /* Optional: Reduce font size on smaller screens */
  }

  @media (max-width: 600px) {
    background: transparent;
  }

  &:hover {
    background: white;
    color:black;
  }
`;


function MarketPlaceButton() {
  const matches = useMediaQuery("(max-width:927px)");

  return matches ? (
    <IconWrapper
      onClick={() => {
        window.location = "https://shipment.shipora.io";
      }}
    >
      <StorefrontIcon style={{ color: "#242626" }} />
    </IconWrapper>
  ) : (
    <StyledButton
      onClick={() => {
        window.location = "https://shipment.shipora.io";
      }}
    >
      Marketplace
      <IconWrapper>
        <StorefrontIcon style={{ color: "#242626" }} />
      </IconWrapper>
    </StyledButton>
  );
}

export default MarketPlaceButton;

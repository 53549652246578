import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import styled from "@emotion/styled";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "slick-carousel/slick/slick.css";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import SingleProduct from "./SingleProduct";
import { useMediaQuery } from "@mui/material";
import { get, post } from "../../api_helper/api_helper";
const WidthAdjuster = styled.div`

  margin: 10px auto;
  margin-top: 50px;
  max-width:1525px;
  
   @media (max-width: 600px) {
   width: 97%;
  }
 
`;
const HeadingWithLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;
const Heading = styled.div`
  font-family: "Inter";
  font-weight: 500;
  font-size: 24px;
  line-height: 29.05px;
  @media (max-width: 600px) {
    font-size: 19px;
  line-height: 24.05px;
  }
`;
const ButtonWrapper = styled.div`
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  
`;
const CarouselButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
 
 width: 6%;

 
  @media (max-width: 600px) {
    width: 20% !important;

  }

    @media (max-width: 1800px) {
    width: 6%;

  }

   @media (max-width: 1271px) {
    width: 11%;

  }

  
   @media (max-width: 990px) {
    width: 15%;

  }

     @media (max-width: 400px) {
    width: 22% !important;

  }

    @media (max-width: 370px) {
    width: 25% !important;

  }
`;
const SliderWrapper = styled.div`
  margin-top: 40px;
  margin:auto;
`;
const ProductWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;
function ProductDetailsProductSlider({ products, getUserWishlistItems, setWishlistItems, wishlistItems }) {
  const matches = useMediaQuery('(max-width:600px)');

 

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: products.length > 1,
    speed: 500,

    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [



      {
        breakpoint: 1479,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },

      {
        breakpoint: 1036,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },


    ]
  };
  return (
    <WidthAdjuster>
      <HeadingWithLink>
        <Heading></Heading>
        <CarouselButtonWrapper>
          <ButtonWrapper
            onClick={() => {
              previous();
            }}
          >
            <ArrowBackIosIcon style={matches ? { fontSize: "12px", color: "#595959", paddingLeft: '10px' } : { fontSize: "16px", color: "#595959", paddingLeft: '6px' }} />
          </ButtonWrapper>
          <ButtonWrapper onClick={() => {next()}}>
            <ArrowForwardIosIcon
              style={matches ? { fontSize: "12px", color: "#595959" } : { fontSize: "16px", color: "#595959" }}
            />
          </ButtonWrapper>
        </CarouselButtonWrapper>
      </HeadingWithLink>
      <SliderWrapper>
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {products.map(item => {
            return <SingleProduct key={item.id} product={item}  displayAddToCartBtn={true} getUserWishlistItems={getUserWishlistItems} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems}/>
          })}

        </Slider>
      </SliderWrapper>
    </WidthAdjuster>
  );
}

export default ProductDetailsProductSlider;

import React from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import OrderIcon from "../../assets/images/box.svg";
import CartIcon from "../../assets/images/cartIcon.svg";
import HeartIcon from "../../assets/images/heart.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { addUserInfo, showLoginModal } from "../../store/reducer/reducer";
import { useSelector } from "react-redux";
const ToolIcon = styled.img`
  width: 23px;
  height: 24px;
  margin-right: 4px;
  @media (max-width: 600px) {
    margin-right: 2px;
    width: 15px;
    height: 16px;
  }
`;

function UserTools() {
  const matches = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const user = useSelector((state) => state.general.user);
  const CommonStyles = {
    color: "white",
    marginRight: matches ? "5px" : "16px",
    fontFamily: "Inter",
    fontWeight: 500,
    textTransform: "capitalize",
    fontSize: matches ? "12px" : "16px",
    padding: 0,
  };
  return (
    <div>
      <Button style={CommonStyles}  onClick={() => {
        if(user){
            navigate("/orders");
          }
          else{

          }
          }}>
        <ToolIcon
          src={OrderIcon}
         
        />
        Orders
      </Button>
      <Button style={CommonStyles} onClick={()=>{
        navigate("/wishlist")
      }}>
        <ToolIcon
          src={HeartIcon}
          style={
            matches
              ? { width: "15px", height: "14px" }
              : { width: "22px", height: "20px" }
          }
        />
        Wishlist
      </Button>
      <Button
        style={CommonStyles}
        onClick={() => {
          navigate("/cart");
        }}
      >
        <ToolIcon
          src={CartIcon}
          style={
            matches
              ? { width: "20px", height: "20px" }
              : { width: "30px", height: "30px" }
          }
        />
        Cart
      </Button>
    </div>
  );
}

export default UserTools;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import SelectedTabBox from "./SelectedTabBox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CountryCodes from "../../assets/json/countrycode.json";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import { get, post } from "../../api_helper/api_helper";
import { Autocomplete, Checkbox, Grid, TextField, Typography, InputAdornment, Divider } from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectedDeliveryAddress } from "../../store/reducer/reducer";
const AddressFormWrapper = styled.div`
  // width: 60%;
  // width: 45%;
      width: clamp(578px, 48%, 806px);
  margin: auto;
box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  background: white;
  padding-bottom: 30px;
  border-radius:16px;

  @media (max-width: 800px) {
    width: 97%;
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 29.05px;
  padding: 40px;
`;

const Heading2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 36px;
  font-weight: 600;
  line-height: 29.05px;
  padding: 40px;
  color:rgba(58, 58, 58, 1);
`;

const WidthAdjuster = styled.div`
  width: 90%;
  margin: auto;
`;

const ContinueButton = styled.button`
  width: 100%;
  height: 52px;
  background: #00b4d8;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  border-radius: 8px;
  border: none;
  outline: none;
  margin: 20px auto;
  cursor: pointer;
`;

const labelStyles = {
  display: 'block',
  marginBottom: '5px',
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 500,
  color: "#5E6363",
};



function AddressForm({ currentTab, setCurrentTab }) {
  const [userAddresses, setUserAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.general.user);
  const [addressSelected, setAddressSelected] = useState(false); // State to track if an address is selected
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const { deliveryAddress } = await post(`/api/create-delivery-address`, {
        ...values,
        ...(values.selectedAddress && { id: values.selectedAddress }),
        ...(user && { user_id: user.id }),
      });
      dispatch(selectedDeliveryAddress({ ...deliveryAddress }));
      setLoading(false);
      setCurrentTab(2);
      formik.resetForm();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  };

  // console.log("User ID",user.id)

  const validationSchema = Yup.object().shape({
    selectedAddress: Yup.string(), // Selected address is now optional
    first_name: Yup.string()
      .matches(/^[A-Za-z\s]*$/, "First name cannot contain digits")
      .required("First name is required"),
    last_name: Yup.string()
      .matches(/^[A-Za-z\s]*$/, "Last name cannot contain digits")
      .required("Last name is required"),
    address: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    zipcode: Yup.string()
      .matches(
        /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
        "Invalid Canadian postal code format"
      )
      .required("Zip code is required"),
    mobile_number_country_code: Yup.string().required(
      "Country code is required"
    ),
    mobile_number: Yup.string().required("Phone number is required"),
    saveThis: Yup.boolean(),
    agreeTerms: Yup.boolean().oneOf([true], "You must agree to the terms"),
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      selectedAddress: "",
      first_name: "",
      last_name: "",
      address: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      mobile_number_country_code: "",
      mobile_number: "",
      saveThis: false,
      agreeTerms: false,
      state: "",
      country: "",
    },
    onSubmit,
  });
  async function getUserDeliveryAddress() {
    try {
      const addressInfo = await get(`/api/get-delivery-address`);
      setUserAddresses(addressInfo?.userDeliveryAddresses || []);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    getUserDeliveryAddress();
  }, []);
  return (
    <AddressFormWrapper>
      <Heading2> Address</Heading2>
      <SelectedTabBox currentTab={currentTab} />

      <Heading>Delivery Address</Heading>
      <form onSubmit={formik.handleSubmit}>
        <WidthAdjuster>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              onChange={(_, value) => {
                const { id, ...rest } = value || {}; // Handle undefined value
                formik.setFieldValue("selectedAddress", value?.id);
                formik.setValues({
                  ...formik.values,
                  ...rest,
                });
                setAddressSelected(!!value); // Set state to true if an address is selected
              }}
              id="combo-box-demo"
              options={userAddresses.map((item) => ({
                ...item,
                label: `${item?.address || ""} ${item?.landmark || ""} ${item?.state || ""}`,
                value: item?.id,
              }))}
              renderInput={(params) => (

                <>


                  <Typography component="label" htmlFor="first_name" sx={labelStyles}>
                    Select Saved Address
                  </Typography>
                  <TextField
                    {...params}
                    // label="Select Saved Address"
                    helperText={formik.errors.selectedAddress}
                    error={Boolean(formik.errors.selectedAddress)}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        borderRadius: "8px",
                        borderColor: "rgba(227, 227, 227, 1)",
                      },
                    }}
                  />

                </>
              )}
            />
          </FormControl>

          <div style={{ height: "20px" }}></div>
          <Grid container spacing={2}>
            <Grid item xs={6}>

              {/* <p>First NAme </p> */}

              <Typography component="label" htmlFor="first_name" sx={labelStyles}>
                First Name
              </Typography>
              <TextField
                fullWidth
                id="first_name"
                // label="First Name"
                name="first_name"
                variant="outlined"
                value={formik.values.first_name}
                onChange={(e) => {
                  // Allow only letters and spaces in first name
                  const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Regex to remove numbers and special characters
                  formik.setFieldValue('first_name', value);
                }}
                error={Boolean(formik.errors.first_name)}
                helperText={formik.errors.first_name}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "rgba(227, 227, 227, 1)",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>

              <Typography component="label" htmlFor="last_name" sx={labelStyles}>
                Last Name
              </Typography>
              <TextField
                fullWidth
                id="last_name"
                // label="Last Name"
                name="last_name"
                variant="outlined"
                value={formik.values.last_name}
                onChange={(e) => {
                  // Allow only letters and spaces in first name
                  const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Regex to remove numbers and special characters
                  formik.setFieldValue('last_name', value);
                }}
                error={Boolean(formik.errors.last_name)}
                helperText={formik.errors.last_name}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "rgba(227, 227, 227, 1)",
                  },
                }}
              />
            </Grid>
          </Grid>
          <div style={{ height: "20px" }}></div>

          <Grid container spacing={2}>
            <Grid item xs={12}>

              <Typography component="label" htmlFor="last_nameaddress" sx={labelStyles}>
                Address
              </Typography>
              <TextField
                fullWidth
                id="address"
                // label="Address"
                name="address"
                variant="outlined"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.address)}
                helperText={formik.errors.address}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "rgba(227, 227, 227, 1)",
                  },
                }}
              />
            </Grid>
          </Grid>
          <div style={{ height: "20px" }}></div>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  value={formik.values.country}
                  onChange={(_, value) =>
                    formik.setFieldValue("country", value?.value)
                  }
                  id="combo-box-country"
                  options={[{ label: "Canada", value: "Canada" }]}
                  renderInput={(params) => (

                    <>


                      <Typography component="label" htmlFor="last_nameaddress" sx={labelStyles}>
                        Country
                      </Typography>
                      <TextField
                        {...params}
                        // label="Country"
                        helperText={formik.errors.country}
                        error={Boolean(formik.errors.country)}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            borderRadius: "8px",
                            borderColor: "rgba(227, 227, 227, 1)",
                          },
                        }}
                      />
                    </>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  value={formik.values.state}
                  onChange={(_, value) =>
                    formik.setFieldValue("state", value?.value)
                  }
                  id="combo-box-state"
                  options={[
                    { label: "Alberta", value: "Alberta" },
                    { label: "British Columbia", value: "British Columbia" },
                    { label: "Manitoba", value: "Manitoba" },
                    { label: "New Brunswick", value: "New Brunswick" },
                    { label: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
                    { label: "Nova Scotia", value: "Nova Scotia" },
                    { label: "Ontario", value: "Ontario" },
                    { label: "Prince Edward Island", value: "Prince Edward Island" },
                    { label: "Quebec", value: "Quebec" },
                    { label: "Saskatchewan", value: "Saskatchewan" },
                    { label: "Northwest Territories", value: "Northwest Territories" },
                    { label: "Nunavut", value: "Nunavut" },
                    { label: "Yukon", value: "Yukon" },
                  ]}
                  renderInput={(params) => (

                    <>

                      <Typography component="label" htmlFor="last_nameaddress" sx={labelStyles}>
                        State
                      </Typography>
                      <TextField
                        {...params}
                        // label="State"
                        helperText={formik.errors.state}
                        error={Boolean(formik.errors.state)}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            borderRadius: "8px",
                            borderColor: "rgba(227, 227, 227, 1)",
                          },
                        }}
                      />

                    </>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div style={{ height: "20px" }}></div>

          <Grid container spacing={2}>
            <Grid item xs={6}>

              <Typography component="label" htmlFor="city" sx={labelStyles}>
                City
              </Typography>
              <TextField
                fullWidth
                id="city"
                // label="City"
                name="city"
                variant="outlined"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.city)}
                helperText={formik.errors.city}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "rgba(227, 227, 227, 1)",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>

              <Typography component="label" htmlFor="zipcode" sx={labelStyles}>
                Zip Code
              </Typography>

              <TextField
                fullWidth
                id="zipcode"
                // label="Zip Code"
                name="zipcode"
                variant="outlined"
                value={formik.values.zipcode}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.zipcode)}
                helperText={formik.errors.zipcode}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "rgba(227, 227, 227, 1)",
                  },
                }}
              />
            </Grid>
          </Grid>
          <div style={{ height: "20px" }}></div>



          {/* <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  value={formik.values.mobile_number_country_code}
                  onChange={(_, value) =>
                    formik.setFieldValue("mobile_number_country_code", value?.dial_code)
                  }
                  id="combo-box-country-code"
                  options={CountryCodes.map((item) => ({
                    ...item,
                    label: `${item.flag} (${item?.dial_code}) - ${item.name}`,
                    value: item?.dial_code,
                  }))}
                  renderInput={(params) => (
                    <>
                      <Typography component="label" htmlFor="zipcode" sx={labelStyles}>
                        Country Code
                      </Typography>



                      <TextField
                        {...params}
                        // label="Country Code"
                        helperText={formik.errors.mobile_number_country_code}
                        error={Boolean(formik.errors.mobile_number_country_code)}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            borderRadius: "8px",
                            borderColor: "rgba(227, 227, 227, 1)",
                          },
                        }}
                      />
                    </>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>

              <Typography component="label" htmlFor="phone-number" sx={labelStyles}>
                Phone Number
              </Typography>
              <TextField
                fullWidth
                id="phone-number"
                // label="Phone Number"
                name="mobile_number"
                variant="outlined"
                value={formik.values.mobile_number}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.mobile_number)}
                helperText={formik.errors.mobile_number}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "rgba(227, 227, 227, 1)",
                  },
                }}
              />
            </Grid>
          </Grid> */}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography component="label" htmlFor="phone-number" sx={labelStyles}>
                  Phone Number
                </Typography>
                <TextField
                  fullWidth
                  id="phone-number"
                  name="mobile_number"
                  variant="outlined"
                  value={formik.values.mobile_number}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.mobile_number) || Boolean(formik.errors.mobile_number_country_code)}
                  helperText={formik.errors.mobile_number || formik.errors.mobile_number_country_code}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* Country Code Dropdown */}
                        <Autocomplete
                          disablePortal
                          value={CountryCodes.find(
                            (code) => code.dial_code === formik.values.mobile_number_country_code
                          )}
                          onChange={(_, value) =>
                            formik.setFieldValue("mobile_number_country_code", value?.dial_code)
                          }
                          options={CountryCodes}
                          getOptionLabel={(option) => `${option.flag} ${option.dial_code}`}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder=""
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                style: { width: 80, fontSize: '0.875rem' }
                              }}
                            />
                          )}
                        />
                        {/* Divider */}
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ mx: 1, height: 28, alignSelf: "center", backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                        />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "8px",
                      borderColor: "rgba(227, 227, 227, 1)",
                    },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>



          <div style={{ height: "20px" }}></div>

          {!addressSelected && (
            <Grid container>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#00b4d8" }}
                      checked={formik.values.saveThis}
                      onChange={formik.handleChange}
                      name="saveThis"
                    />
                  }
                  label="Save this address (Optional)"
                />
              </Grid>
            </Grid>
          )}

          <Grid container>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#00b4d8" }}
                    checked={formik.values.agreeTerms}
                    onChange={formik.handleChange}
                    name="agreeTerms"
                  />
                }
                label="I agree to terms and conditions"
              />
            </Grid>
          </Grid>

          <ContinueButton type="submit" disabled={!formik.isValid}>
            Continue
          </ContinueButton>
        </WidthAdjuster>
      </form>

    </AddressFormWrapper>
  );
}

export default AddressForm;



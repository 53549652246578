import React, { useEffect, useState, useRef } from "react";

import { post } from "../../api_helper/api_helper";
import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import IndividualCategory from "../../components/Category/IndividualCategory";
import DisplaySubCategory from "../../components/Category/SubCategory";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "slick-carousel/slick/slick.css";
import { get } from "../../api_helper/api_helper";
import subcategory1 from '../../assets/images/subcategory_1.jpg';
import subcategory2 from '../../assets/images/subcategory_2.jpg';
import subcategory3 from '../../assets/images/subcategory_3.jpg';
import subcategory5 from '../../assets/images/subcategory_5.png';
import subcategory6 from '../../assets/images/subcategory_6.png';
import { useNavigate } from 'react-router-dom';

import "slick-carousel/slick/slick-theme.css";
import SingleProduct from "../../components/LandingPage/SingleProduct";
import { useMediaQuery } from "@mui/material";
import "./category.css";
const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
  max-width:1525px;
  @media (max-width: 600px) {
    width: 97%;
  }
`;



const ViewCenter = styled.div`
  display: flex;
  margin: 20px auto;
  justify-content: center;
  align-items: center;
`;
const HeadingWithLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const Heading = styled.div`
  font-family: "Inter";
  font-weight: 500;
  font-size: 24px;
  line-height: 29.05px;
  @media (max-width: 600px) {
    font-size: 19px;
  line-height: 24.05px;
  }
`;
const ButtonWrapper = styled.div`
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  
`;

const CarouselButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
 
 width: 6%;

 
  @media (max-width: 600px) {
    width: 20% !important;

  }

    @media (max-width: 1800px) {
    width: 6%;

  }

   @media (max-width: 1271px) {
    width: 11%;

  }

  
   @media (max-width: 990px) {
    width: 15%;

  }

     @media (max-width: 400px) {
    width: 22% !important;

  }
`;



const SliderWrapper = styled.div`
  margin-top: 40px;
  margin:auto;
`;
function Categories() {
  const [categories, setCategories] = useState([]);
  const [indexTill, setIndexTill] = useState(4);
  const selectedCategoryFromLandingPage = useSelector(state => state.general.selectedCategoryFromLandingPage)
  const [viewAllClicked, setViewAllClicked] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(selectedCategoryFromLandingPage)
  const [products, setproducts] = useState([]);
  const navigate = useNavigate(); // Initialize the navigate function

  // const [ads, setAds] = useState([]);
  // const [sliderImages, setSliderImages] = useState([]);

  async function getAllCategories() {
    try {
      const categories = await post("/api/categories");
      setCategories(categories.categories);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getAllCategories();
  }, []);


  async function getLandingPageDetails() {
    try {
      const data = await get("/api/ecom-dashboard/web");
      // setSliderImages(data?.sliderImages || []);
      // setAds(data?.sliderAds || []);
      setproducts(data?.propularProducts || []);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getLandingPageDetails();

  }, []);

  const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 16px;
  margin: 50px auto;

  @media (max-width: 768px) {
    flex-direction: column; // Change to column on smaller screens
  }
`;

const RightColumn = styled.div`
  flex: 0 0 50%; // Takes up 50% of the width

  @media (max-width: 768px) {
    flex: 0 0 100%; // Takes full width on smaller screens
  }

  div {
    width: 100%;
      aspect-ratio: 16 / 13.1;
    border-radius: 20px;
    overflow: hidden; // Hide overflow to maintain shape

    img {
      width: 100%; // Ensure image takes full width
      height: auto; // Maintain aspect ratio
      border-radius: 20px; // Match border radius
    }
  }
`;

const LeftColumn = styled.div`
  flex: 0 0 50%; // Takes up 50% of the width
  display: flex;
  flex-direction: column;
  

  @media (max-width: 768px) {
    flex: 0 0 100%; // Takes full width on smaller screens
  }

  div {
    width: 100%;
aspect-ratio: 16 / 6.5;
    border-radius: 20px;
    overflow: hidden; // Hide overflow to maintain shape

    img {
      width: 100%; // Ensure image takes full width
      height: auto; // Maintain aspect ratio
      border-radius: 20px; // Match border radius
      object-fit: cover; // Cover the container while maintaining aspect ratio
    }
  }
`;


const Column = styled.div`
flex: 0 0 50%; // Takes up 50% of the width

@media (max-width: 768px) {
  flex: 0 0 100%; // Takes full width on smaller screens
}

div {
  width: 100%;
aspect-ratio: 16 / 6.5;
  border-radius: 20px;
  overflow: hidden; // Hide overflow to maintain shape
}

img {
  width: 100%; // Ensure image takes full width
  height: auto; // Maintain aspect ratio
  border-radius: 20px; // Match border radius
       object-fit: cover; // Cover the container while maintaining aspect ratio
}
`;



  const matches = useMediaQuery('(max-width:600px)');

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {

    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [

      // {
      //   breakpoint: 1522,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //   }
      // },




      {
        breakpoint: 1479,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },

      {
        breakpoint: 1036,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },



      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },


    ]
  };

 

  return  (
    <WidthAdjuster>
      {/* New Flexbox Container for Placeholders Above the Heading */}
      <FlexContainer>
      {/* Right column with one placeholder */}
      <RightColumn>
        <div>
          <img src={subcategory1} alt="Right column image description" />
        </div>
      </RightColumn>

      {/* Left column with two placeholders */}
      <LeftColumn>
        <div>
          <img src={subcategory2} alt="Left column first image description" />
        </div>
        <div>
          <img src={subcategory3} alt="Left column second image description" />
        </div>
      </LeftColumn>
    </FlexContainer>

      {/* Heading for All Categories */}

      <div style={{ marginTop: "0px", margin: "auto" }}>
        <Heading style={{ textAlign: "center" }}>All Categories</Heading>
        {/* <img src="subcategory_1.jpg.jpg"/> */}

        <Grid container spacing={2} alignItems="center" margin={"10px 0"} width={"100%"}>
          {categories.slice(0, indexTill).map((item) => {
            return (
              <Grid item xs={6} md={3} key={item.id} style={{paddingLeft:"0"}}  onClick={() => {
                setSelectedCategory(item); // Set the selected category
                navigate("/sub-categories", { state: { selectedCategory: item } }); // Navigate to the sub-categories route
            }}>
                <IndividualCategory name={item?.name} img={item?.image_url} products={products} />
              </Grid>
            );
          })}
        </Grid>
      </div>


      {!viewAllClicked && categories.length >= 5 && (
  <ViewCenter>
    <Button
      onClick={() => {
        setViewAllClicked(true);
        setIndexTill(categories.length);
      }}
      style={{
        borderRadius: "8px",
        width: "138px",
        height: "49px",
        color: "white",
        background: "#00B4D8",
      }}
    >
      View All
    </Button>
  </ViewCenter>
)}


      <FlexContainer>
        {/* Right Column for One Placeholder */}
        <Column>
        <div>
          <img src={subcategory5} alt="Description of image 1" />
        </div>
      </Column>

      {/* Left Column for One Placeholder */}
      <Column>
        <div>
          <img src={subcategory6} alt="Description of image 2" />
        </div>
      </Column>
      </FlexContainer>


      <div style={{ margin: "50px auto" }}>

        <HeadingWithLink>
          <Heading>Recently Viewed Products</Heading>
          <CarouselButtonWrapper>
            <ButtonWrapper
              onClick={() => {
                previous();
              }}
            >
              <ArrowBackIosIcon style={matches ? { fontSize: "12px", color: "#595959", paddingLeft: '10px' } : { fontSize: "16px", color: "#595959", paddingLeft: '6px' }} />
            </ButtonWrapper>
            <ButtonWrapper
              onClick={() => {
                next();
              }}
            >
              <ArrowForwardIosIcon
                style={matches ? { fontSize: "12px", color: "#595959" } : { fontSize: "16px", color: "#595959" }}
              />
            </ButtonWrapper>
          </CarouselButtonWrapper>
        </HeadingWithLink>
        <SliderWrapper>
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            {products.map(item => {
              return <SingleProduct key={item.id} product={item} />
            })}
          </Slider>
        </SliderWrapper>
      </div>


    </WidthAdjuster>




  );
}

export default Categories;

import React from "react"
import styled from "@emotion/styled"
import DetailsAccordian from "../Accordian";
const AccordianBoxWrapper = styled.div`
width: 50%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 20px;
@media (max-width: 800px) {
    width: 90%;
  }

    @media (max-width: 1100px) {
    width: 90%;
    margin: 10px auto;

  }
`;
function AccordianBox({product}){
    // const finalProduct = product?.contain_variants
    // ? { ...product, ...product?.product_variants[0] }
    // : product;

    const finalProduct = product?.contain_variants && product?.product_variants?.length > 0
    ? { ...product, ...product.product_variants[0] }
    : product;

    function MeasurementString(){
        return (
            `
            <ul>
            <li>Weight: ${finalProduct.weight} kg</li>
            <li>Length: ${finalProduct.length} cm</li>
            <li>Height: ${finalProduct.height} cm</li>
            <li>Width: ${finalProduct.width} cm</li>
            </ul>
            `
        )
    }
    return <AccordianBoxWrapper>
    <DetailsAccordian name={`Key features`} info={product?.key_features} />
    <DetailsAccordian name={`Product measurements`} info={MeasurementString()} />
    <DetailsAccordian name={`Materials`} info={product?.materials} />
    <DetailsAccordian name={`Assembly instructions`} info={product?.assembly_instructions} />
    <DetailsAccordian name={`Additional information`} info={product?.additional_information} />
    </AccordianBoxWrapper>
}
export default AccordianBox
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { get } from "../../api_helper/api_helper";
import DetailsAccordian from "./DetailsAcordian";

const AboutWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #f1fdff; /* Your background color */
  position: relative; /* Required for absolute positioning of patches */
  overflow: hidden;
`;

const Patch = styled.div`
  position: absolute;
  width: 10vw; /* Adjust size of the patch */
  height: 10vw; /* Adjust size of the patch */
  background-color: #8decffb2; /* Patch color */
  border-radius: 50%; /* Make it round */
  transform: rotate(45deg);
  transform: scale(3);
  pointer-events: none;
  filter: blur(50px);
`;

const StyledPatch1 = styled(Patch)`
  top: 5vw;
  left: 5vw;
`;

const StyledPatch2 = styled(Patch)`
  top: 50%;
  left: 50%;
`;

const StyledPatch3 = styled(Patch)`
  bottom: 5vw;
  left: 5vw;
`;
const Heading = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 700;
  line-height: 38.73px;
  text-align: center;
  color: #25626f;
  padding-top: 100px;
`;

const AccordianWrapper = styled.div`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* left: 50%; */
  z-index: 1;
  width: 80%;
`;
function FAQ() {
  const [faqs, setFaqs] = useState([]);

  async function getFaqs() {
    try {
      const data = await get("/api/faq");
      setFaqs(data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getFaqs();
  }, []);
  return (
    <AboutWrapper>
      <Heading>Frequently ask questions</Heading>
      <AccordianWrapper>
        {faqs.map((item) => {
          return (
            <DetailsAccordian
              info={item?.description}
              name={item?.title}
              key={item?.id}
            />
          );
        })}
      </AccordianWrapper>

      <StyledPatch1 />
      <StyledPatch2 />
      <StyledPatch3 />
    </AboutWrapper>
  );
}

export default FAQ;

import React from 'react'
import styled from '@emotion/styled'

const TabSelectionWrapper = styled.div`
width: 50%;
margin: auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding-top: 40px ;
padding-bottom: 40px ;
@media (max-width: 800px) {
    width: 80%;
  }

`

const TabDisplay = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
`
const TabDisplayUp = styled.div`
width: 95%;
display: flex;
justify-content: space-between;
align-items: center;
`
const Tab = styled.div`
border-radius: 25px;
height: 50px;
background: #00B4D8;
width: 50px;
display: flex;
justify-content: center;
align-items: center;
font-family: "Inter";
font-size: 20px;
font-weight: 500;
line-height: 24.2px;



`
const HrLine = styled.div`
border: 1px dashed #7B7B7B;
width: 80%;

`
const TabName = styled.div`
font-family: "Inter";
font-size: 16px;
font-weight: 400;
line-height: 19.36px;
margin-top: 15px;

`
function SelectedTabBox({currentTab}) {
  return (
    <TabSelectionWrapper>
        <TabDisplayUp>

            <Tab style={{
                color:currentTab===1?"white":"#595959",
                background:currentTab===1?"#00B4D8":"#E3E3E3",

            }}>1</Tab>
            <HrLine />
            <Tab style={{
                color:currentTab===2?"white":"#595959",
                background:currentTab===2?"#00B4D8":"#E3E3E3",

            }}>2</Tab>

        </TabDisplayUp>
        <TabDisplay>
            <TabName style={{
                color:currentTab===1?"#00B4D8":"#242626",

            }}>
                Shipping
            </TabName>
            <TabName style={{
                color:currentTab===2?"#00B4D8":"#242626",

            }}>
                Order Summary
            </TabName>

        </TabDisplay>
        
    </TabSelectionWrapper>
  )
}

export default SelectedTabBox
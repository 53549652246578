import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 38.73px;
  text-align: left;
  margin: 20px auto;
  width: 80%;
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;

function Title({ heading }) {
  return <Wrapper>{heading}</Wrapper>;
}

export default Title;

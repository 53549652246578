import React from "react";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";
import CloseModal from "../Auth/CloseModal";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import "react-phone-input-2/lib/style.css";
const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 16px;
  width: 20%;
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

const DisplayFlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 38.73px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 35px 0;
`;
const DisplayBtnFlex = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;
const CancelBtn = styled.div`
  width: 158px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4px;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  color: #595959;
  line-height: 24.2px;
  cursor: pointer;
  `;
const RemoveBtn = styled.div`
cursor: pointer;
  width: 158px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4px;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  background: #da4848;
  color: white;
`;

function DeleteWishListItem({ removeFromWishlist,showModal, setShowModal }) {
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper>
          <CloseModal
            closeFn={() => {
              setShowModal(false);
            }}
          />
          <DisplayFlexCenter>
            <img src={DeleteIcon} alt="delete" />
            <Title>Remove item from wish list?</Title>
            <DisplayBtnFlex>
              <CancelBtn onClick={()=>{
                setShowModal(false);
              }}>Cancel</CancelBtn>
              <RemoveBtn onClick={()=>{
                removeFromWishlist()
                setShowModal(false);

              }}>Remove it</RemoveBtn>
            </DisplayBtnFlex>
          </DisplayFlexCenter>
        </LoginFormWrapper>
      </Modal>
    </>
  );
}

export default DeleteWishListItem;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CountryCodes from "../../assets/json/countrycode.json";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import { get, post } from "../../api_helper/api_helper";
import { Autocomplete, Checkbox, Grid, TextField } from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectedDeliveryAddress } from "../../store/reducer/reducer";
import { useNavigate } from "react-router-dom";
import SingleAddressitem from "./SingleAddressitem";
const AddressFormWrapper = styled.div`
  width: 60%;
  margin: auto;
  box-shadow: 0px 4px 30px 0px #42424229;
  background: white;
  padding-bottom: 30px;

  @media (max-width: 800px) {
    width: 97%;
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 29.05px;
  padding: 40px;
`;

const WidthAdjuster = styled.div`
  width: 90%;
  margin: auto;
`;

const ContinueButton = styled.button`
  width: 100%;
  height: 52px;
  background: #00b4d8;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  border-radius: 8px;
  border: none;
  outline: none;
  margin: 20px auto;
  cursor: pointer;
`;
const Divider = styled.div`
  border: 1px solid #e3e3e3;
  width: 100%;
  margin: 40px 0;
`;

const HeadingSaved = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
`;
function AddressForm() {
  const [userAddresses, setUserAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.general.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const { deliveryAddress } = await post(`/api/create-delivery-address`, {
        ...values,
        ...(values.selectedAddress && { id: values.selectedAddress }),
        ...(user && { user_id: user?.id }),
        saveThis: true,
      });
      dispatch(selectedDeliveryAddress({ ...deliveryAddress }));
      setLoading(false);
      formik.resetForm();
      getUserDeliveryAddress();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
    }
  };

  const validationSchema = Yup.object().shape({
    selectedAddress: Yup.string(), // Selected address is now optional
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    address: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    zipcode: Yup.string().required("Zip code is required"),
    mobile_number_country_code: Yup.string().required(
      "Country code is required"
    ),
    mobile_number: Yup.string().required("Phone number is required"),
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      selectedAddress: "",
      first_name: "",
      last_name: "",
      address: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      mobile_number_country_code: "",
      mobile_number: "",
      state: "",
      country: "",
    },
    onSubmit,
  });
  async function getUserDeliveryAddress() {
    try {
      const addressInfo = await get(`/api/get-delivery-address`);
      setUserAddresses(addressInfo?.userDeliveryAddresses || []);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      getUserDeliveryAddress();
    }
  }, []);
  return (
    <form onSubmit={formik.handleSubmit}>
      <WidthAdjuster>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="first_name"
              label="First Name"
              name="first_name"
              variant="outlined"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.first_name)}
              helperText={formik.errors.first_name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="last_name"
              label="Last Name"
              name="last_name"
              variant="outlined"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.last_name)}
              helperText={formik.errors.last_name}
            />
          </Grid>
        </Grid>
        <div style={{ height: "20px" }}></div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="address"
              label="Address"
              name="address"
              variant="outlined"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.address)}
              helperText={formik.errors.address}
            />
          </Grid>
        </Grid>
        <div style={{ height: "20px" }}></div>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                value={formik.values.country}
                // inputValue={formik.values.country}
                onChange={(_, value) =>
                  formik.setFieldValue("country", value?.value)
                }
                id="combo-box-demo"
                options={[{ label: "Canada", value: "Canada" }]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    helperText={formik.errors.country}
                    error={Boolean(formik.errors.country)}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                value={formik.values.state}
                onChange={(_, value) =>
                  formik.setFieldValue("state", value?.value)
                }
                id="combo-box-demo"
                options={[
                  { label: "Alberta", value: "Alberta" },
                  { label: "British Columbia", value: "British Columbia" },
                  { label: "Manitoba", value: "Manitoba" },
                  { label: "New Brunswick", value: "New Brunswick" },
                  {
                    label: "Newfoundland and Labrador",
                    value: "Newfoundland and Labrador",
                  },
                  { label: "Nova Scotia", value: "Nova Scotia" },
                  { label: "Ontario", value: "Ontario" },
                  {
                    label: "Prince Edward Island",
                    value: "Prince Edward Island",
                  },
                  { label: "Quebec", value: "Quebec" },
                  { label: "Saskatchewan", value: "Saskatchewan" },
                  {
                    label: "Northwest Territories",
                    value: "Northwest Territories",
                  },
                  { label: "Nunavut", value: "Nunavut" },
                  { label: "Yukon", value: "Yukon" },
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    helperText={formik.errors.state}
                    error={Boolean(formik.errors.state)}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ height: "20px" }}></div>

        <div style={{ height: "20px" }}></div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="city"
              label="City"
              name="city"
              variant="outlined"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.city)}
              helperText={formik.errors.city}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="zipcode"
              label="Zip Code"
              name="zipcode"
              variant="outlined"
              value={formik.values.zipcode}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.zipcode)}
              helperText={formik.errors.zipcode}
            />
          </Grid>
        </Grid>
        <div style={{ height: "20px" }}></div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <Autocomplete
                value={formik.values.mobile_number_country_code}
                disablePortal
                // inputValue={formik.values.mobile_number_country_code}
                onChange={(_, value) =>
                  formik.setFieldValue(
                    "mobile_number_country_code",
                    value?.dial_code
                  )
                }
                id="combo-box-demo"
                options={CountryCodes.map((item) => ({
                  ...item,
                  label: `${item.flag} (${item?.dial_code}) - ${item.name}`,
                  value: item?.dial_code,
                }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country Code"
                    helperText={formik.errors.mobile_number_country_code}
                    error={Boolean(formik.errors.mobile_number_country_code)}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <div style={{ height: "20px" }}></div>

          <Grid item xs={7}>
            <TextField
              fullWidth
              id="phone-number"
              label="Phone Number"
              name="mobile_number"
              variant="outlined"
              value={formik.values.mobile_number}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.mobile_number)}
              helperText={formik.errors.mobile_number}
            />
          </Grid>
        </Grid>
        <div style={{ height: "20px" }}></div>

        <ContinueButton type="submit" disabled={!formik.isValid}>
          Save
        </ContinueButton>
        <ContinueButton
          type="button"
          onClick={() => {
            formik.resetForm();
          }}
        >
          Reset Selection
        </ContinueButton>
        <Divider />

        <HeadingSaved>Select Saved Address</HeadingSaved>

        {userAddresses.map((item) => {
          return (
            <SingleAddressitem
              isSelected={formik?.values?.selectedAddress === item?.id}
              func={() => {
                const { id, ...rest } = item;
                formik.setValues({
                  ...formik.values,
                  ...rest,
                  selectedAddress: item.id,
                });
              }}
              key={item?.id}
              addStr={`${item?.address || ""} ${item?.landmark || ""} 
               ${item?.city || ""} ${item?.state || ""}  ${
                item?.mobile_number || ""
              } `}
            />
          );
        })}
      </WidthAdjuster>
    </form>
  );
}

export default AddressForm;

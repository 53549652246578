import React, { useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";
import CountryCodes from "../../assets/json/countrycode.json";

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import CloseModal from "../Auth/CloseModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { put } from "../../api_helper/api_helper";
const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
  width: 30%;
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 90%;
  }
`;
const Heading = styled.div`
  font-family: "Inter";
  font-size: 28px;
  line-height: 33.89px;
  font-weight: 600;
  margin-bottom: 20px;
`;

function UpdateUserMobile({
  setShowUpdateUserMobile,
  showUpdateUserMobileModal,
  setShowOtpModal,
  setCountryCode,
  setPhoneNumber,
  phoneNumber,
  couuntryCode
}) {

  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.general.user);

  async function updateUserMobileNumber() {
    // if (user?.mobilenumber === phoneNumber) {
    //   return toast.error("Mobile number cannot be same!");
    // }
   
    try {
      setLoading(true);
      await put("/api/auth/profile-info-mobile", {
        new_mobile_number: phoneNumber,
        new_country_code: couuntryCode,
      });
      setShowOtpModal(true)
      setShowUpdateUserMobile(false)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  }
  return (
    <>
      <Modal
        open={showUpdateUserMobileModal}
        onClose={() => {
          setShowUpdateUserMobile(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper>
          <CloseModal
            closeFn={() => {
              setShowUpdateUserMobile(false);
            }}
          />
          <Heading>Enter New Mobile Number</Heading>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  onChange={(_, value) => setCountryCode(value.dial_code)}
                  id="combo-box-demo"
                  options={CountryCodes.map((item) => ({
                    ...item,
                    label: `${item.flag} (${item.dial_code}) - ${item.name}`,
                    value: item.dial_code,
                  }))}
                  renderInput={(params) => (
                    <TextField {...params} label="Country Code" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="phone-number"
                label="Phone Number"
                name="phone_number"
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                margin: "20px auto",
                background: "#00B4D8",
                color: "white",
                borderRadius: "24px",
                width: "80%",
                height: "43px",
              }}
              variant="contained"
              type="submit"
              onClick={updateUserMobileNumber}
              disabled={loading}
            >
              {loading ? <CircularProgress /> : `Update`}
            </Button>
          </div>
        </LoginFormWrapper>
      </Modal>
    </>
  );
}

export default UpdateUserMobile;

import React from "react";
import styled from "@emotion/styled";
import AddressForm from "./AddressForm";
import SelectedTabBox from "./SelectedTabBox";

const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  padding: 40px;
`;

function SelectAddress({currentTab,setCurrentTab}) {
  return (
    <>
      <Heading></Heading>

     

      <AddressForm currentTab={currentTab} setCurrentTab={setCurrentTab} />
    </>
  );
}

export default SelectAddress;

import React from "react";
import styled from "@emotion/styled";
import HeadingWithLinks from "./HeadingWithLinks";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/system";

const FooterParentWrapper = styled.div`
  min-height: 441px;
  width: 100%;
  background: #25626f;
  color: white;
  position: relative;
  padding-top: 20px;
  box-sizing: border-box;
`;

const LinkWrapper = styled.div`
  width: 80%;
  margin: auto;
  max-width:1525px; 
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 600px) {
      flex-direction: column;
      padding: 30px 0px;
  }
`;
const FooterCredits = styled.div`
  width: 74%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }

`;

function Footer() {
    const matches = useMediaQuery('(max-width:600px)');

  return (
    <FooterParentWrapper>
      <LinkWrapper>
        <HeadingWithLinks
          heading={`Get to Know Us`}
          links={[{ link: "/about", linkName: "About Us" }]}
        />
        <HeadingWithLinks
          heading={`Make Money With Us`}
          links={[{ link: "", linkName: "Sell on Shipora" }]}
        />
        <HeadingWithLinks
          heading={`Let Us Help You`}
          links={[
            { link: "/user-profile", linkName: "Your Account" },
            { link: "/faq", linkName: "Help" },
          ]}
        />
        <HeadingWithLinks
          heading={`Connect with Us`}
          links={[
            { link: "", linkName: "Facebook" },
            { link: "", linkName: "Twitter" },
            { link: "", linkName: "Instagram" },
          ]}
        />
      </LinkWrapper>

      <FooterCredits>
        <Link to={"https://techrabbit.io/"} style={{textDecoration:"underline",color:"white",marginRight:"20px",marginBottom:matches?"10px":""}}>© {new Date().getFullYear()} Shipora, All rights reserved. Developed by Tech Rabbit</Link>
        <Link onClick={()=>{
          window.scrollTo(0, 0);
        }} to={"/terms-and-condition"} style={{textDecoration:"underline",color:"white",marginRight:"20px",marginBottom:matches?"10px":""}}>Terms & Conditions</Link>
        <Link onClick={()=>{
          window.scrollTo(0, 0);
        }} to={"/privacy-policy"} style={{textDecoration:"underline",color:"white",marginRight:"20px",marginBottom:matches?"10px":""}}>Privacy Policy</Link>
      </FooterCredits>
    </FooterParentWrapper>
  );
}

export default Footer;

import React from "react";
import styled from "@emotion/styled";
import TermsItem from "../../components/TermsAndConditions/TermsItem";
import PolicyItem from "../../components/PrivacyPolicy/PolicyItem";
import Title from "../../components/PrivacyPolicy/Title";

const Heading = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 43.57px;
  margin: 30px auto;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;

const Paratitle = styled.span`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
`;
const ParaDetail = styled.span`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
`;
const HeadingWithParaWrapper = styled.div`
  width: 80%;
  text-align: justify;
  margin: 15px auto;
`;

const ListWrapper = styled.div`
  width: 80%;
  margin: 15px auto;
  padding-left: 20px;
`;
const ListItem = styled.li`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #595959;
`;
const TextItem = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #595959;
  width: 80%;
  margin: 10px auto;
`;

function PrivacyPolicy() {
  return (
    <>
      <Heading>Privacy Policy</Heading>
      <div style={{ marginBottom: "40px" }}>&nbsp;&nbsp;</div>

      <PolicyItem
        description={`Thank you for visiting [YourWebsite.com] (the "Website") and for considering how we handle your personal information. This
Privacy Policy explains how [YourCompany] ("we," "us," or "our") collects, uses, and safeguards your personal information when
you use our Website or services. By accessing or using our Website, you consent to the practices described in this
Privacy Policy.`}
      />

      <Title heading={`1. Information We Collect`} />
      <HeadingWithPara
        detail={` We may collect personal information, such as your name, email address, postal address, phone
number, and other information you voluntarily provide when you register for an account, subscribe to our newsletter, make a
purchase, or otherwise interact with our Website.`}
        heading={`1.1. Personal Information:`}
      />
      <HeadingWithPara
        detail={` We automatically collect information about how you use our Website, including your IP address,
browser type, operating system, and browsing habits. This information helps us improve our services and user experience.`}
        heading={`1.2. Usage Information:`}
      />
      <HeadingWithPara
        detail={` We use cookies and similar tracking technologies to collect information about your
interactions with our Website. You can manage your cookie preferences through your browser settings`}
        heading={`1.3. Cookies and Tracking Technologies::`}
      />
      <Title heading={`2. How We Use Your Information`} />
      <ListBox arr={List1} />
      <Title heading={`3. Information Sharing`} />
      <TextItem>
        3.1. We may share your personal information with third parties in the
        following circumstances:
      </TextItem>
      <ListBox arr={List2} />
      <Title heading={`4. Data Security`} />
      <TextItem>
        4.1. We take reasonable measures to protect your personal information
        from unauthorized access, disclosure, alteration, or destruction.
        However, no method of transmission over the internet or electronic
        storage is entirely secure, and we cannot guarantee absolute security.
      </TextItem>
      <Title heading={`5. Your Choices`} />
      <ListBox arr={List3} />
      <Title heading={`6. Children's Privacy`} />
      <ListBox arr={List3} />
      <Title heading={`7. Changes to this Privacy Policy`} />
      <TextItem>
        7.1. We reserve the right to update or modify this Privacy Policy at any
        time. Any changes will be effective immediately upon posting the updated
        Privacy Policy on our Website.
      </TextItem>
      <div style={{ marginBottom: "40px" }}>&nbsp;&nbsp;</div>
    </>
  );
}

function HeadingWithPara({ heading, detail }) {
  return (
    <HeadingWithParaWrapper>
      {" "}
      <Paratitle>{heading}</Paratitle> <ParaDetail>{detail}</ParaDetail>
    </HeadingWithParaWrapper>
  );
}

function ListBox({ arr }) {
  return (
    <ListWrapper>
      {arr.map((el) => {
        return <ListItem>{el}</ListItem>;
      })}
    </ListWrapper>
  );
}
export default PrivacyPolicy;

const List1 = [
  "To provide and maintain our services.",
  "To process and fulfill orders and requests.",
  "To send you updates, newsletters, and promotional materials.",
  "To improve and personalize your user experience.",
  "To analyze and monitor usage patterns and trends.",
  "To comply with legal obligations and protect our rights.",
];

const List2 = [
  "With your consent.",
  "To third-party service providers who assist us in delivering our services.",
  "To comply with legal obligations or to protect our rights, privacy, safety, or property.",
  "In connection with a business transaction, such as a merger, acquisition, or sale of assets.",
];

const List3 = [
  "You can review and update your account information.",
  "You can opt out of receiving promotional emails from us.",
  "You can delete your account by contacting us.",
];

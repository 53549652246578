import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from "@emotion/styled"

const Heading = styled.span`
font-family: "Inter";
font-size: 20px;
font-weight: 500;
line-height: 20px;
`


export default function DetailsAccordian({name,info}) {
  return (
      <Accordion style={{width:"100%",margin:"10px 0"}}>
        <AccordionSummary
        
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${name}-content`}
          id={`${name}-header`}
        >
          <Heading>{name}</Heading>
        </AccordionSummary>
        <AccordionDetails dangerouslySetInnerHTML={{__html:info}} />
      </Accordion>
      
  );
}

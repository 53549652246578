const Recieve = ({ color }) => {
    return (
        <svg width="70" height="64" viewBox="0 0 70 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M61.3546 22.5334C61.2782 22.0427 61.0151 21.6112 60.6149 21.3194L47.4327 11.6737C47.0368 11.384 46.481 11.47 46.1913 11.866C45.9016 12.262 45.9877 12.8178 46.3836 13.1074L59.5669 22.7538C59.5849 22.7669 59.5957 22.7848 59.5992 22.8068C59.6026 22.8291 59.5976 22.8497 59.5843 22.8678L58.2237 24.7271C58.196 24.7648 58.1446 24.7729 58.1076 24.7458L32.2872 5.85198C31.5508 5.31426 30.5614 5.31426 29.8246 5.85245L4.00587 24.7451C3.96785 24.7728 3.91657 24.7648 3.88897 24.7273L2.52821 22.8679C2.51482 22.8497 2.50985 22.8291 2.5134 22.8068C2.51684 22.7848 2.52773 22.767 2.54668 22.7531L30.3277 2.42537C30.7635 2.10653 31.349 2.10664 31.7849 2.42537L43.0389 10.6593C43.4349 10.949 43.9907 10.8627 44.2803 10.4669C44.57 10.071 44.4839 9.51512 44.0879 9.22553L32.8339 0.991643C31.7707 0.213723 30.3419 0.213723 29.2787 0.991643L1.49848 21.3186C1.09732 21.6112 0.834266 22.0427 0.757872 22.5334C0.681478 23.0245 0.800866 23.5158 1.09436 23.9169L2.45537 25.7767C3.06024 26.6028 4.22558 26.7837 5.05396 26.1795L5.1962 26.0754V35.3073C5.1962 35.7978 5.59393 36.1956 6.08451 36.1956C6.57509 36.1956 6.97282 35.7978 6.97282 35.3073V24.7754L30.873 7.28654C30.9825 7.20647 31.1298 7.20647 31.2387 7.28606L55.1396 24.7754V42.1613C55.1396 42.6519 55.5373 43.0496 56.0279 43.0496C56.5185 43.0496 56.9162 42.6519 56.9162 42.1613V26.0755L57.0595 26.1803C57.3895 26.421 57.7729 26.537 58.1535 26.5368C58.7271 26.5368 59.2935 26.2732 59.6573 25.7764L61.0183 23.9168C61.3115 23.5158 61.431 23.0245 61.3546 22.5334Z"
                fill={color}
                stroke={color}
            />

            <path
                d="M58.4199 59.2744H56.9155V46.3079C56.9155 45.8173 56.5178 45.4195 56.0272 45.4195C55.5366 45.4195 55.1389 45.8173 55.1389 46.3079V51.6186H48.6938V31.9284C48.6938 30.779 47.7586 29.8438 46.6091 29.8438H15.502C14.3525 29.8438 13.4173 30.779 13.4173 31.9284V51.6187H6.97209V39.4538C6.97209 38.9632 6.57436 38.5655 6.08378 38.5655C5.5932 38.5655 5.19548 38.9632 5.19548 39.4538V59.2744H3.69104C3.20046 59.2744 2.80273 59.6721 2.80273 60.1627C2.80273 60.6533 3.20046 61.051 3.69104 61.051H58.4199C58.9105 61.051 59.3082 60.6533 59.3082 60.1627C59.3082 59.6721 58.9105 59.2744 58.4199 59.2744ZM55.1389 53.3952V59.2744H48.6938V53.3952H55.1389ZM15.1938 31.9284C15.1938 31.7585 15.332 31.6202 15.502 31.6202H46.6091C46.7791 31.6202 46.9173 31.7585 46.9173 31.9284V59.2744H43.4296V48.6783C43.4296 48.1877 43.0319 47.79 42.5413 47.79H37.6868V37.1926C37.6868 36.7021 37.289 36.3043 36.7984 36.3043H25.3128C24.8222 36.3043 24.4245 36.7021 24.4245 37.1926V47.79H19.5699C19.0793 47.79 18.6816 48.1877 18.6816 48.6783V59.2744H15.1939L15.1938 31.9284ZM26.201 47.7901V38.0811H28.3726V40.782C28.3726 41.2726 28.7703 41.6703 29.2609 41.6703H32.8501C33.3407 41.6703 33.7384 41.2726 33.7384 40.782V38.0811H35.91V47.7901H26.201ZM24.4063 49.5666H26.219V51.3793H24.4063V49.5666ZM30.1492 38.081H31.9618V39.8936H30.1492V38.081ZM35.892 49.5666H37.7046V51.3793H35.892V49.5666ZM6.97209 53.3952H13.4172V59.2744H6.97209V53.3952ZM20.4581 49.5666H22.6297V52.2676C22.6297 52.7581 23.0275 53.1559 23.518 53.1559H27.1073C27.5978 53.1559 27.9956 52.7581 27.9956 52.2676V49.5666H30.1672V59.2744H20.4581V49.5666ZM31.9438 59.2744V49.5666H34.1154V52.2676C34.1154 52.7581 34.5131 53.1559 35.0037 53.1559H38.5929C39.0835 53.1559 39.4813 52.7581 39.4813 52.2676V49.5666H41.6529V59.2744H31.9438Z"
                fill={color}
                stroke={color}
            />

            <path
                d="M36.0809 24.1061C37.0986 24.1061 37.9263 23.2782 37.9263 22.2606V20.3464C37.9263 19.3288 37.0984 18.501 36.0809 18.501H26.031C25.0133 18.501 24.1855 19.3289 24.1855 20.3464V22.2606C24.1855 23.2782 25.0134 24.1061 26.031 24.1061H36.0809ZM25.962 22.2606V20.3464C25.962 20.3084 25.993 20.2775 26.031 20.2775H36.0809C36.1189 20.2775 36.1498 20.3084 36.1498 20.3464V22.2606C36.1498 22.2985 36.1189 22.3295 36.0809 22.3295H26.031C25.993 22.3295 25.962 22.2987 25.962 22.2606Z"
                fill={color}
                stroke={color}
            />
        </svg>
    );
};

export default Recieve;

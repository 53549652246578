import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";
import "react-phone-input-2/lib/style.css";
import CloseModal from "../Auth/CloseModal";
import Box from "../../assets/images/Icon1";
import Truck from "../../assets/images/Truck";
import Packing from "../../assets/images/Icon2";
import Airplane from "../../assets/images/Icon3";
import Delivered from "../../assets/images/Icon4";
import InfoIcon from "@mui/icons-material/Info";  // Import the Info icon from Material-UI
import Process from "../../assets/images/Process";
import Recieve from "../../assets/images/Recieve";
import Shipped from "../../assets/images/Shipped";
import Reached from "../../assets/images/Reached";
import Done from "../../assets/images/Done";
import Hold from "../../assets/images/Hold";
import Cancelled from "../../assets/images/Cancelled";


// New style for the info icon
const InfoIconWrapper = styled.div`
  cursor: pointer;
  font-size: 0px;
  color: red;
  margin-left: 8px;

`;

const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  width: clamp(296px, 50%, 400px);
  max-height: 90vh;
  padding: 24px;
  overflow-y: auto;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
`;

const Heading = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #111827;
`;

const TimelineContainer = styled.div`
  position: relative;
  margin-left: 12px;
`;

const VerticalLine = styled.div`
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #E5E7EB;
`;

const StatusItem = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 12px 0;
  gap: 32px;
`;

const StatusDot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid 
    ${({ active, status }) =>
    status === "HOLD" ||     status === "CANCELLED" ? "red" : active ? "rgba(28, 210, 156, 1)" : "#E5E7EB"};
  background-color: 
    ${({ completed, status }) =>
    status === "HOLD" || status === "CANCELLED" ? "red" : completed ? "none" : "white"};
  position: absolute;
  left: 6px;
  top: 22px;
  transform: translateX(-50%);
  z-index: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 16px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  
  svg {
    fill: ${({ active, status }) =>
    status === "HOLD" || status === "CANCELLED" ? "red" : active ? "none" : "none"};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
      align-items: center;
`;

const StatusText = styled.span`
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: ${({ active, status }) =>
     status === "HOLD" || status === "CANCELLED" ? "red" : active ? "rgba(28, 210, 156, 1)" : "#6B7280"};
`;

const DateText = styled.span`
  font-size: 12px;
  color: #6B7280;
  margin-top: 2px;
`;

const HoldReasonModal = styled.div`
  padding: 12px;
  background: white;
  border-radius: 10px;
  max-width: 300px;
  margin:10px auto;
  position:relative;
`;

function OrderStatusModal({
  selectedOrder,
  setSelectedOrder,
  displayModal,
  setDisplayModal,
}) {
  const [holdModalOpen, setHoldModalOpen] = useState(false);
  const [cancelModalOpen, setcancelModalOpen] = useState(false);
  

  const statuses = [
    { status: "PLACED", Icon: Box },
    { status: "READY_TO_DISPATCH", Icon: Truck },
    { status: "PROCESSING", Icon: Process },
    { status: "RECEIVED", Icon: Recieve },
    { status: "SHIPPED", Icon: Shipped },
    { status: "REACHED_TO_LOCAL_PARTNER", Icon: Reached },
    { status: "DELIVERED", Icon: Done },
  ];

  if (selectedOrder?.order_status === "HOLD") {
    const currentIndex = statuses.findIndex(s => s.status === selectedOrder?.order_status);
    if (currentIndex === -1) {
      statuses.splice(1, 0, { status: "HOLD", Icon: Hold });
    }
  }

  if (selectedOrder?.order_status === "CANCELLED") {
    const currentIndex = statuses.findIndex(s => s.status === selectedOrder?.order_status);
    if (currentIndex === -1) {
      statuses.splice(1, 0, { status: "CANCELLED", Icon: Cancelled });
    }
  }

  const isCompleted = (status) => {
    const currentIndex = statuses.findIndex(s => s.status === selectedOrder?.order_status);
    const statusIndex = statuses.findIndex(s => s.status === status);
    return statusIndex < currentIndex;
  };

  const isActive = (status) => {
    return status === selectedOrder?.order_status;
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  return (
    <Modal
      open={displayModal}
      onClose={() => {
        setDisplayModal(false);
        setSelectedOrder(null);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <LoginFormWrapper>
        <CloseModal
          closeFn={() => {
            setDisplayModal(false);
            setSelectedOrder(null);
          }}
        />
        <Heading>Orders #{selectedOrder?.display_id}</Heading>
        <TimelineContainer>
          <VerticalLine />
          {statuses.map(({ status, Icon }) => {
            const completed = isCompleted(status);
            const active = isActive(status);
            return (
              <StatusItem key={status}>
                <StatusDot completed={completed} active={completed || active} status={status} />
                <ContentWrapper>
                  <IconWrapper active={completed || active} status={status}>
                    <Icon
                      color={status === "HOLD" || status === "CANCELLED" ? "red" : completed || active ? "rgba(28, 210, 156, 1)" : "#6B7280"}
                    />
                  </IconWrapper>
                  <TextWrapper>
                    <StatusText active={completed || active} status={status}>
                      {status.replace(/_/g, " ")}
                    </StatusText>
                    {/* {status === "PLACED" && (
                      <DateText>
                        {formatDate(selectedOrder?.createdAt)}
                      </DateText>
                    )} */}
                    {status === "HOLD" && (
                      <InfoIconWrapper onClick={() => setHoldModalOpen(true)}>
                        <InfoIcon />
                      </InfoIconWrapper>
                    )}

                      {status === "CANCELLED" && (
                      <InfoIconWrapper onClick={() => setcancelModalOpen(true)}>
                        <InfoIcon />
                      </InfoIconWrapper>
                    )}
                  </TextWrapper>
                </ContentWrapper>
              </StatusItem>
            );
          })}
        </TimelineContainer>

        {/* Modal for Hold Reason */}
        {holdModalOpen && (
          <Modal
            open={holdModalOpen}
            onClose={() => setHoldModalOpen(false)}
          >
            <HoldReasonModal>
            <CloseModal
                closeFn={() => {
                  setHoldModalOpen(false);

                }}
              />
              <h3 style={{marginTop:"0"}}>Reason for Hold</h3>
              <p>{selectedOrder?.cancellation_or_hold_reason || "This order is on hold due to some issues."}</p>
              {/* <span onClick={() => setHoldModalOpen(false)} style={{cursor:"pointer", position:"absolute", top:"7px", right:"10px", fontWeight:"500", fontSize:"20px"}}>X</span> */}
         

            </HoldReasonModal>
          </Modal>
        )}

{cancelModalOpen && (
          <Modal
            open={cancelModalOpen}
            onClose={() => setcancelModalOpen(false)}
          >
            <HoldReasonModal>
            <CloseModal
                closeFn={() => {
                  setcancelModalOpen(false);

                }}
              />
              <h3 style={{marginTop:"0"}}>Reason for cancel</h3>
              <p>{selectedOrder?.cancellation_or_hold_reason || "This order is cancelled to some issues."}</p>
              {/* <span onClick={() => setHoldModalOpen(false)} style={{cursor:"pointer", position:"absolute", top:"7px", right:"10px", fontWeight:"500", fontSize:"20px"}}>X</span> */}
         

            </HoldReasonModal>
          </Modal>
        )}
      </LoginFormWrapper>
    </Modal>
  );
}

export default OrderStatusModal;

import React from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { showLoginModal, showSignupModal } from "../../store/reducer/reducer";
import { useDispatch } from "react-redux";

const ParentWrapper = styled.div`
  margin: 100px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Heading = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 38.73px;
  text-align: center;
`;
const RegisterDiv = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
`;
function ExploreMarketPlace() {
  const dispatch = useDispatch();
  return (
    <ParentWrapper>
      <Heading>Explore The Marketplace</Heading>

      <Button
        style={{
          fontFamily: "Inter",
          fontSize: "24px",
          lineHeight: "29.05px",
          fontWeight: 400,
          textTransform: "capitalize",
          margin: "15px auto",
          color: "white",
          background: "#00B4D8",
          borderRadius: "52px",
          width: "212px",
          height: "52px",
        }}
        onClick={() => {
          dispatch(showLoginModal(true));
        }}
      >
        Sign in
      </Button>

      <RegisterDiv>
        <span>or{` `}</span>
        <span
          style={{
            cursor: "pointer",
            color: "#0587FF",
            textDecoration: "underline",
          }}
          onClick={() => {
            dispatch(showSignupModal(true));
          }}
        >
          Register
        </span>
      </RegisterDiv>
    </ParentWrapper>
  );
}

export default ExploreMarketPlace;

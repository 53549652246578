import styled from "@emotion/styled";
import React from "react";

const ParentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 40px auto;
  border: 1px solid #19889f;
  border-radius: 16px;
  padding: 50px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 20px;
  }
`;
const ParentWrapperRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  width: 80%;
  margin: 40px auto;
  border: 1px solid #19889f;
  border-radius: 16px;
  padding: 50px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    padding: 20px;
  }
`;

const ImgBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContentBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 50px;
  @media (max-width: 800px) {
    padding: 0;
  }
`;
const Image = styled.img`
  width: calc(100% - 30%);
`;

const Title = styled.div`
  font-family: "Inter";
  font-size: 28px;
  font-weight: 600;
  line-height: 33.89px;
  text-align: left;
  margin: 15px 0;
  color: #25626f;
`;
function ImgWithText({ img, title, content, isRight }) {
  return isRight ? (
    <ParentWrapperRight>
      <CommonContent img={img} title={title} content={content} />
    </ParentWrapperRight>
  ) : (
    <ParentWrapper>
      <CommonContent img={img} title={title} content={content} />
    </ParentWrapper>
  );
}

function CommonContent({ img, title, content }) {
  return (
    <>
      <ImgBox>
        <Image src={img} />
      </ImgBox>
      <ContentBox>
        <Title>{title}</Title>

        {content}
      </ContentBox>
    </>
  );
}

export default ImgWithText;

import React, { useRef } from "react";
import styled from "@emotion/styled";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "slick-carousel/slick/slick.css";
import { useState } from "react";
import { useSelector } from "react-redux";

import "slick-carousel/slick/slick-theme.css";
import SingleProduct from "./SingleProduct";
import { useMediaQuery } from "@mui/material";
import { post } from "../../api_helper/api_helper";
import { useEffect } from "react";
import { useCallback } from "react";
import PropTypes from 'prop-types';

const WidthAdjuster = styled.div`
  width: 80%;
  margin: 10px auto;
  margin-top: 50px;
  max-width:1525px;
  
   @media (max-width: 600px) {
   width: 95%;
  }
 
`;
const HeadingWithLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;
const Heading = styled.div`
  font-family: "Inter";
  font-weight: 500;
  font-size: 24px;
  line-height: 29.05px;
  @media (max-width: 600px) {
    font-size: 19px;
  line-height: 24.05px;
  }
`;
const ButtonWrapper = styled.div`
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  
`;
const CarouselButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
 
 width: 6%;

 
  @media (max-width: 600px) {
    width: 20% !important;

  }

    @media (max-width: 1800px) {
    width: 6%;

  }

   @media (max-width: 1271px) {
    width: 11%;

  }

  
   @media (max-width: 990px) {
    width: 15%;

  }

     @media (max-width: 400px) {
    width: 22% !important;

  }

    @media (max-width: 370px) {
    width: 25% !important;

  }
`;
const SliderWrapper = styled.div`
  margin-top: 40px;
  margin:auto;
`;
const ProductWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const PositionAbsolute = styled.div`
position: absolute;
right:5px;
top:5px;
z-index: 1000;
color:red;
`;
export default function PopularProductSlider({ products, getUserWishlistItems, wishlistItems, setWishlistItems }) {
  // console.log("Populer Product")
  const matches = useMediaQuery('(max-width:600px)');
  // const [wishlistItems, setWishlistItems] = useState([]);

  const user = useSelector((state) => state.general.user);
  const sliderRef = useRef(null);

  // async function getUserWishlistItems() {
  //   if (user) {
  //     // Fetch wishlist items from API for logged-in users
  //     try {
  //       const items = await post("/api/wishlist", {});
  //       setWishlistItems(items?.wishlist?.wishlist_items || []);

  //     } catch (error) {
  //       console.error("Error fetching wishlist items:", error);
  //     }
  //   } else {
  //     // Fetch wishlist items from local storage for guest users
  //     const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //     setWishlistItems(storedWishlist);
  //   }
  // }

  // useEffect(() => {

  //   getUserWishlistItems();
  // }, [user]);

  // async function getUserWishlistItems() {
  //   if (user) {
  //     // Fetch wishlist items from API for logged-in users
  //     try {
  //       const items = await post("/api/wishlist", {});
  //       console.log("Fetched wishlist items (logged-in user):", items?.wishlist?.wishlist_items);
  //       setWishlistItems(items?.wishlist?.wishlist_items || []);
  //     } catch (error) {
  //       console.error("Error fetching wishlist items:", error);
  //     }
  //   } else {
  //     // Fetch wishlist items from local storage for guest users
  //     const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //     console.log("Fetched wishlist items (guest user):", storedWishlist);
  //     setWishlistItems(storedWishlist);
  //   }
  // }


  // useEffect(() => {
  //   console.log("PopularProductSlider: wishlistItems updated:", wishlistItems);
  // }, [wishlistItems]);


  // useEffect(() => {
  //   // Call this on initial render to load wishlist items
  //   getUserWishlistItems();
  // }, []); // Run only once on mount

  // useEffect(() => {
  //   console.log("PopularProductSlider: wishlistItems updated:", wishlistItems);
  // }, [wishlistItems]);
// Determine if we should show arrows based on `slidesToShow` and the number of products
const slidesToShow = products.length === 1 ? 1 : 5; // If only 1 product, show 1 slide, else show 5
const showArrows = slidesToShow === 5 && products.length > 1; // Show arrows only when `slidesToShow` is 5 and there are more than 1 product

  const repeatedProducts = products.length < 4 ? [...products, ...products] : products;
  //   const settings = {
  //     dots: false,
  //     infinite: products.length > 1,
  //     speed: 500,
  //     slidesToShow: 5,
  //     slidesToScroll: 1,
  //     responsive: [
  //       {
  //         breakpoint: 1479,
  //         settings: {
  //           slidesToShow: 3,
  //           slidesToScroll: 3,
  //         }
  //       },
  //       {
  //         breakpoint: 1036,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 2,
  //         }
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 2,
  //         }
  //       },
  //     ]
  //   };
  // // console.log("set",setWishlistItems)
  //   return (
  //     <WidthAdjuster>
  //       <HeadingWithLink>
  //         <Heading>Explore Popular Products</Heading>
  //         <CarouselButtonWrapper>
  //           <ButtonWrapper onClick={() => sliderRef.current?.slickPrev()}>
  //             <ArrowBackIosIcon style={matches ? { fontSize: "12px", color: "#595959", paddingLeft: '10px' } : { fontSize: "16px", color: "#595959", paddingLeft: '6px' }} />
  //           </ButtonWrapper>
  //           <ButtonWrapper onClick={() => sliderRef.current?.slickNext()}>
  //             <ArrowForwardIosIcon style={matches ? { fontSize: "12px", color: "#595959" } : { fontSize: "16px", color: "#595959" }} />
  //           </ButtonWrapper>
  //         </CarouselButtonWrapper>
  //       </HeadingWithLink>
  //       <SliderWrapper>
  //         <Slider ref={sliderRef} {...settings}>
  //           {products.map((item) => (
  //             <SingleProduct
  //               key={item.id}
  //               product={item}
  //               getUserWishlistItems={getUserWishlistItems}
  //               wishlistItems={wishlistItems}
  //               setWishlistItems={setWishlistItems}



  //             />
  //           ))}
  //         </Slider>
  //       </SliderWrapper>
  //     </WidthAdjuster>
  //   );

  // Dynamically repeat the products if there are fewer than 3


  const settings = {
    dots: false,
    infinite: true, // Ensure infinite scrolling works with repeated items
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1479,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1036,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
    ]
  };

  return (
    <WidthAdjuster>
      <HeadingWithLink>
        <Heading>Explore Popular Products</Heading>
        <CarouselButtonWrapper>
          <ButtonWrapper onClick={() => sliderRef.current?.slickPrev()}>
            <ArrowBackIosIcon style={matches ? { fontSize: "12px", color: "#595959", paddingLeft: '10px' } : { fontSize: "16px", color: "#595959", paddingLeft: '6px' }} />
          </ButtonWrapper>
          <ButtonWrapper onClick={() => sliderRef.current?.slickNext()}>
            <ArrowForwardIosIcon style={matches ? { fontSize: "12px", color: "#595959" } : { fontSize: "16px", color: "#595959" }} />
          </ButtonWrapper>
        </CarouselButtonWrapper>
      </HeadingWithLink>
      <SliderWrapper>
        <Slider ref={sliderRef} {...settings}>
          {repeatedProducts.map((item) => (
            <SingleProduct
              key={item.id}
              product={item}
              getUserWishlistItems={getUserWishlistItems}
              wishlistItems={wishlistItems}
              setWishlistItems={setWishlistItems}
            />
          ))}
        </Slider>
      </SliderWrapper>
    </WidthAdjuster>
  );

}
import React from "react";
import styled from "@emotion/styled";

const TermsItemWrapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: 20px auto;
`;
const ItemTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 38.73px;
  margin: 10px 0;
`;

const ItemDescription = styled.div`
  text-align: justify;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  width: 100%;
  color: #595959;
`;
function PolicyItem({ count, heading, description }) {
  return (
    <TermsItemWrapper>
      <ItemDescription>{description}</ItemDescription>
    </TermsItemWrapper>
  );
}

export default PolicyItem;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import OrderInfo from "./OrderInfo";
import { post } from "../../api_helper/api_helper";
import { useSelector } from "react-redux";

const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  padding: 40px;
`;

function Checkout({ currentTab, setCurrentTab }) {
  const [cart, setCart] = useState({});
  const user = useSelector((state) => state.general.user);
  const isBuyNowItemSelected = useSelector(
    (state) => state.general.isBuyNowItemSelected
  );
  const [coupon_code, setCouponCode] = useState("");

  async function getUserCartInfo(dontUseCoupon) {
    try {
      let cartInfo = {};
      if (isBuyNowItemSelected) {
        cartInfo = await post("/api/buy-now/details", {
          user_id: user?.id,
          ...((coupon_code && !dontUseCoupon) ? { coupon_code }:{}),
        });
      } else {
        cartInfo = await post("/api/cart", {
          user_id: user?.id,
          ...(coupon_code && { coupon_code }),
        });
      }

      setCart(cartInfo);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getUserCartInfo();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Heading></Heading>

      <OrderInfo
        getUserCartInfo={getUserCartInfo}
        setCouponCode={setCouponCode}
        coupon_code={coupon_code}
        cart={cart}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
    </>
  );
}

export default Checkout;

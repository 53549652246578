import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate here
import styled from "@emotion/styled";
import ChildIndividualCategory from "../../components/Category/ChildIndividualCategory";
import { Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Pagination from '@mui/material/Pagination';
import useMediaQuery from "@mui/material";
import Stack from '@mui/material/Stack';
import { useEffect } from "react";
const ViewEnd = styled.div`
  display: none;
  margin: 20px auto;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const CustomPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    color: #00B4D8;
    border-color: #00B4D8;
  }
  .MuiPaginationItem-root.Mui-selected {
    background-color: #00B4D8;
    color: white;
    width:36px;
    height:36px;
    border-radius:19px;
    font-size:16px;
  }
  .MuiPaginationItem-ellipsis {
    color: #00B4D8;
  }
  .MuiPaginationItem-icon {
    color: #00B4D8;
  }
`;


const PaginationInfo = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color:#7B7B7B;
`;

const GoBack = styled.span`
  font-weight: 500;
  font-size: 20px;
  font-family: "Inter";
  cursor: pointer;
`;

function ChildCategory() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);




  const location = useLocation();
  const navigate = useNavigate(); // Define navigate using useNavigate
  const [currentPage, setCurrentPage] = useState(1);
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const { selectedCategory, selectedSubCategory } = location.state || {};

  // Safely check if childCategories exist and filter them by selectedSubCategory.id
  const childCategories = selectedCategory?.childCategories?.filter(
    (child) => child?.sub_category?.id === selectedSubCategory?.id
  ) || [];
  const itemsPerPage = 12;


  const totalPages = Math.ceil(childCategories?.length / itemsPerPage);
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentCategory = childCategories?.slice(indexOfFirstProduct, indexOfLastProduct);
  console.log("Child categories:--", currentCategory)
  const WidthAdjuster = styled.div`
    width: 80%;
    margin: 10px auto;
    margin-top: 50px;
    max-width: 1525px;

    @media (max-width: 600px) {
      width: 97%;
    }
  `;

  const Heading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    line-height: 29.05px;
    margin: 20px auto;
    margin-bottom:60px;
    font-size: 48px;
    font-weight: 600;
    color: #3e4d50;

    @media (max-width: 600px) {
      font-size: 19px;
    }
  `;
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <WidthAdjuster>
      <ViewEnd onClick={handleBack}>
        <ArrowBackIcon />
        <GoBack>BACK</GoBack>
      </ViewEnd>
      <div style={{ marginTop: "0px", margin: "auto" }}>
        <Heading>{selectedSubCategory?.name || "Subcategory"}</Heading>
        {childCategories.length > 0 ? (
          <Grid container spacing={2} alignItems="center">
            {currentCategory.map((item) => (
              <Grid
                item
                xs={6}
                md={4}
                key={item.id}
                onClick={() => {
                  // Navigate to /products with the selected subcategory in the state
                  navigate("/products", { state: { selectedSubCategory: item } });
                }}
              >
                <ChildIndividualCategory name={item?.name} img={item?.image_url} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <p>No Categories available</p>
        )}
      </div>


      {childCategories?.length > 12 && (
        <div style={{ margin: "74px auto", display: "flex", alignItems: "center", justifyContent: "space-between", width: isMobile ? "80%" :"60%" }}>
          <PaginationInfo>
            {`Page ${currentPage} of ${totalPages}`}
          </PaginationInfo>
          <Stack spacing={2}>
            <CustomPagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      )}
    </WidthAdjuster>
  );
}

export default ChildCategory;

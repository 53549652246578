import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  showForgotPasswordModal,
  showForgotPasswordOtp,
  tempRegistrationData,
} from "../../store/reducer/reducer";
import styled from "@emotion/styled";
import OtpImage from "../../assets/images/getOtpImage.svg";
import CountryCodes from "../../assets/json/countrycode.json";

import "react-phone-input-2/lib/style.css";
import { Button } from "@mui/base";
import { post } from "../../api_helper/api_helper";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Autocomplete, CircularProgress, FormControl, Grid, TextField, Box, Typography, InputAdornment } from "@mui/material";
import CloseModal from "./CloseModal";
const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
  width: clamp(450px, 30%, 636px);
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

const ShowImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TexttoDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Heading = styled.div`
  font-weight: 600;
  font-size: 36px;
  font-family: "Inter";
  margin: 10px auto;
`;
const SubHeading = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: "Inter";
  margin: 10px auto;
  margin-bottom: 0px;
`;

const inputStyles = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '13px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'primary.main',
    },
  },
  '& .MuiInputBase-input': {
    padding: '16.5px 14px',
  },

};

const labelStyles = {
  display: 'block',
  marginBottom: '5px',
  fontFamily: 'Inter, sans-serif',
  fontSize: '21px',
  fontWeight: 500,
  color: "#5E6363",
};

function ForgotPasswordModal() {
  const displayModal = useSelector(
    (state) => state.general.showForgotPasswordModal
  );
  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const onSubmit = async (values) => {
    try {
      if (!values.phone_number) {
        toast.error("Phone number is required")
        return
      }
      if (!values.country_code) {
        toast.error("Country code is required")
        return
      }
      setLoading(true);

      await post("/api/auth/forgot-password", {
        mobilenumber: values.phone_number,
        country_code: values.country_code,
      });
      toast.success("OTP sent successfully!");
      setLoading(false);
      dispatch(showForgotPasswordModal(false));
      dispatch(showForgotPasswordOtp(true));
      dispatch(tempRegistrationData(values));
      formik.resetForm();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
    }
  };

  const formik = useFormik({
    initialValues: {
      country_code: "",
      phone_number: "",
    },
    onSubmit,
  });
  return (
    <>
      <Modal
        open={displayModal}
        onClose={() => {
          dispatch(showForgotPasswordModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper>
          <CloseModal closeFn={() => {
            dispatch(showForgotPasswordModal(false));
          }} />
          <ShowImage>
            <img
              alt="forgot password"
              src={OtpImage}
              style={{ width: "171px", height: "140px", margin: "40px auto" }}
            />
          </ShowImage>

          <TexttoDisplay>
            <Heading>Forgot Password</Heading>
            <SubHeading>Enter your registered mobile number</SubHeading>
          </TexttoDisplay>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Box sx={{ width: '100%', maxWidth: 500, margin: '13px auto' }}>
              <Typography
                component="label"
                htmlFor="phone-input"
                sx={{
                  display: 'block',
                  marginBottom: '5px',
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '21px',
                  fontWeight: 500,
                  color: '#5E6363',
                }}
              >
                Mobile Number
              </Typography>
              <TextField
                id="phone-input"
                fullWidth
                variant="outlined"
                sx={{
                  ...inputStyles,
                  '& .MuiOutlinedInput-root': {
                    ...inputStyles['& .MuiOutlinedInput-root'],
                    display: 'flex',
                    padding: 0,
                  },
                }}
                placeholder=""
                value={formik.values.phone_number} // Only show phone number
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                  if (value.length <= 10) {
                    formik.setFieldValue('phone_number', value); // Only set value if it's 10 digits or less
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Autocomplete
                        disablePortal
                        onChange={(_, value) => {
                          const dialCode = value?.dial_code || '';
                          formik.setFieldValue("country_code", dialCode);
                          // Optionally, you can update the phone number here if needed
                        }}
                        options={CountryCodes.map((item) => ({
                          ...item,
                          label: `${item.flag} ${item.dial_code}`,
                        }))}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            sx={{
                              width: '120px',
                              padding: '0px !important',
                              '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                              '& .MuiAutocomplete-input': { padding: '16.5px 14px' },
                              // Adding the border to the right of the country code
                              '& .MuiOutlinedInput-root': {
                                borderRight: '1px solid #ccc', // Adjust the color and width as needed
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                        sx={{
                          '& .MuiAutocomplete-endAdornment': { display: 'block' },
                          width: '130px',
                          zIndex: 10,
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                name="phone_number"
                error={Boolean(formik.errors.phone_number) || Boolean(formik.errors.country_code)}
                helperText={(formik.errors.phone_number || formik.errors.country_code) || ' '}
              />
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  fontFamily: "Inter",
                  fontWeight: 400,
                  fontSize: "20px",
                  height: "54px",
                  color: "white",
                  background: "#00B4D8",
                  borderRadius: "8px",
                  border: "none",
                  width: "224px",
                  margin: "20px auto",
                  cursor: "pointer"
                }}
                type="submit"
                disabled={!formik.isValid}
              >
                {loading ? <CircularProgress /> : `Verify`}
              </Button>
            </div>
          </form>
        </LoginFormWrapper>
      </Modal>
    </>
  );
}

export default ForgotPasswordModal;

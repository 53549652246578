import React from 'react'
import styled from '@emotion/styled'

const BoxWrapper = styled.div`
width: 80%;
margin: auto;
box-shadow: 0px 4px 35px 0px #25626F66;
background: #25626F;
border-radius: 16px;
min-height: 157px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
box-sizing: border-box;
padding: 50px;
`

const Title = styled.div`
font-family: "Inter";
font-size: 24px;
font-weight: 500;
line-height: 29.05px;


color: white;
text-align: center;

`
const Description = styled.div`
font-family: "Inter";
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: center;

color: white;
text-align: center;
margin: 10px auto;

`

function InfoBox() {
  return (
    <BoxWrapper>
        <Title>Welcome to Shipora</Title>
        <Description>
        At Shipora, we're thrilled to have you here. Whether you're a first-time visitor or a long-time supporter, we extend our warmest
welcome to you. Our commitment is to make your experience with us enjoyable, informative, and rewarding.
        </Description>
    </BoxWrapper>
  )
}

export default InfoBox
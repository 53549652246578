import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

function CloseModal({closeFn}){
    return (
        <div style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
            <CloseIcon onClick={closeFn} style={{cursor:"pointer"}} />

        </div>
    )
}
export default CloseModal
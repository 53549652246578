import React, { useState } from "react";
import styled from "@emotion/styled";
import { post } from "../../api_helper/api_helper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {formatDollars} from "../../helper/generalFunctions"

const CartBoxWidth = styled.div`
  padding: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #e3e3e3;
  margin: auto;
`;

const CouponHeading = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 15px;
`;
// const CouponInput = styled.input`
//   width: 80%;
//   height: 40px;
//   text-align: start;
//   border: none;
//   outline: none;
//   padding : 0 15px;
//   background: #f3f3f3;
//   color: #838484;
// `;

const CouponInput = styled.input`
  width: 80%;
  height: 44px;
  text-align: start;
  padding : 0 15px;
  border: none;
  outline: none;
  background: #f3f3f3;
  color: #838484;
  border-radius: 8px;
`;
// const ApplyBtn = styled.button`
//   outline: none;
//   border: none;
//   background: transparent;
//   color: #00b4d8;
//   font-family: "Inter";
//   font-size: 16px;
//   font-weight: 500;
//   line-height: 20px;
//   margin-left: 10px;
//   cursor: pointer;
// `;

const ApplyBtn = styled.button`
  outline: none;
  border: none;
  background: #00b4d8;
  color: white;
  border-radius: 8px;
  padding:10px 16px;

  font-family: "Inter";
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  margin-left: 10px;
  cursor: pointer;

  //     @media (max-width: 423px) {
  // font-size: 14px;
  //   margin-left: 3px;
  //   padding: 8px 5px;
  // }
`;

const Title = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #242626;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Detail = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #242626;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const TitleSmall = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #838484;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const DetailSmall = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #838484;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const ProductCounter = styled.span`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 10px;
`;
const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CheckoutBtn = styled.button`
  width: 100%;
  background: #00b4d8;
  color: white;
  border-radius: 8px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
`;
function CartDetailsBox({ getUserCartInfo, cart, coupon_code, setCouponCode }) {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.general.user);
  const [showRemove, setShowRemove] = useState(false);
  const grandShippingValue = useSelector(
    (state) => state.general.grandShippingValue
  );
  const selectedAddress = useSelector(
    (state) => state.general.selectedDeliveryAddress
  );
  const isBuyNowItemSelected = useSelector(
    (state) => state.general.isBuyNowItemSelected
  );

  const subTotal = cart?.subTotal || 0;
  const shipping = grandShippingValue || 0;
  const taxes = cart?.taxes || 0;
  const discount = cart?.discountAmount || 0;
  
  const GrandTotalValue = subTotal + shipping + taxes - discount;

  console.log("Info", cart)

  async function createOrder() {
    try {
      setLoading(true);
      let url = "";
      if (isBuyNowItemSelected) {
        const info = await post("/api/create-ecommerce-order/buy-now", {
          coupon_code,
          user_id: user.id,
          delivery_address_id: selectedAddress.id,
        });
        url = info.url;
      } else {
        const info = await post("/api/create-ecommerce-order", {
          coupon_code,
          user_id: user.id,
          delivery_address_id: selectedAddress.id,
        });
        url = info.url;
      }

      setLoading(false);
      window.location.href = url;
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error || "something went wrong");
      console.log(e);
    }
  }
  async function checkAndApplyCoupon() {
    try {
      if (showRemove) {
        setCouponCode("");
        setShowRemove(false);
        getUserCartInfo(true);
        return;
      }
      setLoading(true);
      await post("/api/get-coupon-by-name-shipment", {
        coupon_name: coupon_code,
        grandTotal:cart?.grandTotal||0,
        order_type:"ECOMMERCE"
      });

      setLoading(false);
      toast.success("Coupon applied!");
      getUserCartInfo();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error || "something went wrong");
      console.log(e);
    }
  }

  return (
    <CartBoxWidth>
      <CouponHeading>Coupon Code</CouponHeading>
      <BoxWrapper>
        <CouponInput
          placeholder="Enter Coupon Code"
          type="text"
          value={coupon_code}
          onChange={(e) => {
            setCouponCode(e.target.value);
          }}
        />

        <ApplyBtn disabled={loading} onClick={checkAndApplyCoupon}>
        {showRemove ? `REMOVE` : `Apply`}
        </ApplyBtn>
      </BoxWrapper>

      
      <div style={{ display: "flex", justifyContent: "right", width: "100%", marginTop: "14px", borderBottom: "1px dotted black", paddingBottom: "14px" }}>
        <span style={{ fontSize: "14px", color: "#006FFF", textDecoration: "underline", cursor: "pointer" }}>View promo codes</span>
      </div>
      <div style={{ marginTop: "20px" }}></div>

      <BoxWrapper style={{ marginBottom: "10px" }}>
        <TitleSmall>Product Total</TitleSmall>
        <DetailSmall>{formatDollars(cart?.subTotal)}</DetailSmall>
      </BoxWrapper>
      <BoxWrapper style={{ marginBottom: "10px" }}>
        <TitleSmall>Discount</TitleSmall>
        <DetailSmall>-{formatDollars(cart?.discountAmount)}</DetailSmall>
      </BoxWrapper>
      <BoxWrapper style={{ marginBottom: "10px" }}>
        <TitleSmall>Shipping Charges</TitleSmall>
        <DetailSmall>{formatDollars(grandShippingValue)}</DetailSmall>
      </BoxWrapper>
      <BoxWrapper style={{ marginBottom: "10px" }}>
        <TitleSmall>Taxes</TitleSmall>
        <DetailSmall>{formatDollars(cart?.taxes)}</DetailSmall>
      </BoxWrapper>
      <div
        style={{ border: "1px solid #AFAFAF", margin: "10px 0", width: "100%" }}
      ></div>
      <BoxWrapper>
        <div style={{ display: "flex" }}>
          <Title>Total</Title>{" "}
          <ProductCounter>
            {` ( ${cart?.cartItems?.length || 0} products )`}
          </ProductCounter>
        </div>
        <Detail>{formatDollars(GrandTotalValue)}</Detail>
      </BoxWrapper>
      <CheckoutBtn onClick={createOrder}>Checkout</CheckoutBtn>
    </CartBoxWidth>
  );
}

export default CartDetailsBox;

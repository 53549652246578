import React, { useRef } from "react";
import styled from "@emotion/styled";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import SingleProduct from "./SingleProduct";
import { useMediaQuery } from "@mui/material";
const WidthAdjuster = styled.div`
  width: 80%;
  margin: 71px auto;
  margin-top: 50px;
  max-width:1525px;
     @media (max-width: 600px) {
   width: 97%;
  }

`;
const HeadingWithLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const Heading = styled.div`
  font-family: "Inter";
  font-weight: 500;
  font-size: 24px;
  line-height: 29.05px;
  @media (max-width: 600px) {
    font-size: 19px;
  line-height: 24.05px;
  }
`;
const ButtonWrapper = styled.div`
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  
`;

const CarouselButtonWrapper = styled.div`
 display: flex;
  justify-content: space-between;
  align-items: center;
 
 width: 6%;

 
  @media (max-width: 600px) {
    width: 20% !important;

  }

    @media (max-width: 1800px) {
    width: 6%;

  }

   @media (max-width: 1271px) {
    width: 11%;

  }

  
   @media (max-width: 990px) {
    width: 15%;

  }

     @media (max-width: 400px) {
    width: 22% !important;

  }

      @media (max-width: 370px) {
    width: 25% !important;

  }
`;

const SliderWrapper = styled.div`
  margin-top: 40px; 
  margin:auto;
`;
function HomeFurnishing({ products }) {
    const matches = useMediaQuery('(max-width:600px)');

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {

    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      

      {
        breakpoint: 1479,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },

      {
        breakpoint: 1036,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },



      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      ]
  };
  return (
    <WidthAdjuster>
      <HeadingWithLink>
        <Heading>Home Furnishings</Heading>
        <CarouselButtonWrapper>
          <ButtonWrapper
            onClick={() => {
              previous();
            }}
          >
            <ArrowBackIosIcon style={matches?{fontSize: "12px", color: "#595959",paddingLeft : '10px' }:{ fontSize: "16px", color: "#595959",paddingLeft : '6px' }} />
          </ButtonWrapper>
          <ButtonWrapper
            onClick={() => {
              next();
            }}
          >
            <ArrowForwardIosIcon
              style={matches?{fontSize: "12px", color: "#595959" }:{ fontSize: "16px", color: "#595959" }}
            />
          </ButtonWrapper>
        </CarouselButtonWrapper>
      </HeadingWithLink>
      <SliderWrapper>
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {products.map(item=>{
            return <SingleProduct key={item.id} product={item} />
          })}
        </Slider>
      </SliderWrapper>
    </WidthAdjuster>
  );
}

export default HomeFurnishing;

import React, { useState } from "react";
import styled from "@emotion/styled";
import SearchIcon from "../../assets/images/search.svg";
import { useDispatch } from "react-redux";
import { searchTerm } from "../../store/reducer/reducer";

const InputField = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 40px;
  border: 1px solid #e3e3e3;
  outline: none;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  padding: 10px 16px 10px 16px;
  box-sizing: border-box;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
const SearchIconImg = styled.img`
  height: 32px;
  width: 32px;
`;
const InputWrapper = styled.div`
  position: relative;
  width: 40%;
  height: 40px;
  @media (max-width: 600px) {
    width: 50%;
  }
`;

function ProductInputBox() {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  return (
    <InputWrapper>
      <InputField
        type="text"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Search products..."
      />
      <div
        style={{
          position: "absolute",
          right: "4px",
          top: "4px",
          cursor: "pointer",
        }}
      >
        <SearchIconImg
          src={SearchIcon}
          onClick={() => {
            dispatch(searchTerm(searchText));
          }}
        />
      </div>
    </InputWrapper>
  );
}

export default ProductInputBox;

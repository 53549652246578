// import React, { useEffect, useState } from "react";
// import Modal from "@mui/material/Modal";
// import { useDispatch, useSelector } from "react-redux";
// import AirplanemodeActiveTwoToneIcon from "@mui/icons-material/AirplanemodeActiveTwoTone";
// import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
// import { useMediaQuery } from "@mui/material";

// import {
//   selectedProduct,
//   showSelectShipmentModal,
//   setModalSource,  // Import setModalSource action
// } from "../../store/reducer/reducer";
// import styled from "@emotion/styled";
// import { toast } from "react-toastify";
// import { post } from "../../api_helper/api_helper";
// import { CircularProgress, Grid } from "@mui/material";
// import CloseModal from "../Auth/CloseModal";
// import { formatDollars } from "../../helper/generalFunctions";
// import { Dialog, DialogContent, DialogActions, Typography, Button, Box, Divider, ToggleButton, ToggleButtonGroup } from '@mui/material';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
// import CheckIcon from '@mui/icons-material/Check';
// import { Snackbar, SnackbarContent } from '@mui/material';

// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
// import FlightIcon from '@mui/icons-material/Flight';


// const LoginFormWrapper = styled.div`
//   position: fixed;
//   top: 50%;
//   background: #d9d9d9;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   padding: 20px;
//   border-radius: 22px;
// width: clamp(127px, 90%, 539px);
//   // padding: 20px;
//   box-sizing: border-box;
//   background-color:white;
//   // @media (max-width: 800px) {
//   //   width: 90%;
//   // }

//     @media (max-width: 484px) {
//     width: 90%;
//     padding: 16px 20px;

//   }
// `;

// const ModalBtn = styled.div`
//   height: 51px;
//   background: #00b4d8;
//   color: white;
//   font-family: "Inter";
//   font-size: 20px;
//   margin: 20px auto;
//   cursor: pointer;
//   font-weight: 400;
//   line-height: 18px;
//   width: 70%;
//   border-radius: 44px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//       @media (max-width: 484px) {
//     height: 44px;
//         width: 67%;
//             font-size: 17px;
//                 margin: 12px auto 0 auto;

//   }

// `;

// const Heading = styled.div`
//   font-family: "Inter";
//   font-size: 20px;
//   line-height: 29.05px;
//   font-weight: 500;
//   margin-bottom: 20px;

//       @media (max-width: 484px) {
// font-size: 13px;

//   }
// `;

// const WishlistHeading = styled.div`
//   font-family: "Inter";
//   font-size: 20px;
//   line-height: 24px;
//   font-weight: 400;
//   color: red;  // Highlight with red to show it's from Wishlist
//   margin-bottom: 20px;
// `;

// const Card = styled.div`
//   background: white;
//   width: 167px;
//   height: 209;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   margin: 10px auto;
//   box-sizing: border-box;
//   border-radius: 4px;

//         @media (max-width: 484px) {
//     width: 128px;

//   }
// `;

// const CardPrice = styled.div`
//   font-family: "Inter";
//   font-size: 20px;
//   font-weight: 400;
//   line-height: 24.2px;
//   text-align: center;
//   margin: 10px;


//         @media (max-width: 484px) {
//     font-size: 18px;
//     margin: 5px;

//   }
// `;

// const CardBtn = styled.div`
//   width: 143px;
//   height: 43px;
//   border-radius: 20px;
//   text-align: center;
//   font-family: "Inter";
//   font-size: 20px;
//   font-weight: 400;
//   line-height: 24.2px;
//   margin: 10px auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;


//         @media (max-width: 484px) {
//     width: 98px;
//     height: 32px;
//     margin: 5px auto;
//         font-size: 13px;
//   }
// `;


// const CategoryImage = styled.img`
//   width: 128px;
//   height: 128px;
//   padding: 32px;
//   border-radius: 18px;
// border:1.5px solid rgb(227, 227, 227);

//   @media (max-width: 768px) {
//     width: 115px;
//     height: 115px;
//     padding: 40px;
//   }

//   @media (max-width: 600px) {
//     width: 100px;
//     height: 100px;
//     padding: 30px;
//   }

//     @media (max-width: 484px) {
//     width: 80px;
//     height: 80px;
//     padding: 30px;
//   }
// `;

// const CategoryWrapper = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   flex-direction: row;
//   margin-right: 22px;
//   margin-top: 22px;
//   box-sizing: border-box;

//   margin: 15px auto;

//   margin: 10px auto;

//   @media (max-width: 768px) {
//     width: 100%; /* Full width for tablet and smaller devices */
//     margin-right: 0; /* No extra margin on smaller devices */
//   }

//   @media (max-width: 600px) {
//     width: 100%; /* Slightly narrower for small devices */
//     margin: 10px auto;
//   }

//     @media (max-width: 484px) {
//     width: 100%; /* Slightly narrower for small devices */

//   }
// `;

// const PriceContainer = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   margin: 20px 0;
// `;

// const MRP = styled.div`
//   font-family: "Inter";
//   font-size: 15px;
//   font-weight: 500;
//   line-height: 24.2px;
//   margin-left: 7px;
//   text-decoration: line-through;
//   color: #999999;

//      @media (max-width: 600px) {
//      font-size: 17px;
//   }

//        @media (max-width: 484px) {
//      font-size: 10px;
//   }
// `;
// const SellingPrice = styled.div`
//   font-family: "Inter";
//   font-size: 20px;
//   font-weight: 500;
//   color: #3e4d50;
//   line-height: 38.73px;

//      @media (max-width: 600px) {
//      font-size: 20px;
//   }

//          @media (max-width: 484px) {
//      font-size: 11px;
//   }
// `;
// const Discount = styled.div`
//   color: #da4848;
//   font-family: "Inter";
//   font-size: 14px;
//   font-weight: 400;
//   line-height: 24.2px;
//   margin-left: 7px;

//      @media (max-width: 600px) {
//      font-size: 14px;
//   }

//        @media (max-width: 484px) {
//      font-size: 10px;
//        line-height:12px;
//   }
// `;

// const PriceWrapper = styled.div`

//   margin-left: 25px;

//      @media (max-width: 484px) {
//     margin-left: 14px;
//   }
// `;

// const CustomMessage = styled.div`

//  position: fixed;           /* Fixed position for staying in place even when scrolling */
//   top: 50%;               /* 50% from the bottom */
//   left: 50%;                 /* Center horizontally */
//   transform: translateX(-50%); /* Truly center it horizontally */
//   background-color: rgba(222, 250, 255, 1);   /* Success color (you can change this) */
//   color: rgba(2, 44, 53, 1);              /* Text color */
//   padding: 16px;             /* Padding around the text */
//   border-radius: 8px;        /* Rounded corners */
//   font-size: 20px;           /* Font size */
//   text-align: center;        /* Center the text */
//   z-index: 9999; 
//   }
// `;



// const MainFlex = styled.div``;

// function SelectTypeOfShipment() {
//   const displayModal = useSelector((state) => state.general.showSelectShipmentModal);
//   const modalSource = useSelector((state) => state.general.modalSource);  // Get modal source from store
//   const [selected, setSelected] = useState("AIR");
//   const dispatch = useDispatch();
//   const choosenProduct = useSelector((state) => state.general.selectedProduct);
//   const [shippingDetails, setShippingDetails] = useState({});
//   const [loading, setLoading] = useState(false);
//   const WODetails = choosenProduct?.WOLoginID;
//   const WOLoginProductDetails = choosenProduct?.WODetails;
//   const img = choosenProduct?.productDetails?.image_urls[0];
//   const WOLoginImage = choosenProduct?.WODetails?.image;
//   const WOLoginName = choosenProduct?.WODetails?.name;
//   const WOLoginMRP = choosenProduct?.WODetails?.MRP;
//   const WOLoginPrice = choosenProduct?.WODetails?.price;
//   const WOLoginDiscountedPrice = choosenProduct?.WODetails?.discountedPrice;
//   const WOLoginDiscountPercentage = choosenProduct?.WODetails?.discountPercentage;
//   const ProductName = choosenProduct?.productDetails?.name;
//   const [typeOfShipment, setTypeOfShipment] = useState("AIR");
//   // console.log("Chhoo",choosenProduct.WODetails.image) 
//   // const MRP = choosenProduct?.productDetails?.MRP;
//   // const DiscountedPrice=choosenProduct?.productDetails?.DiscountedPrice;
//   const [qty, setQty] = useState(1);
//   const quantity = Number(qty) || 0
//   const [showMessage, setShowMessage] = useState(false);
//   const [message, setMessage] = useState('');
//   const price = shippingDetails?.amountShip ? formatDollars(shippingDetails?.amountShip) : "--";
//   const parseCurrency = (value) => {
//     return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0; // Convert to number and return 0 if NaN
//   };

//   const priceValue = parseCurrency(price) || 0; // Use parseCurrency to get the numeric value
//   const ShippingPrice = formatDollars(priceValue * quantity);
//   console.log("Heloo SHip", price)
//   const ProducePrice = formatDollars(choosenProduct?.productDetails?.discountedPrice * quantity);
//   const TotalPrice = (priceValue * quantity) + (choosenProduct?.productDetails?.discountedPrice * quantity)
//   const WOLoginToPrice = (priceValue * quantity) + (WOLoginDiscountedPrice * quantity)


//   // Get shipping details when product is selected
//   // async function getShippingDetails() {
//   //   try {
//   //     const shippingDetails = await post("/api/ecom-calculate-shipping", {
//   //       productId: choosenProduct?.productId,
//   //       ...(choosenProduct?.productVariantId && {
//   //         productVariantId: choosenProduct.productVariantId,
//   //       }),
//   //     });

//   //     setShippingDetails(shippingDetails);
//   //   } catch (e) {
//   //     console.log(e);
//   //   }
//   // }
//   console.log("Details", WODetails)

//   async function getShippingDetails() {
//     try {
//       const shippingDetails = await post(
//         "/api/ecom-calculate-shipping",

//         {
//           productId: WODetails,
//           ...(WODetails?.productVariantId && {
//             productVariantId: WODetails.productVariantId,
//           }),
//         }
//       );

//       setShippingDetails(shippingDetails);
//     } catch (e) {
//       console.log(e);
//     }
//   }




//   console.log("Choosen Product", choosenProduct)

//   useEffect(() => {
//     if (choosenProduct) {
//       getShippingDetails();
//     }
//   }, [choosenProduct]);
//   const user = useSelector((state) => state.general.user);
//   // async function addItemToUserCart() {
//   //   try {
//   //     setLoading(true);
//   //     await post(`/api/cart/add-to-cart-or-increase-quantity`, {
//   //       productId: choosenProduct.productId,
//   //       shipment_type: selected,
//   //       ...(choosenProduct?.productVariantId && {
//   //         productVariantId: choosenProduct.productVariantId,
//   //       }),
//   //     });
//   //     const successMessage = `Product has been added to your cart.`; // Customize the message
//   //     setMessage(successMessage); // Set the success message
//   //     // setShowMessage(true);
//   //     toast.success(successMessage);
//   //     setLoading(false);
//   //     closeModal(); // Assuming you have a closeModal function to close the modal
//   //   } catch (e) {
//   //     setLoading(false);
//   //     toast.error(e?.response?.data?.message || "Something went wrong");
//   //     console.log(e);
//   //   }
//   // }

//   // async function addItemToUserCart() {
//   //   try {
//   //     setLoading(true);

//   //     if (user) {
//   //       // If user is logged in, add to cart via API
//   //       await post(`/api/cart/add-to-cart-or-increase-quantity`, {
//   //         productId: choosenProduct.productId,
//   //         shipment_type: selected,
//   //         ...(choosenProduct?.productVariantId && {
//   //           productVariantId: choosenProduct.productVariantId,
//   //         }),
//   //       });
//   //       const successMessage = `Product has been added to your cart.`;
//   //       toast.success(successMessage);
//   //     } else {
//   //       // If user is not logged in, add to cart in local storage
//   //       const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];

//   //       // Check if the product already exists in the guest cart
//   //       const existingItemIndex = guestCart.findIndex(
//   //         item => item.productId === choosenProduct.productId &&
//   //           item.productVariantId === choosenProduct.productVariantId
//   //       );

//   //       if (existingItemIndex > -1) {
//   //         // Increase quantity if the item is already in the guest cart
//   //         guestCart[existingItemIndex].quantity += 1;
//   //       } else {
//   //         // Add new item to the guest cart
//   //         guestCart.push({
//   //           productId: choosenProduct?.WODetails?.id,
//   //           productVariantId: choosenProduct.productVariantId,
//   //           shipment_type: selected,
//   //           image: choosenProduct?.WODetails?.image,
//   //           quantity: quantity,
//   //           discountedPrice: WOLoginDiscountedPrice,
//   //           MRP: WOLoginMRP,
//   //         });
//   //       }

//   //       // Save updated guest cart to local storage
//   //       localStorage.setItem("guestCart", JSON.stringify(guestCart));
//   //       const successMessage = `Product has been added to your cart as a guest.`;
//   //       toast.success(successMessage);
//   //     }

//   //     setLoading(false);
//   //     closeModal(); // Assuming you have a closeModal function to close the modal
//   //   } catch (e) {
//   //     setLoading(false);
//   //     toast.error(e?.response?.data?.message || "Something went wrong");
//   //     console.log(e);
//   //   }
//   // }


//   // // Close the message after 3 seconds
//   // setTimeout(() => {
//   //   setShowMessage(false);
//   // }, 3000);






//   async function addItemToUserCart() {
//     try {
//       setLoading(true);

//       // For guest users, manage the cart using localStorage
//       const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];

//       // Check if the product already exists in the guest cart
//       const existingItemIndex = guestCart.findIndex(
//         item => item.productId === choosenProduct.productId &&
//                item.productVariantId === choosenProduct.productVariantId
//       );

//       if (existingItemIndex > -1) {
//         // If item exists, increase its quantity
//         guestCart[existingItemIndex].quantity += 1;
//       } else {
//         // If item does not exist, add it to the guest cart
//         guestCart.push({
//           productId: choosenProduct?.WODetails?.id,
//           productVariantId: choosenProduct.productVariantId,
//           shipment_type: selected,
//           image: choosenProduct?.WODetails?.image,
//           quantity: quantity,
//           discountedPrice: WOLoginDiscountedPrice,
//           MRP: WOLoginMRP,
//         });
//       }

//       // Save the updated guest cart to localStorage
//       localStorage.setItem("guestCart", JSON.stringify(guestCart));

//       const successMessage = `Product has been added to your cart as a guest.`;
//       toast.success(successMessage);

//       setLoading(false);
//       closeModal(); // Assuming you have a closeModal function to close the modal

//       // Close the success message after 3 seconds
//       setTimeout(() => {
//         setShowMessage(false);
//       }, 3000);

//     } catch (e) {
//       setLoading(false);
//       toast.error(e?.response?.data?.message || "Something went wrong");
//       console.log(e);
//     }
//   }










//   // Add item to the cart and show the modal
//   // async function addItemToUserCart() {
//   //   try {
//   //     setLoading(true);
//   //     await post(`/api/cart/add-to-cart-or-increase-quantity`, {
//   //       productId: choosenProduct.productId,
//   //       shipment_type: selected,
//   //       ...(choosenProduct?.productVariantId && {
//   //         productVariantId: choosenProduct.productVariantId,
//   //       }),
//   //     });
//   //     toast.success(`${ProductName} Added to cart!`);
//   //     setLoading(false);
//   //     closeModal();
//   //   } catch (e) {
//   //     setLoading(false);
//   //     toast.error(e?.response?.data?.message || "something went wrong");
//   //     console.log(e);
//   //   }
//   // }



//   // async function addItemToUserCart() {
//   //   try {
//   //     setLoading(true);
//   //     await post("/api/cart/add-to-cart-or-increase-quantity", {
//   //       productId: choosenProduct.productId,
//   //       shipment_type: selected,
//   //       quantity: qty,  // Pass the quantity from Wishlist
//   //       ...(choosenProduct?.productVariantId && {
//   //         productVariantId: choosenProduct.productVariantId,
//   //       }),
//   //     });
//   //     toast.success("Added to cart!");
//   //     setLoading(false);
//   //     closeModal();
//   //   } catch (e) {
//   //     setLoading(false);
//   //     toast.error(e?.response?.data?.message || "something went wrong");
//   //     console.log(e);
//   //   }
//   // }

//   // async function addItemToUserCart(qty) {  // Accept qty as an argument
//   //   try {
//   //     setLoading(true);

//   //     const payload = {
//   //       productId: choosenProduct.productId,
//   //       shipment_type: selected,
//   //       quantity: qty, // Use the passed qty here
//   //       ...(choosenProduct?.productVariantId && {
//   //         productVariantId: choosenProduct.productVariantId,
//   //       }),
//   //     };

//   //     console.log("Adding to cart with payload:", payload); // Log the payload to check if quantity is correct

//   //     await post("/api/cart/add-to-cart-or-increase-quantity", payload);
//   //     toast.success("Added to cart!");
//   //     setLoading(false);
//   //     closeModal();
//   //   } catch (e) {
//   //     setLoading(false);
//   //     toast.error(e?.response?.data?.message || "something went wrong");
//   //     console.log(e);
//   //   }
//   // }



//   function closeModal() {
//     dispatch(selectedProduct(null));
//     dispatch(showSelectShipmentModal(false));
//   }

//   // Dispatch action to set modal source as 'wishlist' if opened from wishlist
//   useEffect(() => {
//     const modalSource = localStorage.getItem("modalSource");
//     if (modalSource === "wishlist") {
//       dispatch(setModalSource("wishlist"));
//     }
//   }, [dispatch]);

//   // console.log("Print", choosenProduct?.productDetails)

//   const calculateDiscountPercentage = () => {
//     if (choosenProduct?.productDetails?.discountedPrice && choosenProduct?.productDetails?.MRP) {
//       const discountedPrice = parseFloat(choosenProduct?.productDetails?.discountedPrice);
//       const mrp = parseFloat(choosenProduct?.productDetails?.MRP);
//       const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
//       return Math.round(discountPercentage);
//     }
//     return 0;
//   };



//   return (
//     <>





//       <Modal
//         open={displayModal}
//         onClose={closeModal}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         {modalSource === "wishlist" ? (


//           <>


//             <LoginFormWrapper>

//               <CloseModal closeFn={closeModal} />



//               <CategoryWrapper>
//                 <CategoryImage


//                   src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${WOLoginImage}`}
//                 />

//                 <PriceWrapper>

//                   <Heading>{ProductName || WOLoginName}</Heading>

//                   <PriceContainer>

//                     <SellingPrice>

//                       {formatDollars(WOLoginDiscountedPrice)}

//                     </SellingPrice>
//                     <MRP>{formatDollars(WOLoginMRP)}</MRP>
//                     <Discount>-{WOLoginDiscountPercentage}% OFF</Discount>


//                   </PriceContainer>

//                 </PriceWrapper>

//               </CategoryWrapper>



//               <div style={{ display: 'flex', flexDirection: "column" }}>

//                 <div style={{ display: 'flex', marginTop: '7px' }}>
//                   <Box
//                     sx={{
//                       // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
//                       border: "1.5px solid rgb(227, 227, 227)",
//                       borderRadius: '4px',
//                       padding: '0px',
//                       display: 'inline-block',
//                       width: 'fit-content',
//                     }}
//                   >
//                     <Grid container alignItems="center">
//                       <Grid item xs alignItems="center" justifyContent="center" style={{ padding: "8px 8px" }} onClick={() => {
//                         if (qty > 1) {
//                           setQty((prev) => prev - 1);
//                         }

//                       }}>
//                         <RemoveOutlinedIcon style={{ color: "black", cursor: "pointer", marginTop: "5px" }} />
//                       </Grid>
//                       <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

//                       <Grid item xs style={{ color: "black", fontSize: "19px", fontWeight: "500", padding: "8px 8px" }}>
//                         {qty}
//                       </Grid>
//                       <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

//                       <Grid item style={{ padding: "8px 8px" }} xs onClick={() => {
//                         setQty((prev) => prev + 1);
//                       }}>
//                         <AddOutlinedIcon style={{ color: "black", cursor: "pointer", marginTop: "5px" }} />
//                       </Grid>

//                     </Grid>
//                   </Box>
//                 </div>




//                 <div style={{ display: 'flex', marginTop: '18px' }}>
//                   <Box
//                     sx={{
//                       // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)', // Box shadow instead of border
//                       border: "1.5px solid rgb(227, 227, 227)",
//                       borderRadius: '8px',
//                       padding: '12px 0px',
//                       display: 'inline-block',
//                       width: 'fit-content',

//                       '@media (max-width: 430px)': {
//                         padding: '7px 0px', // Adjust padding when screen width is 430px or less
//                       },
//                     }}
//                   >
//                     <Grid container alignItems="center">
//                       <Grid item xs style={{ padding: "0px 10px" }}>
//                         <Typography variant="body2" align="center" color="textSecondary" sx={{
//                           fontSize: '16px',
//                           fontWeight: '500',
//                           color: '#424545',
//                           '@media (max-width: 430px)': {
//                             fontSize: '13px', // Apply font-size 11px on max-width 430px
//                           },
//                         }}>
//                           Width
//                         </Typography>
//                         <Typography variant="h6" align="center" sx={{
//                           fontSize: '15px',
//                           fontWeight: '500',
//                           color: '#838484',
//                           '@media (max-width: 430px)': {
//                             fontSize: '11px', // Apply font-size 11px on max-width 430px
//                           },
//                         }}>
//                           {choosenProduct?.productDetails?.width || WOLoginProductDetails?.width}(CM)
//                         </Typography>
//                       </Grid>
//                       <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

//                       <Grid item xs style={{ padding: "0px 10px" }}>
//                         <Typography variant="body2" align="center" color="textSecondary" sx={{
//                           fontSize: '16px',
//                           fontWeight: '500',
//                           color: '#424545',
//                           '@media (max-width: 430px)': {
//                             fontSize: '13px', // Apply font-size 11px on max-width 430px
//                           },
//                         }}>
//                           Height
//                         </Typography>
//                         <Typography variant="h6" align="center" sx={{
//                           fontSize: '15px',
//                           fontWeight: '500',
//                           color: '#838484',
//                           '@media (max-width: 430px)': {
//                             fontSize: '11px', // Apply font-size 11px on max-width 430px
//                           },
//                         }}>
//                           {choosenProduct?.productDetails?.height || WOLoginProductDetails?.height}(CM)
//                         </Typography>
//                       </Grid>
//                       <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

//                       <Grid item xs style={{ padding: "0px 10px" }}>
//                         <Typography variant="body2" align="center" color="textSecondary" sx={{
//                           fontSize: '16px',
//                           fontWeight: '500',
//                           color: '#424545',
//                           '@media (max-width: 430px)': {
//                             fontSize: '13px', // Apply font-size 11px on max-width 430px
//                           },
//                         }}>
//                           Length
//                         </Typography>
//                         <Typography variant="h6" align="center" sx={{
//                           fontSize: '15px',
//                           fontWeight: '500',
//                           color: '#838484',
//                           '@media (max-width: 430px)': {
//                             fontSize: '11px', // Apply font-size 11px on max-width 430px
//                           },
//                         }}>
//                           {choosenProduct?.productDetails?.length || WOLoginProductDetails?.length}(CM)
//                         </Typography>
//                       </Grid>
//                       <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

//                       <Grid item xs style={{ padding: "0px 10px" }}>
//                         <Typography variant="body2" align="center" color="textSecondary" sx={{
//                           fontSize: '16px',
//                           fontWeight: '500',
//                           color: '#424545',
//                           '@media (max-width: 430px)': {
//                             fontSize: '13px', // Apply font-size 11px on max-width 430px
//                           },
//                         }}>
//                           Weight
//                         </Typography>
//                         <Typography variant="h6" align="center" sx={{
//                           fontSize: '15px',
//                           fontWeight: '500',
//                           color: '#838484',
//                           '@media (max-width: 430px)': {
//                             fontSize: '11px', // Apply font-size 11px on max-width 430px
//                           },
//                         }}>
//                           {choosenProduct?.productDetails?.weight || WOLoginProductDetails?.weight}(KG)
//                         </Typography>
//                       </Grid>
//                     </Grid>
//                   </Box>
//                 </div>
//               </div>





//               <Grid container spacing={2} >
//                 <Grid item alignItems={"center"} justifyContent={"center"}>
//                   <DisplayShipmentCard
//                     iconName="Airplane"
//                     price={shippingDetails?.amountAir ? formatDollars(shippingDetails?.amountAir) : "--"}
//                     // selected={selected}
//                     selected={typeOfShipment}
//                     icon="AIR"
//                     name={"AIR"}
//                     duration={shippingDetails?.durationAir}
//                     // setSelected={setSelected}
//                     setSelected={setTypeOfShipment}
//                     quantity={quantity}
//                     priceValue={priceValue}
//                     ShippingPrice={ShippingPrice}
//                   />

//                 </Grid>
//                 <Grid item alignItems={"center"} justifyContent={"center"}>
//                   <DisplayShipmentCard
//                     iconName="Ship"
//                     price={shippingDetails?.amountShip ? formatDollars(shippingDetails?.amountShip) : "--"}
//                     selected={typeOfShipment}
//                     icon="SHIP"
//                     name={"SHIP"}
//                     duration={shippingDetails?.durationShip}
//                     setSelected={setTypeOfShipment}
//                     quantity={quantity}
//                     priceValue={priceValue}
//                     ShippingPrice={ShippingPrice}
//                   />
//                 </Grid>


//               </Grid>

//               <div style={{ display: 'flex', marginTop: '20px' }}>
//                 <Box
//                   sx={{
//                     // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)', // Box shadow instead of border
//                     border: "1.5px solid rgb(227, 227, 227)",
//                     borderRadius: '8px',
//                     padding: '10px 3px',
//                     display: 'inline-block',
//                     width: '344px',
//                     '@media (max-width: 430px)': {
//                       padding: '20px 0px', // Adjust padding when screen width is 430px or less
//                     },

//                     '@media (max-width: 484px)': {
//                       padding: '7px 0px', // Adjust padding when screen width is 430px or less
//                     },

//                     '@media (max-width: 430px)': {
//                       width: 'fit-content',
//                     },

//                   }}
//                 >
//                   <Grid container alignItems="center">
//                     <Grid item xs style={{ padding: "0px 0px" }}>
//                       <Typography variant="body2" align="center" color="textSecondary" sx={{
//                         fontSize: '16px',
//                         fontWeight: '500',
//                         color: '#424545',
//                         '@media (max-width: 430px)': {
//                           fontSize: '13px', // Apply font-size 11px on max-width 430px
//                         },
//                       }}>
//                         Produce   Price
//                       </Typography>
//                       <Typography variant="h6" align="center" sx={{
//                         fontSize: '15px',
//                         fontWeight: '500',
//                         color: '#838484',
//                         '@media (max-width: 430px)': {
//                           fontSize: '11px', // Apply font-size 11px on max-width 430px
//                         },
//                       }}>
//                         {/* {formatDollars(discountedPriceValue * quantity)} */}
//                         {formatDollars(WOLoginDiscountedPrice)}
//                       </Typography>
//                     </Grid>
//                     <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

//                     <Grid item xs style={{ padding: "0px 0px" }}>
//                       <Typography variant="body2" align="center" color="textSecondary" sx={{
//                         fontSize: '16px',
//                         fontWeight: '500',
//                         color: '#424545',
//                         '@media (max-width: 430px)': {
//                           fontSize: '13px', // Apply font-size 11px on max-width 430px
//                         },
//                       }}>
//                         {typeOfShipment === "AIR" ? "Airplane Price" : "Ship Price"}
//                       </Typography>
//                       <Typography variant="h6" align="center" sx={{
//                         fontSize: '15px',
//                         fontWeight: '500',
//                         color: '#838484',
//                         '@media (max-width: 430px)': {
//                           fontSize: '11px', // Apply font-size 11px on max-width 430px
//                         },
//                       }}>
//                         {ShippingPrice}
//                       </Typography>
//                     </Grid>
//                     <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

//                     <Grid item xs style={{ padding: "0px 0px" }}>
//                       <Typography variant="body2" align="center" color="textSecondary" sx={{
//                         fontSize: '16px',
//                         fontWeight: '500',
//                         color: '#424545',
//                         '@media (max-width: 430px)': {
//                           fontSize: '13px', // Apply font-size 11px on max-width 430px
//                         },
//                       }}>
//                         Total Price
//                       </Typography>
//                       <Typography variant="h6" align="center" sx={{
//                         fontSize: '15px',
//                         fontWeight: '500',
//                         color: '#838484',
//                         '@media (max-width: 430px)': {
//                           fontSize: '11px', // Apply font-size 11px on max-width 430px
//                         },
//                       }}>
//                         {formatDollars(WOLoginToPrice)}
//                       </Typography>
//                     </Grid>


//                   </Grid>
//                 </Box>
//               </div>

//               <ModalBtn onClick={() => { addItemToUserCart(); }}>
//                 {loading ? <CircularProgress /> : `Add to cart`}
//               </ModalBtn>

//             </LoginFormWrapper>
//           </>

//         ) : (
//           // Regular UI for Non-Wishlist
//           <LoginFormWrapper>
//             <CloseModal closeFn={closeModal} />

//             <Heading>Select Type of Shipment</Heading>



//             <Grid container spacing={2} justifyContent={"center"}>
//               <Grid item alignItems={"center"} justifyContent={"center"}>
//                 <DisplayShipmentCard
//                   iconName="Airplane"
//                   price={price}
//                   selected={selected}
//                   icon="AIR"
//                   name={"AIR"}
//                   duration={shippingDetails?.durationAir}
//                   setSelected={setSelected}
//                   quantity={quantity}
//                   priceValue={priceValue}
//                   ShippingPrice={ShippingPrice}
//                 />
//               </Grid>


//               <Grid item alignItems={"center"} justifyContent={"center"}>
//                 <DisplayShipmentCard
//                   iconName="Ship"
//                   price={price}
//                   selected={selected}
//                   icon="SHIP"
//                   name={"SHIP"}
//                   duration={shippingDetails?.durationShip}
//                   setSelected={setSelected}
//                   quantity={quantity}
//                   priceValue={priceValue}
//                   ShippingPrice={ShippingPrice}
//                 />
//               </Grid>
//             </Grid>

//             <ModalBtn onClick={() => { addItemToUserCart(qty); }}>
//               {loading ? <CircularProgress /> : `Submit`}
//             </ModalBtn>
//           </LoginFormWrapper>
//         )}
//       </Modal>
//     </>


//   );
// }

// // Shipment card component for displaying different shipment options
// function DisplayShipmentCard({
//   iconName,
//   icon,
//   name,
//   duration,
//   selected,
//   setSelected,
//   price,
//   quantity,
//   priceValue,
//   ShippingPrice,
// }) {
//   const Icon =
//     icon === "AIR"
//       ? AirplanemodeActiveTwoToneIcon
//       : DirectionsBoatFilledOutlinedIcon;
//   const cardColor = selected === name ? `#03BEE7` : `#838484`;
//   const textColor = selected === name ? `#242626` : `#838484`;
//   const btnTextColor = selected === name ? `white` : `#838484`;
//   const btnBackgroundColor = selected === name ? `#03BEE7` : `#E3E3E3`;
//   console.log("Hello", priceValue)

//   const isMobile = useMediaQuery("(max-width:484px)"); // Media query for mobile devices
//   return (
//     <Card
//       style={{
//         border:
//           selected === name ? `1.5px solid #03BEE7` : "1.5px solid #E3E3E3",
//       }}
//       onClick={() => {
//         setSelected(name);
//       }}
//     >
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           padding: "7px",
//         }}
//       >
//         <Icon
//           style={{
//             color: cardColor,
//             fontFamily: "Inter",
//             fontSize: isMobile ? "17px" : "30px", // Adjust font size based on screen width
//             fontWeight: 500,
//             lineHeight: "24.2px",
//           }}
//         />
//         <div
//           style={{
//             color: cardColor,
//             marginLeft: "10px",
//             fontFamily: "Inter",
//             fontSize: isMobile ? "17px" : "20px", // Adjust font size based on screen width
//             fontWeight: 500,
//             lineHeight: "24.2px",
//           }}
//         >
//           {iconName}
//         </div>
//       </div>

//       <CardPrice style={{ color: textColor }}>{ShippingPrice}</CardPrice>

//       <CardBtn style={{ color: btnTextColor, background: btnBackgroundColor }}>
//         {duration}
//       </CardBtn>
//     </Card>
//   );
// }

// export default SelectTypeOfShipment;
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import AirplanemodeActiveTwoToneIcon from "@mui/icons-material/AirplanemodeActiveTwoTone";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import { useMediaQuery } from "@mui/material";

import {
  selectedProduct,
  showSelectShipmentModal,
  setModalSource,  // Import setModalSource action
} from "../../store/reducer/reducer";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { post } from "../../api_helper/api_helper";
import { CircularProgress, Grid } from "@mui/material";
import CloseModal from "../Auth/CloseModal";
import { formatDollars } from "../../helper/generalFunctions";
import { Dialog, DialogContent, DialogActions, Typography, Button, Box, Divider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { Snackbar, SnackbarContent } from '@mui/material';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FlightIcon from '@mui/icons-material/Flight';


const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  background: #d9d9d9;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 22px;
width: clamp(127px, 90%, 539px);
  // padding: 20px;
  box-sizing: border-box;
  background-color:white;
  // @media (max-width: 800px) {
  //   width: 90%;
  // }

    @media (max-width: 484px) {
    width: 90%;
    padding: 16px 20px;
    
  }
`;

const ModalBtn = styled.div`
  height: 51px;
  background: #00b4d8;
  color: white;
  font-family: "Inter";
  font-size: 20px;
  margin: 20px auto;
  cursor: pointer;
  font-weight: 400;
  line-height: 18px;
  width: 70%;
  border-radius: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
      @media (max-width: 484px) {
    height: 44px;
        width: 67%;
            font-size: 17px;
                margin: 12px auto 0 auto;
    
  }

`;

const Heading = styled.div`
  font-family: "Inter";
  font-size: 20px;
  line-height: 29.05px;
  font-weight: 500;
  margin-bottom: 20px;

      @media (max-width: 484px) {
font-size: 13px;
    
  }
`;

const WishlistHeading = styled.div`
  font-family: "Inter";
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: red;  // Highlight with red to show it's from Wishlist
  margin-bottom: 20px;
`;

const Card = styled.div`
  background: white;
  width: 167px;
  height: 209;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px auto;
  box-sizing: border-box;
  border-radius: 4px;

        @media (max-width: 484px) {
    width: 128px;
    
  }
`;

const CardPrice = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: center;
  margin: 10px;

  
        @media (max-width: 484px) {
    font-size: 18px;
    margin: 5px;
    
  }
`;

const CardBtn = styled.div`
  width: 143px;
  height: 43px;
  border-radius: 20px;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;

    
        @media (max-width: 484px) {
    width: 98px;
    height: 32px;
    margin: 5px auto;
        font-size: 13px;
  }
`;


const CategoryImage = styled.img`
  width: 128px;
  height: 128px;
  padding: 32px;
  border-radius: 18px;
border:1.5px solid rgb(227, 227, 227);

  @media (max-width: 768px) {
    width: 115px;
    height: 115px;
    padding: 40px;
  }

  @media (max-width: 600px) {
    width: 100px;
    height: 100px;
    padding: 30px;
  }

    @media (max-width: 484px) {
    width: 80px;
    height: 80px;
    padding: 30px;
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-right: 22px;
  margin-top: 22px;
  box-sizing: border-box;

  margin: 15px auto;

  margin: 10px auto;
  
  @media (max-width: 768px) {
    width: 100%; /* Full width for tablet and smaller devices */
    margin-right: 0; /* No extra margin on smaller devices */
  }

  @media (max-width: 600px) {
    width: 100%; /* Slightly narrower for small devices */
    margin: 10px auto;
  }

    @media (max-width: 484px) {
    width: 100%; /* Slightly narrower for small devices */

  }
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
`;

const MRP = styled.div`
  font-family: "Inter";
  font-size: 15px;
  font-weight: 500;
  line-height: 24.2px;
  margin-left: 7px;
  text-decoration: line-through;
  color: #999999;

     @media (max-width: 600px) {
     font-size: 17px;
  }

       @media (max-width: 484px) {
     font-size: 10px;
  }
`;
const SellingPrice = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  color: #3e4d50;
  line-height: 38.73px;

     @media (max-width: 600px) {
     font-size: 20px;
  }

         @media (max-width: 484px) {
     font-size: 11px;
  }
`;
const Discount = styled.div`
  color: #da4848;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 24.2px;
  margin-left: 7px;

     @media (max-width: 600px) {
     font-size: 14px;
  }

       @media (max-width: 484px) {
     font-size: 10px;
       line-height:12px;
  }
`;

const PriceWrapper = styled.div`
  
  margin-left: 25px;

     @media (max-width: 484px) {
    margin-left: 14px;
  }
`;

const CustomMessage = styled.div`
  
 position: fixed;           /* Fixed position for staying in place even when scrolling */
  top: 50%;               /* 50% from the bottom */
  left: 50%;                 /* Center horizontally */
  transform: translateX(-50%); /* Truly center it horizontally */
  background-color: rgba(222, 250, 255, 1);   /* Success color (you can change this) */
  color: rgba(2, 44, 53, 1);              /* Text color */
  padding: 16px;             /* Padding around the text */
  border-radius: 8px;        /* Rounded corners */
  font-size: 20px;           /* Font size */
  text-align: center;        /* Center the text */
  z-index: 9999; 
  }
`;



const MainFlex = styled.div``;

function SelectTypeOfShipment() {
  const displayModal = useSelector((state) => state.general.showSelectShipmentModal);
  const modalSource = useSelector((state) => state.general.modalSource);  // Get modal source from store
  const [selected, setSelected] = useState("AIR");
  const dispatch = useDispatch();
  const choosenProduct = useSelector((state) => state.general.selectedProduct);
  const [shippingDetails, setShippingDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const WODetails = choosenProduct?.WOLoginID;
  const WOLoginProductDetails = choosenProduct?.WODetails;
  const img = choosenProduct?.productDetails?.image_urls[0];
  const WOLoginImage = choosenProduct?.WODetails?.image;
  const WOLoginName = choosenProduct?.WODetails?.name;
  const WOLoginMRP = choosenProduct?.WODetails?.MRP;
  const WOLoginPrice = choosenProduct?.WODetails?.price;
  const WOLoginDiscountedPrice = choosenProduct?.WODetails?.discountedPrice;
  const WOLoginDiscountPercentage = choosenProduct?.WODetails?.discountPercentage;
  const ProductName = choosenProduct?.productDetails?.name;
  const [typeOfShipment, setTypeOfShipment] = useState("AIR");
  // console.log("Chhoo",choosenProduct.WODetails.image) 
  // const MRP = choosenProduct?.productDetails?.MRP;
  // const DiscountedPrice=choosenProduct?.productDetails?.DiscountedPrice;
  const [qty, setQty] = useState(1);
  const quantity = Number(qty) || 0
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const price = shippingDetails?.amountShip ? formatDollars(shippingDetails?.amountShip) : "--";
  const parseCurrency = (value) => {
    return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0; // Convert to number and return 0 if NaN
  };

  const priceValue = parseCurrency(price) || 0; // Use parseCurrency to get the numeric value
  const ShippingPrice = formatDollars(priceValue * quantity);
  // console.log("Heloo SHip", ShippingPrice)
  const ProducePrice = formatDollars(choosenProduct?.productDetails?.discountedPrice * quantity);
  const TotalPrice = (priceValue * quantity) + (choosenProduct?.productDetails?.discountedPrice * quantity)
  const WOLoginToPrice = (priceValue * quantity) + (WOLoginDiscountedPrice * quantity)


  // Get shipping details when product is selected
  // async function getShippingDetails() {
  //   try {
  //     const shippingDetails = await post("/api/ecom-calculate-shipping", {
  //       productId: choosenProduct?.productId,
  //       ...(choosenProduct?.productVariantId && {
  //         productVariantId: choosenProduct.productVariantId,
  //       }),
  //     });

  //     setShippingDetails(shippingDetails);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  console.log("Details", choosenProduct)
  async function getShippingDetails() {
    try {
      const shippingDetails = await post(
        "/api/ecom-calculate-shipping",
        user
          ? {
            productId: choosenProduct?.productId,
            ...(choosenProduct?.productVariantId && {
              productVariantId: choosenProduct.productVariantId,
            }),
          }
          : {
            productId: WODetails,
            ...(WODetails?.productVariantId && {
              productVariantId: WODetails.productVariantId,
            }),
          }
      );

      setShippingDetails(shippingDetails);
    } catch (e) {
      console.log(e);
    }
  }




  // console.log("Choosen Product", choosenProduct)

  useEffect(() => {
    if (choosenProduct) {
      getShippingDetails();
    }
  }, [choosenProduct]);
  const user = useSelector((state) => state.general.user);
  // async function addItemToUserCart() {
  //   try {
  //     setLoading(true);
  //     await post(`/api/cart/add-to-cart-or-increase-quantity`, {
  //       productId: choosenProduct.productId,
  //       shipment_type: selected,
  //       ...(choosenProduct?.productVariantId && {
  //         productVariantId: choosenProduct.productVariantId,
  //       }),
  //     });
  //     const successMessage = `Product has been added to your cart.`; // Customize the message
  //     setMessage(successMessage); // Set the success message
  //     // setShowMessage(true);
  //     toast.success(successMessage);
  //     setLoading(false);
  //     closeModal(); // Assuming you have a closeModal function to close the modal
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e?.response?.data?.message || "Something went wrong");
  //     console.log(e);
  //   }
  // }
  // console.log("Choosen Product",choosenProduct)

console.log("Details",choosenProduct)

  async function addItemToUserCart() {
    try {
      setLoading(true);

      if (user) {
        // If user is logged in, add to cart via API
        await post(`/api/cart/add-to-cart-or-increase-quantity`, {
          productId: choosenProduct.productId,
          shipment_type: selected,
          quantity:qty,
          fromDetailsPage:true,
          ...(choosenProduct?.productVariantId && {
            productVariantId: choosenProduct.productVariantId,
          }),
        });
        const successMessage = `Product has been added to your cart.`;
        toast.success(successMessage);
      } else {
        // If user is not logged in, add to cart in local storage
        const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];

        // Check if the product already exists in the guest cart
        const existingItemIndex = guestCart.findIndex(
          item => item.productId === choosenProduct.productId &&
            item.productVariantId === choosenProduct.productVariantId
        );

        if (existingItemIndex > -1) {
          // Increase quantity if the item is already in the guest cart
          guestCart[existingItemIndex].quantity += 1;
        } else {
          // Add new item to the guest cart
          guestCart.push({
            productId: choosenProduct?.WODetails?.id,
            productVariantId: choosenProduct.productVariantId,
            shipment_type: selected,
            image: choosenProduct?.WODetails?.image,
            quantity: quantity,
            discountedPrice: WOLoginDiscountedPrice,
            MRP: WOLoginMRP,
            name:choosenProduct?.WODetails?.name,
            width:choosenProduct?.WODetails?.width,
            height:choosenProduct?.WODetails?.height,
            length:choosenProduct?.WODetails?.length,
            weight:choosenProduct?.WODetails?.weight,
            ShippingPrice:priceValue * quantity,
            AirDuration:shippingDetails?.durationAir,
            ShipDuration:shippingDetails?.durationShip,
            TotalPriceCart:WOLoginToPrice,
            CartId:choosenProduct?.WOLoginID,

          });
        }

      

        // Save updated guest cart to local storage
        localStorage.setItem("guestCart", JSON.stringify(guestCart));
        const successMessage = `Product has been added to your cart as a guest.`;
        toast.success(successMessage);
      }

      setLoading(false);
      closeModal(); // Assuming you have a closeModal function to close the modal
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "Something went wrong");
      console.log(e);
    }
  }

  // console.log("Local Storage Products", WODetails)

  // Close the message after 3 seconds
  setTimeout(() => {
    setShowMessage(false);
  }, 3000);

  // Add item to the cart and show the modal
  // async function addItemToUserCart() {
  //   try {
  //     setLoading(true);
  //     await post(`/api/cart/add-to-cart-or-increase-quantity`, {
  //       productId: choosenProduct.productId,
  //       shipment_type: selected,
  //       ...(choosenProduct?.productVariantId && {
  //         productVariantId: choosenProduct.productVariantId,
  //       }),
  //     });
  //     toast.success(`${ProductName} Added to cart!`);
  //     setLoading(false);
  //     closeModal();
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e?.response?.data?.message || "something went wrong");
  //     console.log(e);
  //   }
  // }



  // async function addItemToUserCart() {
  //   try {
  //     setLoading(true);
  //     await post("/api/cart/add-to-cart-or-increase-quantity", {
  //       productId: choosenProduct.productId,
  //       shipment_type: selected,
  //       quantity: qty,  // Pass the quantity from Wishlist
  //       ...(choosenProduct?.productVariantId && {
  //         productVariantId: choosenProduct.productVariantId,
  //       }),
  //     });
  //     toast.success("Added to cart!");
  //     setLoading(false);
  //     closeModal();
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e?.response?.data?.message || "something went wrong");
  //     console.log(e);
  //   }
  // }

  // async function addItemToUserCart(qty) {  // Accept qty as an argument
  //   try {
  //     setLoading(true);

  //     const payload = {
  //       productId: choosenProduct.productId,
  //       shipment_type: selected,
  //       quantity: qty, // Use the passed qty here
  //       ...(choosenProduct?.productVariantId && {
  //         productVariantId: choosenProduct.productVariantId,
  //       }),
  //     };

  //     console.log("Adding to cart with payload:", payload); // Log the payload to check if quantity is correct

  //     await post("/api/cart/add-to-cart-or-increase-quantity", payload);
  //     toast.success("Added to cart!");
  //     setLoading(false);
  //     closeModal();
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e?.response?.data?.message || "something went wrong");
  //     console.log(e);
  //   }
  // }



  function closeModal() {
    dispatch(selectedProduct(null));
    dispatch(showSelectShipmentModal(false));
  }

  // Dispatch action to set modal source as 'wishlist' if opened from wishlist
  useEffect(() => {
    const modalSource = localStorage.getItem("modalSource");
    if (modalSource === "wishlist") {
      dispatch(setModalSource("wishlist"));
    }
  }, [dispatch]);

  // console.log("Print", choosenProduct?.productDetails)

  const calculateDiscountPercentage = () => {
    if (choosenProduct?.productDetails?.discountedPrice && choosenProduct?.productDetails?.MRP) {
      const discountedPrice = parseFloat(choosenProduct?.productDetails?.discountedPrice);
      const mrp = parseFloat(choosenProduct?.productDetails?.MRP);
      const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
      return Math.round(discountPercentage);
    }
    return 0;
  };



  return (
    <>





      <Modal
        open={displayModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalSource === "wishlist" ? (


          <>


            <LoginFormWrapper>

              <CloseModal closeFn={closeModal} />



              <CategoryWrapper>
                <CategoryImage


                  src={user ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${img}` : `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${WOLoginImage}`}
                />

                <PriceWrapper>

                  <Heading>{user ? ProductName : WOLoginName}</Heading>

                  <PriceContainer>

                    <SellingPrice>

                      {user ? formatDollars(choosenProduct?.productDetails?.discountedPrice) : formatDollars(WOLoginDiscountedPrice)}

                    </SellingPrice>
                    <MRP>{user ? formatDollars(choosenProduct?.productDetails?.MRP) : formatDollars(WOLoginMRP)}</MRP>
                    <Discount>-{user ? calculateDiscountPercentage() : WOLoginDiscountPercentage}% OFF</Discount>


                  </PriceContainer>

                </PriceWrapper>

              </CategoryWrapper>



              <div style={{ display: 'flex', flexDirection: "column" }}>

                <div style={{ display: 'flex', marginTop: '7px' }}>
                  <Box
                    sx={{
                      // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
                      border: "1.5px solid rgb(227, 227, 227)",
                      borderRadius: '4px',
                      padding: '0px',
                      display: 'inline-block',
                      width: 'fit-content',
                    }}
                  >
                    <Grid container alignItems="center">
                      <Grid item xs alignItems="center" justifyContent="center" style={{ padding: "8px 8px" }} onClick={() => {
                        if (qty > 1) {
                          setQty((prev) => prev - 1);
                        }

                      }}>
                        <RemoveOutlinedIcon style={{ color: "black", cursor: "pointer", marginTop: "5px" }} />
                      </Grid>
                      <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

                      <Grid item xs style={{ color: "black", fontSize: "19px", fontWeight: "500", padding: "8px 8px" }}>
                        {qty}
                      </Grid>
                      <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

                      <Grid item style={{ padding: "8px 8px" }} xs onClick={() => {
                        setQty((prev) => prev + 1);
                      }}>
                        <AddOutlinedIcon style={{ color: "black", cursor: "pointer", marginTop: "5px" }} />
                      </Grid>

                    </Grid>
                  </Box>
                </div>




                <div style={{ display: 'flex', marginTop: '18px' }}>
                  <Box
                    sx={{
                      // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)', // Box shadow instead of border
                      border: "1.5px solid rgb(227, 227, 227)",
                      borderRadius: '8px',
                      padding: '12px 0px',
                      display: 'inline-block',
                      width: 'fit-content',

                      '@media (max-width: 430px)': {
                        padding: '7px 0px', // Adjust padding when screen width is 430px or less
                      },
                    }}
                  >
                    <Grid container alignItems="center">
                      <Grid item xs style={{ padding: "0px 10px" }}>
                        <Typography variant="body2" align="center" color="textSecondary" sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#424545',
                          '@media (max-width: 430px)': {
                            fontSize: '13px', // Apply font-size 11px on max-width 430px
                          },
                        }}>
                          Width
                        </Typography>
                        <Typography variant="h6" align="center" sx={{
                          fontSize: '15px',
                          fontWeight: '500',
                          color: '#838484',
                          '@media (max-width: 430px)': {
                            fontSize: '11px', // Apply font-size 11px on max-width 430px
                          },
                        }}>
                          {user ? choosenProduct?.productDetails?.width : WOLoginProductDetails?.width}(CM)
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

                      <Grid item xs style={{ padding: "0px 10px" }}>
                        <Typography variant="body2" align="center" color="textSecondary" sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#424545',
                          '@media (max-width: 430px)': {
                            fontSize: '13px', // Apply font-size 11px on max-width 430px
                          },
                        }}>
                          Height
                        </Typography>
                        <Typography variant="h6" align="center" sx={{
                          fontSize: '15px',
                          fontWeight: '500',
                          color: '#838484',
                          '@media (max-width: 430px)': {
                            fontSize: '11px', // Apply font-size 11px on max-width 430px
                          },
                        }}>
                          {user ? choosenProduct?.productDetails?.height : WOLoginProductDetails?.height}(CM)
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

                      <Grid item xs style={{ padding: "0px 10px" }}>
                        <Typography variant="body2" align="center" color="textSecondary" sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#424545',
                          '@media (max-width: 430px)': {
                            fontSize: '13px', // Apply font-size 11px on max-width 430px
                          },
                        }}>
                          Length
                        </Typography>
                        <Typography variant="h6" align="center" sx={{
                          fontSize: '15px',
                          fontWeight: '500',
                          color: '#838484',
                          '@media (max-width: 430px)': {
                            fontSize: '11px', // Apply font-size 11px on max-width 430px
                          },
                        }}>
                          {user ? choosenProduct?.productDetails?.length : WOLoginProductDetails?.length}(CM)
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

                      <Grid item xs style={{ padding: "0px 10px" }}>
                        <Typography variant="body2" align="center" color="textSecondary" sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#424545',
                          '@media (max-width: 430px)': {
                            fontSize: '13px', // Apply font-size 11px on max-width 430px
                          },
                        }}>
                          Weight
                        </Typography>
                        <Typography variant="h6" align="center" sx={{
                          fontSize: '15px',
                          fontWeight: '500',
                          color: '#838484',
                          '@media (max-width: 430px)': {
                            fontSize: '11px', // Apply font-size 11px on max-width 430px
                          },
                        }}>
                          {user ? choosenProduct?.productDetails?.weight : WOLoginProductDetails?.weight}(KG)
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </div>





              <Grid container spacing={2} >
                <Grid item alignItems={"center"} justifyContent={"center"}>
                  <DisplayShipmentCard
                    iconName="Airplane"
                    price={shippingDetails?.amountAir ? formatDollars(shippingDetails?.amountAir) : "--"}
                    selected={selected}
                    // selected={typeOfShipment}
                    icon="AIR"
                    name={"AIR"}
                    duration={shippingDetails?.durationAir}
                    setSelected={setSelected}
                    // setSelected={setTypeOfShipment}
                    quantity={quantity}
                    priceValue={priceValue}
                    ShippingPrice={ShippingPrice}
                  />

                </Grid>
                <Grid item alignItems={"center"} justifyContent={"center"}>
                  <DisplayShipmentCard
                    iconName="Ship"
                    price={shippingDetails?.amountShip ? formatDollars(shippingDetails?.amountShip) : "--"}
                    // selected={typeOfShipment}
                    selected={selected}
                    icon="SHIP"
                    name={"SHIP"}
                    duration={shippingDetails?.durationShip}
                    // setSelected={setTypeOfShipment}
                    setSelected={setSelected}
                    quantity={quantity}
                    priceValue={priceValue}
                    ShippingPrice={ShippingPrice}
                  />
                </Grid>


              </Grid>

              <div style={{ display: 'flex', marginTop: '20px' }}>
                <Box
                  sx={{
                    // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)', // Box shadow instead of border
                    border: "1.5px solid rgb(227, 227, 227)",
                    borderRadius: '8px',
                    padding: '10px 3px',
                    display: 'inline-block',
                    width: '344px',
                    '@media (max-width: 430px)': {
                      padding: '20px 0px', // Adjust padding when screen width is 430px or less
                    },

                    '@media (max-width: 484px)': {
                      padding: '7px 0px', // Adjust padding when screen width is 430px or less
                    },

                    '@media (max-width: 430px)': {
                      width: 'fit-content',
                    },

                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs style={{ padding: "0px 0px" }}>
                      <Typography variant="body2" align="center" color="textSecondary" sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#424545',
                        '@media (max-width: 430px)': {
                          fontSize: '13px', // Apply font-size 11px on max-width 430px
                        },
                      }}>
                        Produce   Price
                      </Typography>
                      <Typography variant="h6" align="center" sx={{
                        fontSize: '15px',
                        fontWeight: '500',
                        color: '#838484',
                        '@media (max-width: 430px)': {
                          fontSize: '11px', // Apply font-size 11px on max-width 430px
                        },
                      }}>
                        {/* {formatDollars(discountedPriceValue * quantity)} */}
                        {user ? ProducePrice : formatDollars(WOLoginDiscountedPrice)}
                      </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

                    <Grid item xs style={{ padding: "0px 0px" }}>
                      <Typography variant="body2" align="center" color="textSecondary" sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#424545',
                        '@media (max-width: 430px)': {
                          fontSize: '13px', // Apply font-size 11px on max-width 430px
                        },
                      }}>
                        {selected === "AIR" ? "Airplane Price" : "Ship Price"}
                      </Typography>
                      <Typography variant="h6" align="center" sx={{
                        fontSize: '15px',
                        fontWeight: '500',
                        color: '#838484',
                        '@media (max-width: 430px)': {
                          fontSize: '11px', // Apply font-size 11px on max-width 430px
                        },
                      }}>
                        {ShippingPrice}
                      </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

                    <Grid item xs style={{ padding: "0px 0px" }}>
                      <Typography variant="body2" align="center" color="textSecondary" sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#424545',
                        '@media (max-width: 430px)': {
                          fontSize: '13px', // Apply font-size 11px on max-width 430px
                        },
                      }}>
                        Total Price
                      </Typography>
                      <Typography variant="h6" align="center" sx={{
                        fontSize: '15px',
                        fontWeight: '500',
                        color: '#838484',
                        '@media (max-width: 430px)': {
                          fontSize: '11px', // Apply font-size 11px on max-width 430px
                        },
                      }}>
                        {user ? formatDollars(TotalPrice) : formatDollars(WOLoginToPrice)}
                      </Typography>
                    </Grid>


                  </Grid>
                </Box>
              </div>

              <ModalBtn onClick={() => { addItemToUserCart(); }}>
                {loading ? <CircularProgress /> : `Add to cart`}
              </ModalBtn>

            </LoginFormWrapper>
          </>

        ) : (
          // Regular UI for Non-Wishlist
          <LoginFormWrapper>
            <CloseModal closeFn={closeModal} />

            <Heading>Select Type of Shipment</Heading>



            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item alignItems={"center"} justifyContent={"center"}>
                <DisplayShipmentCard
                  iconName="Airplane"
                  price={price}
                  selected={selected}
                  icon="AIR"
                  name={"AIR"}
                  duration={shippingDetails?.durationAir}
                  setSelected={setSelected}
                  quantity={quantity}
                  priceValue={priceValue}
                  ShippingPrice={ShippingPrice}
                />
              </Grid>


              <Grid item alignItems={"center"} justifyContent={"center"}>
                <DisplayShipmentCard
                  iconName="Ship"
                  price={price}
                  selected={selected}
                  icon="SHIP"
                  name={"SHIP"}
                  duration={shippingDetails?.durationShip}
                  setSelected={setSelected}
                  quantity={quantity}
                  priceValue={priceValue}
                  ShippingPrice={ShippingPrice}
                />
              </Grid>
            </Grid>

            <ModalBtn onClick={() => { addItemToUserCart(qty); }}>
              {loading ? <CircularProgress /> : `Submit`}
            </ModalBtn>
          </LoginFormWrapper>
        )}
      </Modal>
    </>


  );
}

// Shipment card component for displaying different shipment options
function DisplayShipmentCard({
  iconName,
  icon,
  name,
  duration,
  selected,
  setSelected,
  price,
  quantity,
  priceValue,
  ShippingPrice,
}) {
  const Icon =
    icon === "AIR"
      ? AirplanemodeActiveTwoToneIcon
      : DirectionsBoatFilledOutlinedIcon;
  const cardColor = selected === name ? `#03BEE7` : `#838484`;
  const textColor = selected === name ? `#242626` : `#838484`;
  const btnTextColor = selected === name ? `white` : `#838484`;
  const btnBackgroundColor = selected === name ? `#03BEE7` : `#E3E3E3`;
  console.log("Hello", priceValue)

  const isMobile = useMediaQuery("(max-width:484px)"); // Media query for mobile devices
  return (
    <Card
      style={{
        border:
          selected === name ? `1.5px solid #03BEE7` : "1.5px solid #E3E3E3",
      }}
      onClick={() => {
        setSelected(name);
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "7px",
        }}
      >
        <Icon
          style={{
            color: cardColor,
            fontFamily: "Inter",
            fontSize: isMobile ? "17px" : "30px", // Adjust font size based on screen width
            fontWeight: 500,
            lineHeight: "24.2px",
          }}
        />
        <div
          style={{
            color: cardColor,
            marginLeft: "10px",
            fontFamily: "Inter",
            fontSize: isMobile ? "17px" : "20px", // Adjust font size based on screen width
            fontWeight: 500,
            lineHeight: "24.2px",
          }}
        >
          {iconName}
        </div>
      </div>

      <CardPrice style={{ color: textColor }}>{ShippingPrice}</CardPrice>

      <CardBtn style={{ color: btnTextColor, background: btnBackgroundColor }}>
        {duration}
      </CardBtn>
    </Card>
  );
}

export default SelectTypeOfShipment;

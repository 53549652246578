import React from "react";
import styled from "@emotion/styled";

const CategoryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 22px;
  margin-top: 22px;
  box-sizing: border-box;
  width: 182px;
  margin: auto;
  width: 80%;
  margin: 10px auto;
  
  @media (max-width: 768px) {
    width: 100%; /* Full width for tablet and smaller devices */
    margin-right: 0; /* No extra margin on smaller devices */
  }

  @media (max-width: 600px) {
    width: 90%; /* Slightly narrower for small devices */
    margin: 10px auto;
  }
`;

const CategoryImage = styled.img`
  width: 128px;
  height: 128px;
  padding: 56px;
  border-radius: 18px;
box-shadow: inset 0 2px 12px rgba(57, 163, 225, 1); /* Inset shadow with light blue */


  @media (max-width: 768px) {
    width: 115px;
    height: 115px;
    padding: 40px;
  }

  @media (max-width: 600px) {
    width: 100px;
    height: 100px;
    padding: 30px;
  }
`;

const CategoryTitle = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  font-family: "Inter";
  margin-top: 15px;
  min-height: 50px;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-top: 10px;
    min-height: 40px;
  }

  @media (max-width: 600px) {
    font-size: 15px;
    margin-top: 8px;
    min-height: 30px;
  }
`;

function ChildIndividualCategory({ img, name, products }) {
  // console.log("Category Name:", name);
  return (
    <CategoryWrapper>
      <CategoryImage
        src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${img}`}
      />
      <CategoryTitle>{name || " "}</CategoryTitle>
      
    </CategoryWrapper>
    
  );
}

export default ChildIndividualCategory;

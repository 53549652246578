import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleDynamicPageContent } from "../../store/reducer/reducer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SingleProduct from "../../components/LandingPage/SingleProduct";
import { post,get } from "../../api_helper/api_helper";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
  max-width:1525px;
  @media (max-width: 600px) {
    width: 97%;
  }
`;

const CustomPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    color: #00B4D8;
    border-color: #00B4D8;
  }
  .MuiPaginationItem-root.Mui-selected {
    background-color: #00B4D8;
    color: white;
    width:36px;
    height:36px;
    border-radius:19px;
    font-size:16px;
  }
  .MuiPaginationItem-ellipsis {
    color: #00B4D8;
  }
  .MuiPaginationItem-icon {
    color: #00B4D8;
  }
`;


const PaginationInfo = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color:#7B7B7B;
`;

const TitleHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 24px;
  line-height: 29.05px;
`;
const ViewEnd = styled.div`
  display: flex;
  margin: 20px auto;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;
const FlexWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 4 equal columns */
  gap: 10px; /* Gap between items */

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr); /* 3 items per row on smaller screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row for tablet screens */
  }

  // @media (max-width: 480px) {
  //   grid-template-columns: 2fr; /* 1 item per row for mobile */
  // }
`;

const FlexItem = styled.div`
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 350px; /* Set a fixed height to ensure uniform card size */
  border: 1px solid #ddd;
`;



const GoBack = styled.span`
  font-weight: 500;
  font-size: 20px;
  font-family: "Inter";
  cursor: pointer;
`;
function DynamicPageContent() {
  const dynamicPageContent = useSelector(
    (state) => state.general.dynamicPageContent
  );
  const [OfferAndBanner,setOffersAndBanners] = useState(dynamicPageContent?.offer_image_products || dynamicPageContent?.banner_image_products)
  const [dynamicpage,setDynamicPage]=useState(dynamicPageContent)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const mainProducts=dynamicpage?.banner_image_products;
  const offerProduct=dynamicPageContent?.offer_image_products;
  const totalPages = Math.ceil(mainProducts ? mainProducts?.length / itemsPerPage : offerProduct?.length);
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = mainProducts ? mainProducts?.slice(indexOfFirstProduct, indexOfLastProduct) : offerProduct?.slice(indexOfFirstProduct, indexOfLastProduct);
  // console.log("Offer And Banner Page",OfferAndBanner)
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };




  useEffect(() => {
    if (!dynamicPageContent) {
      navigate("/");
    }
  }, []);
  console.log("Dynamicpage",dynamicpage)

  const user = useSelector((state) => state.general.user);
  const [wishlistItems, setWishlistItems] = useState([]);



  async function getUserWishlistItems() {
    if (user) {
      try {
        const items = await post("/api/wishlist", {});
        console.log("Fetched wishlist items (logged-in user):", items?.wishlist?.wishlist_items);
        setWishlistItems(items?.wishlist?.wishlist_items || []);
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    } else {
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      console.log("Fetched wishlist items (guest user):", storedWishlist);
      setWishlistItems(storedWishlist);
    }
  }
  
  useEffect(() => {
    getUserWishlistItems();
  }, []); 
  
  useEffect(() => {
    console.log("ProductSlider: wishlistItems updated:", wishlistItems);
  }, [wishlistItems]);



  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <WidthAdjuster>

      
      <ViewEnd>
        <ArrowBackIcon
          onClick={() => {
            dispatch(handleDynamicPageContent(null));
          }}
        />

        <GoBack
          onClick={() => {
            dispatch(handleDynamicPageContent(null));
            navigate("/");
          }}
        >
          BACK
        </GoBack>
      </ViewEnd>
      <TitleHeader>{dynamicPageContent?.pageTitle}</TitleHeader>
          <FlexWrapper>

      {OfferAndBanner?.length ? (
          <>
          {currentProducts.map((item) => {
              return  <SingleProduct product={item} key={item?.id} getUserWishlistItems={getUserWishlistItems} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} />;
            })}
        </>
      ) : null}
      </FlexWrapper>
      {console.log("Main Products",mainProducts)}

      {(mainProducts?.length > 15 || offerProduct?.length > 15) && (
  <div style={{ margin: "74px auto", display: "flex", alignItems: "center", justifyContent: "space-between", width: isMobile ? "80%" : "60%" }}>
    <PaginationInfo>
      {`Page ${currentPage} of ${totalPages}`}
    </PaginationInfo>
    <Stack spacing={2}>
      <CustomPagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
      />
    </Stack>
  </div>
)}

    </WidthAdjuster>
  );
}

export default DynamicPageContent;
